<ng-container [formGroup]="this.filterForm">
  <div *ngFor="let filter of filters" class="filter-container">
    <app-slider-filter
      *ngIf="filter.type === FILTER_TYPE.SLIDER"
      [filter]="filter"
      [filterForm]="filterForm"></app-slider-filter>

    <app-text-filter
      *ngIf="filter.type === FILTER_TYPE.TEXT"
      [filter]="filter"
      [filterForm]="filterForm"></app-text-filter>
  </div>
</ng-container>
