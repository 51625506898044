<div class="chart-container">
  <ng-container *ngIf="labelTranslationIdentifier">
    <span
      class="label"
      *ngIf="tooltip; else noTooltip"
      [matTooltip]="tooltip"
      [matTooltipShowDelay]="tooltipShowDelay"
      [matTooltipPosition]="tooltipPosition">
      {{ labelTranslationIdentifier | translate }}
    </span>
    <ng-template #noTooltip>
      <span class="label">
        {{ labelTranslationIdentifier | translate }}
      </span></ng-template
    >
  </ng-container>

  <div echarts [options]="chartOptions" class="chart"></div>
</div>
