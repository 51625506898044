import { Injectable } from '@angular/core';
import { ApplicableCondition } from '@app/location-table-page/applicable-condtion';
import { BehaviorSubject } from 'rxjs';
import { LocationDto } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class LocationRowService {
  #originLocationRows: LocationDto[] = [];

  #lengthOfFilterResult$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  public get originLocationRows(): LocationDto[] {
    return this.#originLocationRows;
  }

  public set originLocationRows(value: LocationDto[]) {
    this.#originLocationRows = value;
  }

  public get lengthOfFilterResult$(): BehaviorSubject<number> {
    return this.#lengthOfFilterResult$;
  }

  #filteredLocationRows: LocationDto[] = [];

  public get filteredLocationRows(): LocationDto[] {
    return this.#filteredLocationRows;
  }

  public applyFilters(
    locationRows: LocationDto[],
    conditions: ApplicableCondition[]
  ): void {
    this.#filteredLocationRows = locationRows?.filter(location =>
      this.#isPassingAllFilters(conditions, location)
    );
    this.lengthOfFilterResult$.next(this.#filteredLocationRows.length);
  }

  #isPassingAllFilters(
    $event: ApplicableCondition[],
    location: LocationDto
  ): boolean {
    let locationPassesAllFilters = true;

    $event.forEach(condition => {
      const key = condition.criterion as keyof LocationDto;

      if (key === 'stations') {
        const isOneStationApplicable = location.stations.some(station =>
          condition.apply(station.chargingPower)
        );
        if (!isOneStationApplicable) {
          locationPassesAllFilters = false;
        }
      } else if (!condition.apply(location[key] as number)) {
        locationPassesAllFilters = false;
      }
    });
    return locationPassesAllFilters;
  }
}
