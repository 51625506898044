@if (features()) {
  <mgl-geojson-source
    #source
    [clusterMaxZoom]="17"
    [clusterRadius]="75"
    [cluster]="cluster"
    [data]="features()!"
    [id]="id">
  </mgl-geojson-source>

  <mgl-layer
    [id]="'clusters-' + id"
    type="symbol"
    [minzoom]="maxZoom"
    [source]="id"
    [filter]="['has', 'point_count']"
    [layout]="{
      'icon-image': id + '_cluster',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    }"
    (layerMouseUp)="selectCluster($event)" />

  <mgl-layer
    [id]="'clusters-count-' + id"
    type="symbol"
    [minzoom]="maxZoom"
    [source]="id"
    [filter]="['has', 'point_count']"
    [layout]="{
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Open Sans Bold'],
      'text-size': 12,

      'text-allow-overlap': true,
      'text-ignore-placement': true
    }"
    [paint]="{
      'text-color': '#fff'
    }" />

  <mgl-layer
    [id]="'unclustered-point-' + id"
    type="symbol"
    [minzoom]="maxZoom"
    [source]="id"
    [filter]="['!has', 'point_count']"
    (layerMouseEnter)="hoverPoint($event)"
    (layerMouseLeave)="hoverOut()"
    [layout]="{
      'icon-image': id,
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    }" />
}
