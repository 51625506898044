// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
export enum LAYER_COLOR {
  GRID = '#000000',
  POWER = '#964B00',
  PARKING = '#0000FF',
  LANDUSE_COMMERCIAL = '#FFA500',
  LANDUSE_RESIDENTIAL = '#008000',
  LANDUSE_INDUSTRIAL = '#FF0000',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NETWORK_OPERATORS = '#000000',
}
