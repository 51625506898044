import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { round } from 'lodash';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { ScoreChartConfigProvider } from './score-chart-config-provider';

@Component({
  selector: 'app-score-chart',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    NgxEchartsDirective,
  ],
  templateUrl: './score-chart.component.html',
  styleUrls: ['./score-chart.component.scss'],
  providers: [provideEcharts()],
})
export class ScoreChartComponent implements OnChanges, OnInit {
  @Input()
  public score?: number;

  @Input()
  public scoreNameTranslationIdentifier!: string;

  @Input()
  public tooltipTranslationIdentifier?: string;

  @Input()
  public tooltipShowDelay = 0;

  readonly #destroyRef = inject(DestroyRef);
  public chartOptions: EChartsOption | null = null;

  readonly #translateService = inject(TranslateService);

  public ngOnInit(): void {
    this.#translateService.onLangChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.chartOptions = this.generateChartOptions();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['score'] != null) {
      this.chartOptions = this.generateChartOptions();
    }
  }

  public generateChartOptions(): EChartsOption | null {
    if (this.score == null) {
      return null;
    }

    return ScoreChartConfigProvider.generateConfig(
      round(this.score, 1),
      this.#translateService.instant(this.scoreNameTranslationIdentifier)
    );
  }
}
