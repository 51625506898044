import { CommonModule, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MessageComponent } from '../message/message.component';

@Component({
  selector: 'app-attribute',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    NgIf,
    MessageComponent,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss'],
})
export class AttributeComponent {
  @Input()
  public numerator?: string | null;

  @Input()
  public denominator?: string | null;

  @Input()
  public labelTranslationIdentifier!: string;

  @Input()
  public tooltipTranslationIdentifier?: string;

  @Input()
  public tooltipShowDelay = 0;

  @Input()
  public tooltipPosition: TooltipPosition = 'above';

  @Input()
  public numeratorToolTipTranslationIdentifier?: string;

  @Input()
  public denominatorToolTipTranslationIdentifier?: string;

  @Input()
  public set showNewValueIndicator(showNewValueIndicator: boolean) {
    this.isNewValueIndicatorDisplayed = showNewValueIndicator;
  }

  @Input()
  public newValueIndicatorToolTipTranslationIdentifier = '';

  @Input()
  public delimiter = '/';

  public isNewValueIndicatorDisplayed = false;
}
