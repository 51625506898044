import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { SpotOnHoverEvent } from '@app/map/components/sidebar/components/spots-found/spot-on-hover-event.interface';
import { Spot } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { SpotsListComponent } from './components/spots-list/spots-list.component';
import {
  SpotsRankingComponent,
  SpotsSkeletonListComponent,
} from '@app/map/components/sidebar/components/spots-found/components';

@Component({
  selector: 'app-spots-found',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    TranslateModule,
    ReactiveFormsModule,
    SpotsListComponent,
    SpotsRankingComponent,
    SpotsSkeletonListComponent,
  ],
  templateUrl: './spots-found.component.html',
  styleUrls: ['./spots-found.component.scss'],
})
export class SpotsFoundComponent {
  @Input() public spotsFoundOptions!: Spot[];
  @Input() public showPrefixes = true;
  @Input() public showSuffixes = true;
  @Input() public showRanking = false;
  @Input() public freeToPlay = false;

  @Input() public selectedSpot: Spot | undefined;

  @Output() public spotsFoundSelectionChange = new EventEmitter<Spot>();
  @Output() public spotOnHover = new EventEmitter<SpotOnHoverEvent>();

  public selectionChange($event: Spot): void {
    if ($event) {
      this.spotsFoundSelectionChange.emit($event);
    }
  }

  public onSpotHover($event: SpotOnHoverEvent) {
    this.spotOnHover.emit($event);
  }
}
