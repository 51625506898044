import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  ErrorHandlerService,
  LocationService,
  NominatimService,
} from '@app/core';
import { CreateConfigurationDialogData } from '@app/map/components/spot-information/components/create-configuration-dialog/create-configuration-dialog-data';
import { CreateConfigurationDialogOutput } from '@app/map/components/spot-information/components/create-configuration-dialog/create-configuration-dialog-output';
import { CreateConfigurationDialogComponent } from '@app/map/components/spot-information/components/create-configuration-dialog/create-configuration-dialog.component';
import { NetworkOperatorInfoComponent } from '@app/map/components/spot-information/components/network-operator-info/network-operator-info.component';
import { GridOperatorTabComponent } from '@app/map/components/spot-information/components/network-operator-tab/network-operator-tab.component';
import { TabHeaderComponent } from '@app/map/components/spot-information/components/tab-header/tab-header.component';
import { MENU_ENTRY_TYPE } from '@app/map/components/spot-information/enums/menu-entry-type.enum';
import { MenuEntry } from '@app/map/components/spot-information/interfaces/menu-entry';
import {
  CreateOrSelectProjectDialogComponent,
  DIALOG_EVENT,
  DialogConfigFactory,
  DialogData,
  IsLocationPipe,
  LocationRequestDto,
  SPOT_INFORMATION_FEATURE,
  Spot,
  StationDto,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual, isNil } from 'lodash-es';
import { GridScoreTabComponent } from './components/grid-score-tab/grid-score-tab.component';
import { RangeChartComponent } from './components/range-chart/range-chart.component';
import { ScoreChartComponent } from './components/score-chart/score-chart.component';
import { SpotTabComponent } from './components/spot-tab/spot-tab.component';
import { UsageScoreTabComponent } from './components/usage-score-tab/usage-score-tab.component';

export type SpotInformationFeatureConfig = {
  [key in SPOT_INFORMATION_FEATURE]: boolean;
};

@Component({
  selector: 'app-spot-information',
  templateUrl: './spot-information.component.html',
  styleUrls: ['./spot-information.component.scss'],
  imports: [
    MatTabsModule,
    MatButtonModule,
    NgIf,
    MatDialogModule,
    AsyncPipe,
    MatProgressBarModule,
    NgClass,
    TranslateModule,
    IsLocationPipe,
    TabHeaderComponent,
    MatIconModule,
    NgTemplateOutlet,
    NetworkOperatorInfoComponent,
    NgForOf,
    JsonPipe,
    GridOperatorTabComponent,
    RangeChartComponent,
    ScoreChartComponent,
    GridScoreTabComponent,
    UsageScoreTabComponent,
    SpotTabComponent,
    MatMenuModule,
  ],
  standalone: true,
})
export class SpotInformationComponent implements OnChanges {
  @Input() public featureConfig: SpotInformationFeatureConfig = {
    networkOperator: false,
    crudOperations: false,
  };

  @Input() public freeToPlay = false;
  @Input({ required: true }) public spot!: Spot;
  @Input() public isFullWidth = false;
  @Output() public saveLocationClick = new EventEmitter<{
    locationRequest: LocationRequestDto;
    recommendationId: string;
  }>();
  @Output() public deleteLocationClick = new EventEmitter<{
    locationId: string;
  }>();
  @Output() public stationsChanged = new EventEmitter<boolean>();

  public road: string | undefined = '';
  public projectId?: string;
  public showNewValueIndicator = false;
  public menuOptions: MenuEntry[] = [
    {
      labelTranslationIdentifier: 'MAP.LOCATION_INFORMATION.CONFIGURATION',
      icon: 'tune',
      type: MENU_ENTRY_TYPE.CONFIGURATION,
      dataTestId: 'spot-information-menu-configuration',
    },
    {
      labelTranslationIdentifier: 'COMMON.DELETE',
      icon: 'delete',
      type: MENU_ENTRY_TYPE.DELETE,
      dataTestId: 'spot-information-menu-delete',
    },
  ];

  public tabToolTipPosition: TooltipPosition = 'left';

  public isLoading: WritableSignal<boolean> = signal(false);
  readonly #dialog = inject(MatDialog);
  readonly #route = inject(ActivatedRoute);
  readonly #locationService = inject(LocationService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #errorHandlerService = inject(ErrorHandlerService);
  readonly #nominatimService = inject(NominatimService);

  constructor() {
    this.#route.params.pipe(takeUntilDestroyed()).subscribe(params => {
      const id = params['id'];

      if (id) {
        this.projectId = id;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.spot.precalculated) {
      if (!this.spot.address) {
        this.#nominatimService
          .searchByCoordinates(this.spot.latitude, this.spot.longitude)
          .subscribe(geocoding => {
            this.road = geocoding.address.road;
          });
      } else {
        this.road = this.spot.address.road;
      }
    } else {
      this.road = this.spot?.address?.road;
    }

    if (this.isCurrentSpotNotEqualToPrevious(changes)) {
      this.showNewValueIndicator = false;
    }
  }

  private isCurrentSpotNotEqualToPrevious(changes: SimpleChanges): boolean {
    const spotChangeIdentifier = 'spot';

    return (
      changes[spotChangeIdentifier].previousValue?.id !==
      changes[spotChangeIdentifier].currentValue.id
    );
  }

  public saveLocation(): void {
    if (this.projectId) {
      this.emitSaveEvent();
    } else {
      this.openCreateProjectDialog();
    }
  }

  private openCreateProjectDialog(): void {
    const config = DialogConfigFactory.INSTANCE.buildDialogConfig('small');

    const dialogRef = this.#dialog.open(CreateOrSelectProjectDialogComponent, {
      ...config,
      data: {
        project: undefined,
      },
    });

    dialogRef.afterClosed().subscribe((dialogData: DialogData) => {
      if (
        dialogData.event &&
        (dialogData.event === DIALOG_EVENT.CREATE ||
          dialogData.event === DIALOG_EVENT.SELECT)
      ) {
        this.projectId = dialogData.project.id;
        this.emitSaveEvent();
      }
    });
  }

  private emitSaveEvent(): void {
    if (this.projectId) {
      const {
        longitude,
        latitude,
        address,
        totalCostEstimation,
        substationCostEstimation,
        gridInvestmentCostEstimation,
        distanceToGridEstimation,
        gridConnectionCostEstimation,
        consumptionDayEstimationScore,
        consumptionDayEstimationScoreLocal,
        costEstimationScore,
        costEstimationScoreLocal,
        consumptionDayEstimation,
        sessionsDayEstimation,
        evChargersIn10KM,
        evDensity,
        forecastConsumptionDay,
        forecastOptimalConsumptionDay,
        populationDensity,
        stations,
        locationHash,
        version,
        precalculated,
      } = this.spot;

      this.saveLocationClick.emit({
        locationRequest: {
          longitude,
          latitude,
          projectId: this.projectId,
          address,
          totalCostEstimation,
          gridInvestmentCostEstimation,
          substationCostEstimation,
          distanceToGridEstimation,
          gridConnectionCostEstimation,
          sessionsDayEstimation,
          consumptionDayEstimation,
          consumptionDayEstimationScore,
          consumptionDayEstimationScoreLocal,
          costEstimationScore,
          costEstimationScoreLocal,
          evChargersIn10KM,
          forecastConsumptionDay,
          forecastOptimalConsumptionDay,
          evDensity,
          populationDensity,
          stations,
          locationHash,
          version,
          precalculated,
        },
        recommendationId: this.spot.id,
      });
    }
  }

  public onMenuClick(menu: MENU_ENTRY_TYPE): void {
    switch (menu) {
      case MENU_ENTRY_TYPE.DELETE: {
        this.deleteLocationClick.emit({ locationId: this.spot.id });
        break;
      }
      case MENU_ENTRY_TYPE.CONFIGURATION: {
        const config = DialogConfigFactory.INSTANCE.buildDialogConfig('medium');

        const dialogRef = this.#dialog.open(
          CreateConfigurationDialogComponent<CreateConfigurationDialogData>,
          {
            ...config,
            data: this.spot,
          }
        );

        dialogRef
          .afterClosed()
          .subscribe((dialogData: CreateConfigurationDialogOutput[]) => {
            const potentialNewStations: StationDto[] = dialogData.map(
              dialogOutput => {
                const defaultConnectorAmount = 2;
                /* TODO: Adjust when https://jira.eon.com/browse/ONETP-1893 has a solution */
                return {
                  id: dialogOutput.id,
                  chargingPower: parseInt(dialogOutput.chargingPower, 10),
                  connectors: defaultConnectorAmount, // dialogOutput.connectors,
                };
              }
            );

            if (!isEqual(this.spot.stations, potentialNewStations)) {
              const filteredPotentialNewStations = potentialNewStations.filter(
                it =>
                  !isNil(it.connectors) &&
                  !isNil(it.chargingPower === undefined)
              );
              this.isLoading.set(true);
              this.#locationService
                .updateLocationStation(
                  this.spot.id,
                  filteredPotentialNewStations
                )
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe({
                  next: spot => {
                    this.isLoading.set(false);
                    this.spot = spot;
                    this.showNewValueIndicator = true;
                    this.stationsChanged.emit(true);
                  },
                  error: error => {
                    this.isLoading.set(false);
                    this.#errorHandlerService.handleError(error);
                  },
                });
            }
          });
        break;
      }
      default: {
        throw new Error(`Not supported menu entry: ${menu}`);
      }
    }
  }
}
