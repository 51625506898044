import {
  CircleLayerSpecification,
  FillLayerSpecification,
  HeatmapLayerSpecification,
  LineLayerSpecification,
  RasterLayerSpecification,
  SourceSpecification,
  SymbolLayerSpecification,
} from 'maplibre-gl';
import { LAYER_GROUP, LAYER_NAME } from '@app/map/enums';

export enum LAYER_FILTER_TYPE {
  SLIDER = 'slider',
  TEXT = 'text',
}

export interface LayerSliderFilter {
  type: LAYER_FILTER_TYPE.SLIDER;
  layer: LAYER_NAME;
  labelTranslationIdentifier: string;
  key: string;
  min: number;
  max: number;
  step: number;
}

export interface LayerTextFilter {
  type: LAYER_FILTER_TYPE.TEXT;
  layer: LAYER_NAME;
  key: string;
  labelTranslationIdentifier: string;
  value: string;
}

export type LayerFilterType = LayerSliderFilter | LayerTextFilter;

export interface LayerWrapper {
  isActive: boolean;
  canBeToggled: boolean;
  name: LAYER_NAME;
  labelTranslationIdentifier: string;
  icon?: string;
  color?: string;
  cluster?: boolean;
  clusterZoom?: number;
  clusterType?: 'square' | 'round';
  clusterIcon?: string;
  group?: LAYER_GROUP;
  order: number;
  zoomOnToggle: number;
  sourceSpecification: SourceSpecification;
  layerSpecifications: Array<
    | FillLayerSpecification
    | RasterLayerSpecification
    | HeatmapLayerSpecification
    | LineLayerSpecification
    | CircleLayerSpecification
    | SymbolLayerSpecification
  >;
}

export interface LayerWrapperGroup {
  groupName: string;
  groupIcon: string;
  layers: LayerWrapper[];
}

export type LayerWrapperOrGroup = LayerWrapper | LayerWrapperGroup;
