<div class="tab-header">
  <h1 class="tab-header__headline">
    <ng-container *ngIf="road; else emptyAddress">{{ road }}</ng-container>
  </h1>

  @if (!hideSaveButton) {
    <button
      *ngIf="!(spot | isLocation)"
      [disabled]="isLoading"
      (click)="onSaveClick()"
      id="save-button"
      class="small"
      color="accent"
      mat-flat-button
      data-testid="spot-save-btn">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  }
</div>

<ng-template #emptyAddress>
  {{ 'COMMON.NO_ADDRESS_INFO' | translate }}
</ng-template>
