import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RestrictionService } from '@app/core';
import { LAYER_NAME } from '@app/map/enums';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

@Component({
  selector: 'app-regions-restriction-layer',
  standalone: true,
  imports: [CommonModule, NgxMapLibreGLModule],
  templateUrl: './regions-restriction-layer.component.html',
  styleUrls: [],
})
export class RegionsRestrictionLayerComponent {
  public readonly LAYER_NAME = LAYER_NAME;
  readonly #restrictionService = inject(RestrictionService);
  regionsRestriction = this.#restrictionService.allowedRegions;
}
