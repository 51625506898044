import { Spot } from '@app/shared';

export class SpotClickEvent {
  private _$event: Event;
  private _spot: Spot;
  private _shouldOpenSpotInformation: boolean;

  constructor(
    $event: Event,
    spot: Spot,
    shouldOpenSpotInformation: boolean = true
  ) {
    this._$event = $event;
    this._spot = spot;
    this._shouldOpenSpotInformation = shouldOpenSpotInformation;
  }

  set $event(value: Event) {
    this._$event = value;
  }
  set spot(value: Spot) {
    this._spot = value;
  }
  set shouldOpenSpotInformation(value: boolean) {
    this._shouldOpenSpotInformation = value;
  }

  get spot(): Spot {
    return this._spot;
  }
  get $event(): Event {
    return this._$event;
  }
  get shouldOpenSpotInformation(): boolean {
    return this._shouldOpenSpotInformation;
  }
}
