import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-spots-list-element-suffix',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './spots-list-element-suffix.component.html',
  styleUrls: ['./spots-list-element-suffix.component.scss'],
})
export class SpotsListElementSuffixComponent {
  @Input({ required: true }) public icon!: 'speed';
  @Input() public text?: string;
  @Input() public shortSuffix?: boolean = false;
}
