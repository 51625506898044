import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, signal } from '@angular/core';
import { LAYER_NAME } from '@app/map/enums';
import { LocationRecommendationRequestDto } from '@app/shared';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import * as turf from '@turf/turf';

@Component({
  selector: 'app-search-radius-layer',
  standalone: true,
  imports: [CommonModule, NgxMapLibreGLModule],
  templateUrl: './search-radius-layer.component.html',
  styleUrls: [],
})
export class SearchRadiusLayerComponent implements OnChanges {
  public readonly LAYER_NAME = LAYER_NAME.SEARCH_RADIUS;

  @Input()
  public locationRecommendationRequest?: LocationRecommendationRequestDto;

  public circle = signal<GeoJSON.Feature | undefined>(undefined);

  public ngOnChanges(): void {
    if (this.locationRecommendationRequest) {
      this.circle.set(
        turf.circle(
          [
            this.locationRecommendationRequest.longitude,
            this.locationRecommendationRequest.latitude,
          ],
          this.locationRecommendationRequest.radiusMeter,
          {
            units: 'meters',
            steps: 150,
          }
        )
      );
    }
  }
}
