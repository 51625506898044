<ng-container *ngIf="spots">
  <ng-container *ngIf="!cluster">
    <mgl-marker
      *ngFor="let location of spots; let index = index"
      [offset]="[0, -17.5]"
      [lngLat]="[location.longitude, location.latitude]"
      [className]="location | markerColor: index | markerClass">
      <app-spot-marker
        [spot]="location"
        [isLoading$]="isLoading$ | isLoading: location : selectedSpot"
        [size]="location | markerSize: selectedSpot : spotsWithSizeBig"
        [markerColor]="location | markerColor: index"
        (clicked)="onLocationMarkerClicked($event, location)"></app-spot-marker>
    </mgl-marker>
  </ng-container>

  <ng-container *ngIf="cluster">
    <mgl-geojson-source
      #source
      id="spots"
      [data]="mapFeatureCollection()"
      [cluster]="true"
      [clusterMaxZoom]="14"
      [clusterRadius]="50">
    </mgl-geojson-source>

    <mgl-markers-for-clusters source="spots">
      <ng-template mglPoint let-feature>
        <ng-container
          *ngIf="spotsById.get(feature.properties.id) as currentSpot">
          <app-spot-marker
            [spot]="currentSpot"
            [isLoading$]="
              isLoading$
                | isLoading: getSpotById(feature.properties.id) : selectedSpot
            "
            [size]="
              getSpotById(feature.properties.id)
                | markerSize: selectedSpot : spotsWithSizeBig
            "
            [markerColor]="
              getSpotById(feature.properties.id)
                | markerColor: feature.properties.index
            "
            (clicked)="
              onLocationMarkerClicked(
                $event,
                getSpotById(feature.properties.id)
              )
            ">
          </app-spot-marker>
        </ng-container>
      </ng-template>

      <ng-template mglClusterPoint let-feature>
        <app-cluster
          [color]="
            undefined
              | markerColor
                : getLowestIndexForCluster(feature.properties.cluster_id)
          "
          [sizeBig]="showClusterBig(feature.properties.cluster_id)"
          [count]="feature.properties?.point_count"
          (click)="selectCluster($event, feature)">
        </app-cluster>
      </ng-template>
    </mgl-markers-for-clusters>
  </ng-container>
</ng-container>
