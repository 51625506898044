<app-dialog-header (closeButtonClicked)="closeExcludingChanges()">
  {{ 'MAP.LOCATION_INFORMATION.CONFIGURATION' | translate }}
</app-dialog-header>
<mat-dialog-content>
  <div class="dialog__body">
    <app-flex-list-form
      [listFormMeta]="createConfigurationDialogData"
      [initialValues]="initialValues"
      (isValid)="onIsValidChange($event)"
      (validFormValue)="onValidFormValueChange($event)">
    </app-flex-list-form>
    <p>
      {{
        'MAP.LOCATION_INFORMATION.CONFIGURATION_EVALUATION_MESSAGE' | translate
      }}
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    (click)="closeExcludingChanges()"
    tabindex="-1"
    mat-stroked-button
    color="primary"
    class="small">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button
    [disabled]="!isValidForm"
    (click)="closeIncludingData()"
    id="create-button"
    tabindex="-1"
    mat-flat-button
    color="primary"
    class="small">
    {{ 'COMMON.APPLY' | translate }}
  </button>
</mat-dialog-actions>
