import { Pipe, PipeTransform } from '@angular/core';
import { Interval } from '@app/shared';

@Pipe({
  name: 'multiplyInterval',
  standalone: true,
})
export class MultiplyIntervalPipe implements PipeTransform {
  public transform(interval: Interval, multiplier: number): Interval {
    return {
      minBoundary: interval.minBoundary * multiplier,
      maxBoundary: interval.maxBoundary * multiplier,
      min: interval.min * multiplier,
      avg: interval.avg * multiplier,
      max: interval.max * multiplier,
    };
  }
}
