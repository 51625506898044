<mat-form-field class="toolbar" [formGroup]="form" color="accent">
  <mat-label>{{ 'TABLE_VIEW.SELECT_COLUMNS' | translate }}:</mat-label>
  <mat-select
    class="toolbar-colum-selection--capitalized"
    [formControlName]="FORM_NAME"
    (selectionChange)="emitSelectionChange($event)"
    data-testid="column-select"
    multiple>
    <mat-option
      class="toolbar-colum-selection__option--capitalized"
      *ngFor="let checkbox of _checkboxes"
      [value]="checkbox"
      [attr.data-testid]="'option-' + checkbox.columnIdentifier">
      {{ checkbox.labelTranslationIdentifier | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
