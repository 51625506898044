<form [formGroup]="formGroup">
  <ul class="layer-list">
    <ng-container *ngFor="let item of layersOrGroups">
      <ng-container *ngIf="item | isLayerGroup">
        <ng-template
          [ngTemplateOutlet]="layerGroupTemplate"
          [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(item | isLayerGroup)">
        <li>
          <ng-template
            [ngTemplateOutlet]="notLayerGroupTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
        </li>
      </ng-container>
    </ng-container>

    @if (projectSummaries()) {
      @if (projectSummaries()!.length > 0) {
        <mat-divider></mat-divider>

        <app-project-layer-group
          [groupName]="'MAP.LAYERS.SAVED_PROJECTS' | translate"
          [projectSummaries]="projectSummaries()"
          (activeProjectLayer)="onProjectLayerStateChanged($event)" />
      }
    }
  </ul>

  <ng-template #notLayerGroupTemplate let-item>
    @if (item.canBeToggled) {
      <mat-divider></mat-divider>

      <app-layer
        [layer]="item"
        (layerSelectionChanged)="onLayerStateChange($event)" />
    }
  </ng-template>

  <ng-template #layerGroupTemplate let-layerGroup>
    <mat-divider></mat-divider>
    <app-layer-group-header
      [groupName]="layerGroup.groupName | translate"
      [icon]="layerGroup.groupIcon" />
    <ng-container *ngFor="let layer of layerGroup.layers">
      <app-layer
        [isGroupChild]="true"
        [layer]="layer"
        (layerSelectionChanged)="onLayerStateChange($event)">
      </app-layer>
    </ng-container>
  </ng-template>
</form>
