import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { AttributeStationChipsComponent } from '@app/map/components/spot-information/components/attribute-chips/attribute-station-chips.component';
import { ChargingCapacityCalculator } from '@app/map/components/spot-information/util';
import {
  KWhFormatPipe,
  MeterFormatPipe,
  Spot,
  TooltipPipe,
  TooltipShowDelayPipe,
} from '@app/shared';
import { TOOLTIP } from '@app/shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { AttributeTitleComponent } from '../attribute-title/attribute-title.component';
import { AttributeComponent } from '../attribute/attribute.component';
import { ScoreChartComponent } from '../score-chart/score-chart.component';
import { MatDivider } from '@angular/material/divider';
import { MoneyRangeFormatPipe } from '../../pipes';

@Component({
  selector: 'app-spot-tab',
  standalone: true,
  imports: [
    CommonModule,
    AttributeTitleComponent,
    AttributeComponent,
    AttributeStationChipsComponent,
    MatTooltipModule,
    TranslateModule,
    TooltipPipe,
    TooltipShowDelayPipe,
    ScoreChartComponent,
    MatDivider,
    MeterFormatPipe,
    KWhFormatPipe,
    MoneyRangeFormatPipe,
  ],
  templateUrl: './spot-tab.component.html',
  styleUrls: ['./spot-tab.component.scss'],
})
export class SpotTabComponent implements OnChanges {
  @Input() public spot!: Spot;
  @Input() public showNewValueIndicator = false;
  @Input() public attributeToolTipPosition: TooltipPosition = 'left';

  public readonly TT = TOOLTIP;
  public totalChargingCapacityInKw?: number;

  public ngOnChanges(changes: SimpleChanges): void {
    const changeIdentifierSpot = 'spot';

    if (changes[changeIdentifierSpot]) {
      this.totalChargingCapacityInKw = ChargingCapacityCalculator.calc(
        this.spot.stations
      );
    }
  }
}
