<div class="message" [ngClass]="{ 'message-spacing': spacing === true }">
  <mat-icon
    class="icon"
    fontSet="material-symbols-outlined"
    [matTooltipDisabled]="responsive === false"
    [matTooltip]="labelTranslationIdentifier | translate"
    >{{ icon }}</mat-icon
  >

  <p class="label" [ngClass]="{ 'label-responsive': responsive === true }">
    {{ labelTranslationIdentifier | translate }}
  </p>
</div>
