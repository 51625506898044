import { isNil } from 'lodash-es';
import { ApplicableCondition } from '@app/location-table-page';

export class ApplicableConditionExaminer {
  public static isValid(applicableCondition: ApplicableCondition): boolean {
    let output = true;

    Object.getOwnPropertyNames(applicableCondition).forEach(property => {
      if (isNil(property)) {
        output = false;
      }
    });

    return output;
  }
}
