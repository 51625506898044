@if (regionsRestriction()) {
  <mgl-geojson-source
    [id]="LAYER_NAME.REGIONS_RESTRICTION"
    [data]="regionsRestriction()!" />

  <mgl-layer
    [id]="LAYER_NAME.REGIONS_RESTRICTION"
    type="fill"
    [source]="LAYER_NAME.REGIONS_RESTRICTION"
    [paint]="{
      'fill-color': '#444',
      'fill-opacity': 0.5
    }">
  </mgl-layer>
}
