import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatTooltipModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input()
  public icon!: string;

  @Input()
  public labelTranslationIdentifier!: string;

  @Input()
  public responsive = true;

  @Input()
  public spacing = false;
}
