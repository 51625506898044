import {
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
  input,
} from '@angular/core';
import { MarkerColorPipe } from '../spot-marker-group/marker-color.pipe';
import { SpotMarkerComponent } from '../spot-marker/spot-marker.component';
import { MarkerClassPipe } from '../spot-marker-group/marker-class.pipe';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { ProjectDto, MARKER_COLOR, LocationDto } from '@app/shared';
import { SpotClickEvent } from '@app/map/util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Popup, Map as MaplibreMap } from 'maplibre-gl';

@Component({
  selector: 'app-project-location-marker-group',
  standalone: true,
  templateUrl: './project-location-marker-group.component.html',
  styleUrls: [],
  imports: [
    MarkerColorPipe,
    SpotMarkerComponent,
    MarkerClassPipe,
    NgxMapLibreGLModule,
    TranslateModule,
  ],
})
export class ProjectLocationMarkerGroupComponent {
  project = input.required<ProjectDto>();
  @Input({ required: true }) public map?: MaplibreMap;
  @Output() public clicked = new EventEmitter<SpotClickEvent>();

  readonly #translateService = inject(TranslateService);

  public MARKER_COLOR = MARKER_COLOR;
  public popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  });
  public locations: LocationDto[] = [];

  constructor() {
    effect(() => {
      this.locations = this.project()?.locations ?? [];
    });
  }

  public onLocationMarkerClicked($event: Event, spot: LocationDto) {
    this.clicked.emit(new SpotClickEvent($event, spot, false));
  }

  public onMouseEnter($event: MouseEvent, feature: LocationDto) {
    this.popup
      .setLngLat([feature.longitude, feature.latitude])
      .setHTML(
        `
        <section class="project-location-marker-group">
          <h1 class="project-location-marker-group-headline">
          ${this.#translateService.instant(
            'MAP.POPUP_MARKER_SAVED_IN_PROJECT.PROJECT_NAME'
          )}:
          <span>${this.project().name}</span>
        </h1>
      </section>
    `
      )
      .addTo(this.map!);
  }

  public onMouseLeave() {
    this.popup.remove();
  }
}
