import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import {
  LayerComponent,
  LayerSelectionState,
} from './components/layer/layer.component';
import { IsLayerGroupPipe } from '@app/shared';
import { LAYER_GROUP_INFO, LayerWrapper, LayerWrapperOrGroup } from '@app/map';
import { FiltersComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer/components';
import { ProjectLayerGroupComponent } from '@app/map/components/sidebar/components/layer-panel/components/project-layer-group/project-layer-group.component';
import { LayerGroupHeaderComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer-group-header/layer-group-header.component';
import { ProjectSummary } from '@app/map/components/sidebar/components/layer-panel/components/project-layer-group/project-summary';

@Component({
  selector: 'app-layer-panel',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    TranslateModule,
    MatDividerModule,
    MatListModule,
    IsLayerGroupPipe,
    MatIconModule,
    MatSliderModule,
    FiltersComponent,
    LayerComponent,
    ProjectLayerGroupComponent,
    LayerGroupHeaderComponent,
  ],
  templateUrl: './layer-panel.component.html',
  styleUrls: ['./layer-panel.component.scss'],
})
export class LayerPanelComponent implements OnInit, OnChanges {
  @Input() public layers?: LayerWrapper[] = [];
  projectSummaries = input<ProjectSummary[] | null>(null);

  @Output() public layerStageChanged = new EventEmitter<LayerSelectionState>();
  @Output() public projectLayerStateChanged = new EventEmitter<string[]>();
  readonly #fb = inject(FormBuilder);

  public layersOrGroups: LayerWrapperOrGroup[] = [];
  public formGroup: FormGroup = this.#fb.group({});

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['layers'] && changes['layers'].previousValue === undefined) {
      this.layersOrGroups = this.#groupLayers(changes['layers'].currentValue);
    }
  }

  public ngOnInit(): void {
    this.layersOrGroups = this.#groupLayers(this.layers);
  }

  #groupLayers(layers: LayerWrapper[] = []): LayerWrapperOrGroup[] {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const layerGroups: { [key: string]: any } = {};

    layers.forEach(layer => {
      if (layer.group) {
        if (!layerGroups[layer.group]) {
          layerGroups[layer.group] = {
            groupName: LAYER_GROUP_INFO[layer.group].groupName,
            groupIcon: LAYER_GROUP_INFO[layer.group].groupIcon,
            layers: [],
          };
        }
        layerGroups[layer.group].layers.push(layer);
      } else {
        layerGroups[layer?.name] = layer;
      }
    });

    return Object.values(layerGroups);
  }

  public onLayerStateChange($event: LayerSelectionState): void {
    this.layerStageChanged.emit($event);
  }

  public onProjectLayerStateChanged($event: string[]): void {
    this.projectLayerStateChanged.emit($event);
  }
}
