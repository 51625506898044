<div class="attribute-chips" *ngIf="chips && chips.length > 0">
  <div class="line">
    <div class="line-start">
      <mat-icon
        *ngIf="infoTranslationIdentifier"
        class="icon"
        fontSet="material-symbols-outlined"
        [matTooltip]="infoTranslationIdentifier | translate"
        >info
      </mat-icon>

      <span class="label">{{ labelTranslationIdentifier | translate }}</span>
    </div>

    <span class="chips">
      <mat-chip-set>
        <mat-chip-option class="not-clickable" *ngFor="let station of chips">
          {{ station.chargingPower }} {{ 'COMMON.KW' | translate }}
        </mat-chip-option>
      </mat-chip-set>
    </span>
  </div>
</div>
