import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatListModule,
  MatListOption,
  MatSelectionListChange,
} from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingService, SearchService } from '@app/core';
import { SpotsListElementComponent } from '@app/map/components/sidebar/components/spots-found/components/spots-list-element/spots-list-element.component';
import {
  RankingColorPipe,
  SuffixTextPipe,
} from '@app/map/components/sidebar/components/spots-found/pipes';
import { MATERIAL_THEME_COLOR, Spot } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { SpotOnHoverEvent } from '@app/map/components/sidebar/components/spots-found';
import {
  RankBy,
  SpotsSkeletonListComponent,
} from '@app/map/components/sidebar/components/spots-found/components';

@Component({
  selector: 'app-spots-list',
  standalone: true,
  imports: [
    CommonModule,
    SpotsListElementComponent,
    RankingColorPipe,
    TranslateModule,
    FormsModule,
    MatDividerModule,
    MatListModule,
    ReactiveFormsModule,
    SuffixTextPipe,
    MatProgressSpinnerModule,
    SpotsSkeletonListComponent,
  ],
  templateUrl: './spots-list.component.html',
  styleUrls: ['./spots-list.component.scss'],
})
export class SpotsListComponent {
  readonly #fb = inject(FormBuilder);
  readonly #searchService = inject(SearchService);

  public readonly SPOT_SELECTION_CONTROL_NAME = 'selectedSpot';
  public rankBy$: Observable<RankBy | undefined>;

  @Input() public spots: Spot[] = [];
  @Input() public showPrefixes = true;
  @Input() public showSuffixes = true;

  @Input()
  public set selectedSpot(spot: Spot | undefined) {
    this.spotSelection.get(this.SPOT_SELECTION_CONTROL_NAME)?.setValue([spot]);
  }

  @Output() public selectedSpotChanged = new EventEmitter();
  @Output() public spotOnHover = new EventEmitter<SpotOnHoverEvent>();
  public readonly isLoading: Signal<boolean> = inject(LoadingService).isLoading;

  public MATERIAL_THEME_COLOR = MATERIAL_THEME_COLOR;
  public readonly spotSelection: FormGroup = this.#fb.group({
    [this.SPOT_SELECTION_CONTROL_NAME]: new FormControl([]),
  });

  constructor() {
    this.rankBy$ = this.#searchService.lastSubmittedSearch$.pipe(
      map(searchValues => searchValues?.rankBy)
    );
  }

  public selectionChange($event: MatSelectionListChange): void {
    const selectedOption: MatListOption[] = Array.from($event.options).filter(
      option => option.selected
    );

    if (selectedOption.length > 0) {
      this.selectedSpotChanged.emit(selectedOption[0].value);
    }
  }

  public onMouseEnter(spot: Spot): void {
    this.spotOnHover.emit({
      hover: true,
      spot,
    });
  }

  public onMouseLeave(): void {
    this.spotOnHover.emit({
      hover: false,
      spot: undefined,
    });
  }
}
