import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FilterFormValue } from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components/filter/filter-form-value';
import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import { ProjectFromErrorPipe } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { map, tap } from 'rxjs';
import {
  ApplicableCondition,
  ColumnMetaData,
  ConditionMeta,
  ConditionProvider,
  LocationRowService,
} from '@app/location-table-page';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ProjectFromErrorPipe,
  ],
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
})
export class FilterFormComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  public readonly CRITERION_FORM_NAME = 'criterion';
  public readonly CONDITION_FORM_NAME = 'condition';
  public readonly VALUE_FORM_NAME = 'value';
  public readonly FORM_GROUP_NAME = 'conditionsForm';
  public showFilterResult = false;
  public amountOfOriginRows: number;

  @Input()
  public set criteria(criteria: ColumnMetaData[]) {
    this._criteria = criteria.filter(criterion => criterion.isFilterable);
    this._criteriaByColumnIdentifier = new Map(
      this._criteria.map(columnMeta => [
        columnMeta.columnIdentifier,
        columnMeta,
      ])
    );
  }

  @Input() public conditions: ConditionMeta[] = [];
  @Input() public appliedConditions: ApplicableCondition[] = [];
  @Output() public currentFilterFormValues = new EventEmitter<
    FilterFormValue[]
  >();

  public form!: FormGroup;
  public _criteria: ColumnMetaData[] = [];
  private _criteriaByColumnIdentifier: Map<COLUMN_IDENTIFIER, ColumnMetaData> =
    new Map();

  readonly #fb = inject(FormBuilder);
  readonly locationRowService = inject(LocationRowService);

  constructor() {
    this.amountOfOriginRows = this.locationRowService.originLocationRows.length;
  }

  public ngOnInit(): void {
    this.initFormGroup();

    this.form
      .get(this.FORM_GROUP_NAME)
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap((filterFromValues: FilterFormValue[]) =>
          this.setFromGroupThresholds(filterFromValues)
        ),
        map(formValues =>
          formValues.filter((value: FilterFormValue) =>
            this.isValidCondition(value)
          )
        )
      )
      .subscribe((conditions: FilterFormValue[]) => {
        this.form.updateValueAndValidity();
        if (this.isMoreThanOneFieldInvalid(conditions)) {
          this.currentFilterFormValues.emit([]);
          this.showFilterResult = false;
        } else {
          this.currentFilterFormValues.emit(conditions);
          this.showFilterResult = true;
        }
      });
  }

  public get conditionsForm(): FormArray<FormGroup> {
    return this.form.controls[this.FORM_GROUP_NAME] as FormArray;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isValidCondition(condition: any): boolean {
    let output = true;

    [
      this.CONDITION_FORM_NAME,
      this.CRITERION_FORM_NAME,
      this.VALUE_FORM_NAME,
    ].forEach((key: string) => {
      if (isNil(condition[key])) {
        output = false;
      }
    });
    if (condition.value < 0) {
      output = false;
    }
    return output;
  }

  private isMoreThanOneFieldInvalid(filterFrom: FilterFormValue[]): boolean {
    const trashHoldForInvalidForms = 2;

    return (
      this.conditionsForm.length - filterFrom.length >= trashHoldForInvalidForms
    );
  }

  public deleteFilter(index: number): void {
    this.conditionsForm.removeAt(index);
  }

  public addNewForm(isLast: boolean): void {
    if (isLast) {
      this.addFilter();
    }
  }

  private addFilter(): void {
    this.conditionsForm.push(this.buildFromGroup());
  }

  private buildFromGroup(
    criterionInitialValue?: unknown,
    conditionInitialValue?: ConditionMeta,
    initialValue?: number
  ): FormGroup {
    return this.#fb.group({
      [this.CRITERION_FORM_NAME]: [criterionInitialValue, Validators.required],
      [this.CONDITION_FORM_NAME]: {
        value: conditionInitialValue,
        disabled: !conditionInitialValue,
      },
      [this.VALUE_FORM_NAME]: {
        value: initialValue,
        disabled: !initialValue,
      },
    });
  }

  private initFormGroup(): void {
    const formArray: FormGroup[] = this.initFormArray();

    this.form = this.#fb.group({
      [this.FORM_GROUP_NAME]:
        formArray.length > 0
          ? this.#fb.array([...formArray, this.buildFromGroup()])
          : this.#fb.array([this.buildFromGroup()]),
    });
  }

  private initFormArray(): FormGroup[] {
    return this.appliedConditions.map(condition => {
      return this.buildFromGroup(
        this._criteriaByColumnIdentifier?.get(
          condition.criterion as COLUMN_IDENTIFIER
        ),
        ConditionProvider.INSTANCE.getConditionsByConditionType(
          condition.conditionType
        ),
        condition.value
      );
    });
  }

  private setFromGroupThresholds(it: FilterFormValue[]): void {
    it.forEach((value: FilterFormValue, index: number) => {
      if (!isNil(value.criterion)) {
        const validators = [Validators.required];

        if (!isNil(value.criterion.minValue)) {
          validators.push(Validators.min(value.criterion.minValue));
        }
        if (!isNil(value.criterion.maxValue)) {
          validators.push(Validators.max(value.criterion.maxValue));
        }

        this.conditionsForm.controls[index]
          .get(this.VALUE_FORM_NAME)
          ?.setValidators(validators);
        this.conditionsForm.controls[index]
          .get(this.VALUE_FORM_NAME)
          ?.enable({ emitEvent: false });
        this.conditionsForm.controls[index]
          .get(this.CONDITION_FORM_NAME)
          ?.enable({ emitEvent: false });

        this.conditionsForm.controls[index]
          .get(this.CONDITION_FORM_NAME)
          ?.setValidators([Validators.required]);
      }
    });
  }
}
