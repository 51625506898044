<div class="attribute">
  <div class="line">
    <div class="line-start">
      <span
        *ngIf="tooltipTranslationIdentifier; else noTooltip"
        [matTooltip]="tooltipTranslationIdentifier | translate"
        [matTooltipShowDelay]="tooltipShowDelay"
        [matTooltipPosition]="tooltipPosition"
        class="label"
        >{{ labelTranslationIdentifier | translate }}
      </span>
      <ng-template #noTooltip>
        <span class="label">{{ labelTranslationIdentifier | translate }}</span>
      </ng-template>
    </div>

    <div class="value-container">
      <span
        data-testid="numerator-value"
        *ngIf="numerator"
        [matTooltip]="
          numeratorToolTipTranslationIdentifier
            ? (numeratorToolTipTranslationIdentifier | translate)
            : ''
        "
        [matTooltipShowDelay]="tooltipShowDelay"
        class="value"
        >{{ numerator }}</span
      >

      <span *ngIf="denominator" class="delimiter"> {{ delimiter }} </span>
      <span
        data-testid="denominator-value"
        *ngIf="denominator"
        class="value"
        [matTooltip]="
          denominatorToolTipTranslationIdentifier
            ? (denominatorToolTipTranslationIdentifier | translate)
            : ''
        "
        [matTooltipShowDelay]="tooltipShowDelay"
        >{{ denominator }}</span
      >

      <div
        *ngIf="isNewValueIndicatorDisplayed"
        [matTooltip]="
          isNewValueIndicatorDisplayed
            ? (newValueIndicatorToolTipTranslationIdentifier | translate)
            : ''
        "
        [matTooltipShowDelay]="tooltipShowDelay"
        class="new-value-indicator"></div>
    </div>
  </div>
</div>
