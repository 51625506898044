@if (circle()) {
  <mgl-geojson-source [id]="LAYER_NAME" [data]="circle()!"></mgl-geojson-source>
  <mgl-layer
    [id]="LAYER_NAME"
    type="line"
    [source]="LAYER_NAME"
    [paint]="{
      'line-color': '#444',
      'line-width': 3,
      'line-offset': 5,
      'line-opacity': 0.7,
      'line-dasharray': [1, 1]
    }">
  </mgl-layer>
}
