import { Pipe, PipeTransform } from '@angular/core';
import { ThresholdColorConfigProvider } from '@app/map/util';
import {
  LocationRecommendationDiscriminator,
  MARKER_COLOR,
  Spot,
} from '@app/shared';

@Pipe({
  name: 'markerColor',
  standalone: true,
})
export class MarkerColorPipe implements PipeTransform {
  private static readonly thresholdConfig = ThresholdColorConfigProvider.get();

  public transform(spot?: Spot, value?: number): MARKER_COLOR {
    let locationColor: MARKER_COLOR = MARKER_COLOR.LIGHT_BLUE;
    if (spot && LocationRecommendationDiscriminator.isLocation(spot)) {
      locationColor = MARKER_COLOR.RED;
    } else {
      if (
        typeof value !== 'undefined' &&
        value <= MarkerColorPipe.thresholdConfig.thresholdDarkblue
      ) {
        locationColor = MARKER_COLOR.DARK_BLUE;
      } else if (
        value &&
        value <= MarkerColorPipe.thresholdConfig.upperThresholdBlue &&
        value >= MarkerColorPipe.thresholdConfig.lowerThresholdBlue
      ) {
        locationColor = MARKER_COLOR.BLUE;
      }
    }

    return locationColor;
  }
}
