import { COUNTRY_CODE, LocationEstimationRequestDto } from '@app/shared';

export class LocationEstimationDtoMapper {
  public static map(
    longitude: number,
    latitude: number,
    country?: string,
    chargingStations?: number,
    locationMaxKW?: number
  ): LocationEstimationRequestDto {
    return {
      longitude,
      latitude,
      chargingStations,
      locationMaxKW,
      countryCode: country?.toUpperCase() as COUNTRY_CODE,
    };
  }
}
