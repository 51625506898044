import { CONDITION_TYPE } from '@app/location-table-page/enum';

export class ApplicableCondition {
  readonly #conditionType: CONDITION_TYPE;
  readonly #conditionTypeTranslationIdentifier: string;
  readonly #value: number;
  readonly #criterion: string;
  readonly #criterionTranslationIdentifier: string;

  readonly #filter: (locationValue: number, formValue: number) => boolean;

  constructor(
    conditionType: CONDITION_TYPE,
    conditionTypeTranslationIdentifier: string,
    value: number,
    criterion: string,
    criterionTranslationIdentifier: string,
    filter: (locationValue: number, formValue: number) => boolean
  ) {
    this.#conditionType = conditionType;
    this.#conditionTypeTranslationIdentifier =
      conditionTypeTranslationIdentifier;
    this.#value = value;
    this.#criterion = criterion;
    this.#criterionTranslationIdentifier = criterionTranslationIdentifier;
    this.#filter = filter;
  }

  public get conditionType(): CONDITION_TYPE {
    return this.#conditionType;
  }

  public get conditionTypeTranslationIdentifier(): string {
    return this.#conditionTypeTranslationIdentifier;
  }

  public get value(): number {
    return this.#value;
  }

  public get criterion(): string {
    return this.#criterion;
  }

  public get criterionTranslationIdentifier(): string {
    return this.#criterionTranslationIdentifier;
  }

  public apply(input: number): boolean {
    return this.#filter(input, this.value);
  }
}
