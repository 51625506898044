import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { StationDto } from '@app/shared';

@Component({
  selector: 'app-attribute-station-chips',
  standalone: true,
  templateUrl: './attribute-station-chips.component.html',
  styleUrls: ['./attribute-station-chips.component.scss'],
  imports: [
    MatIconModule,
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatChipsModule,
  ],
})
export class AttributeStationChipsComponent {
  @Input() public chips?: StationDto[];

  @Input()
  public labelTranslationIdentifier!: string;

  @Input()
  public infoTranslationIdentifier?: string;
}
