<app-dialog-header (closeButtonClicked)="closeExcludingChanges()">
  {{ 'COMMON.FILTER' | translate }}
</app-dialog-header>

<mat-dialog-content>
  <div class="dialog__body">
    <app-filter
      [criteria]="data.columMetaDate"
      [conditions]="data.conditions"
      [appliedConditions]="data.appliedConditions"
      (currentFilterFormValues)="onConditionsChange($event)">
    </app-filter>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    (click)="closeExcludingChanges()"
    tabindex="-1"
    mat-stroked-button
    color="primary"
    class="small">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button
    (click)="closeIncludingChanges()"
    [disabled]="currentConditions.length === 0"
    id="create-button"
    tabindex="-1"
    mat-flat-button
    color="primary"
    class="small">
    {{ 'COMMON.APPLY' | translate }}
  </button>
</mat-dialog-actions>
