import { Parser } from '@json2csv/plainjs';

type Format = 'csv';

export class Exporter {
  public execute(format: Format, input: Array<unknown>): string {
    // currently only csv needed, add more formats if needed
    return this.csv(input);
  }

  private csv(input: Array<unknown>) {
    try {
      const parser = new Parser();
      return parser.parse(input);
    } catch (err) {
      throw new Error('Error while exporting csv.');
    }
  }
}
