export interface ThresholdColorConfig {
  thresholdDarkblue: number;
  lowerThresholdBlue: number;
  upperThresholdBlue: number;
}
export class ThresholdColorConfigProvider {
  public static get(): ThresholdColorConfig {
    return {
      thresholdDarkblue: 2,
      lowerThresholdBlue: 3,
      upperThresholdBlue: 5,
    };
  }
}
