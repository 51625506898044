import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { GeoserverLayerService, LayerFilterType, LayerWrapper } from '@app/map';
import { FiltersComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer/components';

export interface LayerSelectionState {
  name: string;
  layer: LayerWrapper;
  isActive: boolean;
}

@Component({
  selector: 'app-layer',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FiltersComponent,
  ],
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class LayerComponent implements OnInit {
  @Input() public layer!: LayerWrapper;
  @Input() public isGroupChild = false;
  @Output() public layerSelectionChanged =
    new EventEmitter<LayerSelectionState>();

  readonly #destroyRef = inject(DestroyRef);
  public layerForm: FormGroup = new FormGroup({});
  public filters: LayerFilterType[] = [];
  readonly #geoserverLayerService = inject(GeoserverLayerService);

  public ngOnInit(): void {
    this.initLayerForm();
  }

  private initLayerForm(): void {
    this.filters = this.#geoserverLayerService.getFiltersByLayerName(
      this.layer.name
    );

    this.layerForm = new FormGroup({
      [this.layer.name]: new FormControl(this.layer.isActive),
    });

    this.layerForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(formGroup => {
        this.layerSelectionChanged.emit({
          name: this.layer.name,
          layer: this.layer,
          isActive: formGroup[this.layer.name],
        });
      });
  }
}
