import { ColumnProvider } from '@app/location-table-page/column-provider';
import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import { ExportFormat } from '@app/location-table-page/util';
import { LoactionTableInput } from './location-table-input';

export class ExportMapper {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static map(
    input: LoactionTableInput[],
    tableHeader: COLUMN_IDENTIFIER[]
  ): any[] {
    return input.map((location: LoactionTableInput) => {
      const mappedObj: any = {};
      tableHeader.forEach(header => {
        const columMetaData =
          ColumnProvider.INSTANCE.displayColumnsByIdentifier.get(header);
        if (columMetaData) {
          switch (columMetaData.columnIdentifier) {
            case COLUMN_IDENTIFIER.CHARGING_STATION_CONFIGURATION: {
              if (columMetaData.formatForExport) {
                const mappedStations: ExportFormat[] =
                  columMetaData.formatForExport(location) as ExportFormat[];
                mappedStations.forEach(exportFormat => {
                  mappedObj[exportFormat.header] = exportFormat.value;
                });
              }
              break;
            }
            case COLUMN_IDENTIFIER.CONSUMPTION_YEAR_ESTIMATION: {
              if (columMetaData.formatForExport) {
                const mappedStations: ExportFormat[] =
                  columMetaData.formatForExport(
                    location.consumptionYearEstimation,
                    COLUMN_IDENTIFIER.CONSUMPTION_YEAR_ESTIMATION
                  ) as ExportFormat[];
                mappedStations.forEach(exportFormat => {
                  mappedObj[exportFormat.header] = exportFormat.value;
                });
              }
              break;
            }
            case COLUMN_IDENTIFIER.SESSIONS_YEAR_ESTIMATION: {
              if (columMetaData.formatForExport) {
                const mappedStations: ExportFormat[] =
                  columMetaData.formatForExport(
                    location.sessionsYearEstimation,
                    COLUMN_IDENTIFIER.SESSIONS_YEAR_ESTIMATION
                  ) as ExportFormat[];
                mappedStations.forEach(exportFormat => {
                  mappedObj[exportFormat.header] = exportFormat.value;
                });
              }
              break;
            }
            default: {
              if (columMetaData.formatForExport) {
                const exportValues = columMetaData.formatForExport(
                  location[columMetaData.columnIdentifier],
                  columMetaData.columnIdentifier
                ) as ExportFormat[];

                exportValues.forEach(exportFormat => {
                  mappedObj[exportFormat.header] = exportFormat.value;
                });
              } else {
                mappedObj[header] = columMetaData?.formatter
                  ? columMetaData.formatter(
                      location[columMetaData.columnIdentifier]
                    )
                  : location[columMetaData.columnIdentifier];
              }
              break;
            }
          }
        } else {
          throw new Error(`No columMetaData for ${header}`);
        }
      });
      /* eslint-enable @typescript-eslint/no-explicit-any */
      return mappedObj;
    });
  }
}
