<ng-container *ngIf="areaRestrictions()">
  <mgl-geojson-source
    [id]="LAYER_NAME.AREA_RESTRICTION"
    [data]="areaRestrictions()?.radiusRestrictionLayer"></mgl-geojson-source>
  <mgl-layer
    [id]="LAYER_NAME.AREA_RESTRICTION"
    type="fill"
    [source]="LAYER_NAME.AREA_RESTRICTION"
    [paint]="{
      'fill-color': '#444',
      'fill-opacity': 0.5
    }">
  </mgl-layer>
</ng-container>
