import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import { ColumnMetaData } from '@app/location-table-page/location-table-page.component';
import { ColumnFormatters } from '@app/location-table-page/util';

export class ColumnProvider {
  public static readonly INSTANCE = new ColumnProvider();

  readonly #displayColumns: ColumnMetaData[] = [
    {
      columnIdentifier: COLUMN_IDENTIFIER.ID,
      labelTranslationIdentifier: 'COMMON.ID',
      isDisplayed: false,
      isFilterable: false,
      isSortable: true,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.ADDRESS,
      labelTranslationIdentifier: 'COMMON.ADDRESS',
      isDisplayed: true,
      isFilterable: false,
      formatter: ColumnFormatters.formatAddress,
      isSortable: true,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.COST_ESTIMATION_SCORE_LOCAL,
      labelTranslationIdentifier: 'COMMON.GRID_SCORE_LOCAL',
      isDisplayed: true,
      isFilterable: true,
      isSortable: true,
      minValue: 0,
      maxValue: 10,
      formatter: ColumnFormatters.formatScore,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.COST_ESTIMATION_SCORE,
      labelTranslationIdentifier: 'COMMON.COUNTRY_GRID_SCORE',
      isDisplayed: true,
      isFilterable: true,
      isSortable: true,
      minValue: 0,
      maxValue: 10,
      formatter: ColumnFormatters.formatScore,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.USAGE_ESTIMATION_SCORE_LOCAL,
      labelTranslationIdentifier: 'COMMON.USAGE_SCORE_LOCAL',
      isDisplayed: true,
      isFilterable: true,
      isSortable: true,
      minValue: 0,
      maxValue: 10,
      formatter: ColumnFormatters.formatScore,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.USAGE_ESTIMATION_SCORE,
      labelTranslationIdentifier: 'COMMON.COUNTRY_USAGE_SCORE',
      isDisplayed: true,
      isFilterable: true,
      isSortable: true,
      minValue: 0,
      maxValue: 10,
      formatter: ColumnFormatters.formatScore,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.TOTAL_COST_ESTIMATION,
      labelTranslationIdentifier: 'COMMON.ESTIMATED_COSTS',
      isDisplayed: true,
      isFilterable: false,
      isSortable: false,
      isRange: true,
      isCurrency: true,
      formatForExport: ColumnFormatters.formatRangeExport,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.EV_DENSITY,
      labelTranslationIdentifier: 'COMMON.EV_DENSITY_PER_SQUARE_KM',
      isDisplayed: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.POPULATION_DENSITY,
      labelTranslationIdentifier: 'COMMON.POPULATION_DENSITY',
      isDisplayed: false,
      isFilterable: true,
      isSortable: true,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.CHARGING_STATION_CONFIGURATION,
      labelTranslationIdentifier: 'COMMON.CHARGING_STATIONS',
      isDisplayed: false,
      isFilterable: true,
      isSortable: false,
      formatForExport: ColumnFormatters.formatChargingStationConfiguration,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.SESSION_DAY_ESTIMATION,
      labelTranslationIdentifier:
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_DAY_ESTIMATION',
      isDisplayed: false,
      isRange: true,
      roundingPoints: 2,
      isFilterable: false,
      isSortable: false,
      formatForExport: ColumnFormatters.formatRangeExport,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.SESSIONS_YEAR_ESTIMATION,
      labelTranslationIdentifier:
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_YEAR_ESTIMATION',
      isDisplayed: false,
      isRange: true,
      roundingPoints: 2,
      isFilterable: false,
      isSortable: false,
      formatForExport: ColumnFormatters.formatRangeExport,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.CONSUMPTION_DAY_ESTIMATION,
      labelTranslationIdentifier: 'TABLE_VIEW.CONSUMPTION_PER_DAY_ESTIMATION',
      isDisplayed: false,
      isRange: true,
      roundingPoints: 2,
      isFilterable: false,
      isSortable: false,
      formatForExport: ColumnFormatters.formatRangeExport,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.CONSUMPTION_YEAR_ESTIMATION,
      labelTranslationIdentifier: 'TABLE_VIEW.CONSUMPTION_PER_YEAR_ESTIMATION',
      isDisplayed: false,
      isRange: true,
      roundingPoints: 2,
      isFilterable: false,
      isSortable: false,
      formatForExport: ColumnFormatters.formatRangeExport,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.LATITUDE,
      labelTranslationIdentifier: 'COMMON.LATITUDE',
      isDisplayed: false,
      isFilterable: true,
      isSortable: true,
      isRange: false,
      isCurrency: false,
      formatter: ColumnFormatters.roundNumber,
    },
    {
      columnIdentifier: COLUMN_IDENTIFIER.LONGITUDE,
      labelTranslationIdentifier: 'COMMON.LONGITUDE',
      isDisplayed: false,
      isFilterable: true,
      isSortable: true,
      isRange: false,
      isCurrency: false,
      formatter: ColumnFormatters.roundNumber,
    },
  ];

  readonly #displayColumnsByIdentifier: Map<COLUMN_IDENTIFIER, ColumnMetaData> =
    new Map();

  constructor() {
    this.#displayColumns.forEach(column =>
      this.#displayColumnsByIdentifier.set(column.columnIdentifier, column)
    );
  }

  public get displayColumnsByIdentifier(): Map<
    COLUMN_IDENTIFIER,
    ColumnMetaData
  > {
    return this.#displayColumnsByIdentifier;
  }
}
