<div class="attribute-title">
  <mat-icon class="icon" fontSet="material-symbols-outlined">
    {{ icon }}
  </mat-icon>
  @if (tooltipTranslationIdentifier) {
    <span
      class="title"
      [matTooltip]="tooltipTranslationIdentifier | translate"
      [matTooltipShowDelay]="tooltipShowDelay"
      [matTooltipPosition]="tooltipPosition"
      >{{ titleTranslationIdentifier | translate }}</span
    >
  } @else {
    <span class="title">{{ titleTranslationIdentifier | translate }}</span>
  }
  @if (subTitle) {
    <span class="sub-title">{{ subTitle }}</span>
  }
</div>
