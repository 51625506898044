import { AsyncPipe, NgIf } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MARKER_SIZE } from '@app/map/components/spot-marker/marker-size.enum';
import { MARKER_COLOR, MATERIAL_THEME_COLOR } from '@app/shared';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf, AsyncPipe],
})
export class MarkerComponent implements OnInit {
  private static readonly DIAMETER_FOR_NORMAL_SIZE = 15;
  private static readonly DIAMETER_FOR_BIG_SIZE = 20;

  @Input()
  public loading$: Observable<boolean> = of(false);

  @Input()
  public color: MARKER_COLOR = MARKER_COLOR.RED;

  @Input()
  public size: 'normal' | 'big' = 'normal';

  @Output()
  public clicked = new EventEmitter<MouseEvent>();

  readonly #destroyRef = inject(DestroyRef);
  public diameter = MarkerComponent.DIAMETER_FOR_NORMAL_SIZE;

  public indicatorColor: MATERIAL_THEME_COLOR = MATERIAL_THEME_COLOR.ACCENT;

  public ngOnInit(): void {
    this.loading$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.diameter = this.#determineDiameter();
      this.indicatorColor = this.#determineIndicatorColor();
    });
  }

  public onClicked($ev: MouseEvent): void {
    this.clicked.emit($ev);
  }

  #determineDiameter(): number {
    if (this.size && this.size === MARKER_SIZE.NORMAL) {
      return MarkerComponent.DIAMETER_FOR_NORMAL_SIZE;
    } else if (this.size && this.size === MARKER_SIZE.BIG) {
      return MarkerComponent.DIAMETER_FOR_BIG_SIZE;
    } else {
      return MarkerComponent.DIAMETER_FOR_NORMAL_SIZE;
    }
  }

  #determineIndicatorColor(): MATERIAL_THEME_COLOR {
    let indicatorColor = MATERIAL_THEME_COLOR.ACCENT;

    if (this.color) {
      indicatorColor =
        this.color === MARKER_COLOR.RED
          ? MATERIAL_THEME_COLOR.PRIMARY
          : MATERIAL_THEME_COLOR.ACCENT;
    }
    return indicatorColor;
  }
}
