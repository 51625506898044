<ng-container *ngIf="spotsFoundOptions" class="container">
  <div class="ranking" *ngIf="showRanking">
    <app-spots-ranking [freeToPlay]="freeToPlay" />
  </div>
  <app-spots-list
    [spots]="spotsFoundOptions"
    [showPrefixes]="showPrefixes"
    [selectedSpot]="selectedSpot"
    (selectedSpotChanged)="selectionChange($event)"
    (spotOnHover)="onSpotHover($event)" />
</ng-container>
