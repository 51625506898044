import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { RANKING_COLOR } from '@app/map/components/sidebar/components/spots-found';

@Component({
  selector: 'app-spots-list-element-prefix',
  templateUrl: './spots-list-element-prefix.component.html',
  styleUrls: ['./spots-list-element-prefix.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class SpotsListElementPrefixComponent {
  @Input() public prefixColor?: RANKING_COLOR;

  public readonly RANKING_COLOR = RANKING_COLOR;
}
