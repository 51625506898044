@if (isHidden() === false) {
  <mgl-control [position]="position">
    <button
      mat-stroked-button
      class="search-here-btn small"
      (click)="onBtnClick()">
      <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
      <ng-content />
    </button>
  </mgl-control>
}
