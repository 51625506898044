import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { ErrorHandlerService, ProjectService } from '@app/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { APP_ROUTES, ProjectWithLocationDto } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class LocationTableViewResolver {
  private static readonly PROJECT_IDENTIFIER_PARAM = 'id';
  public static readonly DATA_IDENTIFIER = 'project';

  readonly #projectService = inject(ProjectService);
  readonly #errorHandlerService = inject(ErrorHandlerService);
  readonly #router = inject(Router);

  public resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProjectWithLocationDto> {
    const originParams = route.params;
    const projectId =
      route.params[LocationTableViewResolver.PROJECT_IDENTIFIER_PARAM];

    if (this.isRequestWithProjectID(originParams)) {
      return this.#projectService.getProjectWithLocations(projectId).pipe(
        catchError(error => {
          this.#errorHandlerService.handleError(error);
          this.#router.navigate(['/', `${APP_ROUTES.HOME}`]);
          return EMPTY;
        })
      );
    } else {
      this.#router.navigate(['/', `${APP_ROUTES.HOME}`]);
      this.#errorHandlerService.handleError(new Error('Missing params'));

      return EMPTY;
    }
  }

  private isRequestWithProjectID(params: Params): boolean {
    return !!params[LocationTableViewResolver.PROJECT_IDENTIFIER_PARAM];
  }
}
