<div class="map-toolbar" data-testid="map-sidebar">
  <div class="map-toolbar-body">
    <mat-accordion>
      <mat-expansion-panel
        *ngIf="showSearchPanel"
        [expanded]="expandSearchPanel">
        <mat-expansion-panel-header>{{
          'COMMON.SEARCH' | translate
        }}</mat-expansion-panel-header>
        <app-search-card
          [freeToPlay]="freeToPlay"
          size="small"
          buttonText="COMMON.SEARCH"
          [lastSubmittedSearch$]="lastSubmittedSearch$"
          (submitButtonClicked)="onSubmitButtonClick()">
        </app-search-card>
      </mat-expansion-panel>

      @if (this.showSpotsFound) {
        <mat-expansion-panel [expanded]="!expandSearchPanel">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="!project; else projectView">
              <div class="panel-title">
                <span>{{ 'SIDEBAR.SPOTS_FOUND' | translate }} </span>
                <ng-container *ngTemplateOutlet="amountOfSpots"></ng-container>
              </div>
            </mat-panel-title>
            <ng-template #projectView>
              <mat-panel-title>
                <div class="panel-title">
                  {{ 'COMMON.PROJECT' | translate }}
                  <ng-container
                    *ngTemplateOutlet="amountOfSpots"></ng-container>
                </div>
              </mat-panel-title>
            </ng-template>
          </mat-expansion-panel-header>
          <ng-container *ngIf="project">
            <div class="mat-panel-sub-heading">
              <p class="mat-panel-sub-heading__title">{{ project.name }}</p>
              <app-link-button
                size="small"
                [link]="APP_ROUTES.TABLE_PROJECT + '/' + project.id"
                labelTranslationIdentifier="SIDEBAR.TABLE_VIEW">
              </app-link-button>
            </div>
          </ng-container>
          <app-spots-found
            [freeToPlay]="freeToPlay"
            [showRanking]="showSearchPanel"
            [spotsFoundOptions]="spotsFoundOptions"
            [selectedSpot]="selectedSpot"
            [showPrefixes]="!project"
            [showSuffixes]="!project"
            (spotsFoundSelectionChange)="emitSpotsFoundSelectionChange($event)"
            (spotOnHover)="emitSpotOnHover($event)" />
        </mat-expansion-panel>
      }

      <mat-expansion-panel *ngIf="showLayerPanel">
        <mat-expansion-panel-header data-testid="layerPanel">
          <mat-panel-title class="icon-panel-title">
            <mat-icon fontSet="material-symbols-outlined">Layers</mat-icon>
            <span class="panel-title"> {{ 'SIDEBAR.LAYERS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-layer-panel
          (layerStageChanged)="onLayerStateChanged($event)"
          (projectLayerStateChanged)="onProjectLayerChanged($event)"
          [layers]="layers"
          [projectSummaries]="projectSummaries()" />
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #amountOfSpots let-name="spotsFoundOptions">
  <ng-container *ngIf="spotsFoundOptions">
    <span class="panel-title__prefix" *ngIf="spotsFoundOptions.length > 0">
      ({{ spotsFoundOptions.length }})
    </span>
  </ng-container>
</ng-template>
