import { Pipe, PipeTransform } from '@angular/core';
import { MARKER_SIZE } from '@app/map/components/spot-marker/marker-size.enum';
import { Spot } from '@app/shared';

@Pipe({
  name: 'markerSize',
  standalone: true,
})
export class MarkerSizePipe implements PipeTransform {
  public transform(
    spot: Spot,
    selectedSpot?: Spot,
    spotsWithSizeBig?: Spot[]
  ): MARKER_SIZE {
    let output: MARKER_SIZE;

    if (
      this.#isSelectedSpot(spot, selectedSpot) ||
      this.#isSpotWithBigAsMarkerSize(spotsWithSizeBig, spot)
    ) {
      output = MARKER_SIZE.BIG;
    } else {
      output = MARKER_SIZE.NORMAL;
    }
    return output;
  }

  #isSelectedSpot(spot: Spot, selectedSpot?: Spot): boolean {
    return selectedSpot?.id === spot.id;
  }

  #isSpotWithBigAsMarkerSize(spotsWithSizeBig?: Spot[], spot?: Spot): boolean {
    if (spot && spotsWithSizeBig) {
      return spotsWithSizeBig
        ?.map(locationWithSizeBig => locationWithSizeBig?.id)
        .includes(spot.id);
    }
    return false;
  }
}
