import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { GeoserverLayerService, LayerTextFilter } from '@app/map';

@Component({
  selector: 'app-text-filter',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './text-filter.component.html',
  styleUrls: [],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TextFilterComponent implements OnInit {
  @Input() public filter!: LayerTextFilter;
  @Input() public filterForm!: FormGroup;
  readonly #destroyRef = inject(DestroyRef);
  readonly #geoserverLayerService = inject(GeoserverLayerService);

  public ngOnInit(): void {
    const control = new FormControl(this.filter.value);

    this.filterForm.addControl(
      `${this.filter.type}_${this.filter.key}`,
      control
    );

    control.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.#destroyRef))
      .subscribe(value => {
        this.#geoserverLayerService.updateFilter({
          ...this.filter,
          value: value as string,
        });
      });
  }
}
