import { Component, Input } from '@angular/core';
import { MARKER_COLOR } from '@app/shared';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  standalone: true,
  styleUrls: ['./cluster.component.scss'],
})
export class ClusterComponent {
  @Input() public count!: number;
  @Input() public color!: MARKER_COLOR;
  @Input() public sizeBig!: boolean;
}
