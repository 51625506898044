import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { SliderFilterComponent } from './components/slider-filter/slider-filter.component';
import { TextFilterComponent } from './components/text-filter/text-filter.component';
import { LAYER_FILTER_TYPE, LayerFilterType } from '@app/map';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    CommonModule,
    SliderFilterComponent,
    TextFilterComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class FiltersComponent implements OnInit {
  public FILTER_TYPE = LAYER_FILTER_TYPE;
  @Input() public parentForm!: FormGroup;
  public filterForm: FormGroup = new FormGroup({});
  @Input() public filters!: LayerFilterType[];

  public ngOnInit(): void {
    this.parentForm.addControl('filters', this.filterForm);
  }
}
