import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IsLoadingPipe } from '@app/map/components/spot-marker-group/is-loading.pipe';
import { MarkerColorPipe } from '@app/map/components/spot-marker-group/marker-color.pipe';
import { MarkerSizePipe } from '@app/map/components/spot-marker-group/marker-size.pipe';
import { SpotClickEvent, ThresholdColorConfigProvider } from '@app/map/util';
import { MARKER_COLOR, Spot } from '@app/shared';
import {
  GeoJSONSourceComponent,
  NgxMapLibreGLModule,
} from '@maplibre/ngx-maplibre-gl';
import * as turf from '@turf/turf';
import { Feature } from '@turf/turf';
import { GeoJsonProperties, Geometry } from 'geojson';
import { Map as MaplibreMap } from 'maplibre-gl';
import { Observable, Subject, of, throttleTime } from 'rxjs';
import { ClusterComponent } from '../cluster/cluster.component';
import { MarkerComponent } from '../marker/marker.component';
import { SpotMarkerComponent } from '../spot-marker/spot-marker.component';
import { MarkerClassPipe } from './marker-class.pipe';

interface ClusterLeavesCache {
  [clusterId: string]: Feature<Geometry, GeoJsonProperties>[];
}

@Component({
  selector: 'app-location-marker-group',
  templateUrl: './spot-marker-group.component.html',
  imports: [
    AsyncPipe,
    SpotMarkerComponent,
    NgxMapLibreGLModule,
    NgFor,
    MarkerSizePipe,
    IsLoadingPipe,
    MarkerColorPipe,
    MarkerClassPipe,
    NgClass,
    NgIf,
    MarkerComponent,
    ClusterComponent,
  ],
  standalone: true,
})
export class SpotMarkerGroupComponent implements OnInit {
  public readonly MARKER_COLOR = MARKER_COLOR;
  readonly #destroyRef = inject(DestroyRef);
  private mapChanges = new Subject<void>();
  public clusterLeavesCache: ClusterLeavesCache = {};
  private thresholdConfig = ThresholdColorConfigProvider.get();
  readonly spotsById: Map<string, Spot> = new Map();

  @ViewChild(GeoJSONSourceComponent) source!: GeoJSONSourceComponent;

  public mapFeatureCollection = signal<GeoJSON.FeatureCollection>(
    turf.featureCollection([])
  );

  constructor() {
    this.mapChanges
      .pipe(takeUntilDestroyed(this.#destroyRef), throttleTime(100))
      .subscribe(() => {
        this.analyzeClusters();
      });
    this.analyzeClusters();
  }

  ngOnInit() {
    if (this.map) {
      this.map.on('data', () => {
        this.mapChanges.next();
      });

      this.map.on('zoom', () => {
        this.mapChanges.next();
      });

      this.map.on('move', () => {
        this.mapChanges.next();
      });

      this.map.on('idle', () => {
        this.mapChanges.next();
      });
    }

    if (this.selectedSpot$) {
      this.selectedSpot$
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(spot => {
          this.map?.flyTo({
            center: [spot.longitude, spot.latitude],
            zoom: 16,
          });
        });
    }

    if (this.spotsWithSizeBig$) {
      this.spotsWithSizeBig$
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(spots => {
          this.spotsWithSizeBig = spots;
          this.analyzeClusters();
        });
    }
  }

  private _spots: Spot[] = [];
  @Input() set spots(spots: Spot[]) {
    this._spots = spots;
    this._spots.forEach(spot => this.spotsById.set(spot.id, spot));
    this.mapFeatureCollection.set(
      turf.featureCollection(
        spots.map(spot =>
          turf.point([spot.longitude, spot.latitude], {
            id: spot.id,
            index: spots.indexOf(spot),
          })
        )
      )
    );
  }

  public get spots(): Spot[] {
    return this._spots;
  }

  @Input()
  public selectedSpot?: Spot;

  @Input()
  public selectedSpot$?: Subject<Spot>;

  @Input()
  public spotsWithSizeBig?: Spot[];

  @Input()
  public spotsWithSizeBig$?: Subject<Spot[]>;

  @Input()
  public isLoading$: Observable<boolean> = of(false);

  @Input()
  public cluster?: boolean = false;

  @Input()
  public map: MaplibreMap | undefined;

  @Output()
  public clicked = new EventEmitter<SpotClickEvent>();

  private async analyzeClusters() {
    const clusters = this.map?.querySourceFeatures(this.source.id, {
      filter: ['has', 'point_count'],
    });
    if (!clusters) return;

    for (const cluster of clusters) {
      const clusterId = cluster.properties['cluster_id'];

      const leaves = await this.source.getClusterLeaves(clusterId, 10, 0);
      this.clusterLeavesCache[clusterId] = leaves;
    }
  }

  public onLocationMarkerClicked($event: Event, spot: Spot): void {
    this.clicked.emit(new SpotClickEvent($event, spot));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async selectCluster(event: MouseEvent, feature: any) {
    event.stopPropagation();

    if (!this.map) return;

    const clusterId = feature.properties.cluster_id;

    const zoom = await this.source.getClusterExpansionZoom(clusterId);

    this.map.flyTo({
      center: feature.geometry.coordinates,
      zoom: zoom,
    });

    // yes this duplication looks weird, but it's the only way to get the clusters to disappear after zooming in
    this.map.once('moveend', () => {
      this.map?.flyTo({
        center: feature.geometry.coordinates,
        zoom: zoom,
      });
    });
  }

  public getSpotById(id: string): Spot {
    return this.spots.find(spot => spot.id === id)!;
  }

  public getLowestIndexForCluster(clusterId: number): number {
    const leaves = this.clusterLeavesCache[clusterId];
    if (!leaves) return 9999;
    const lowestIndex = Math.min(
      ...leaves.map(
        (l: turf.helpers.Feature<unknown>) => l.properties?.['index']
      )
    );
    return lowestIndex;
  }

  public getColorForIndex(index: number): MARKER_COLOR {
    let locationColor: MARKER_COLOR = MARKER_COLOR.LIGHT_BLUE;

    if (index <= this.thresholdConfig.thresholdDarkblue) {
      locationColor = MARKER_COLOR.DARK_BLUE;
    } else if (
      index <= this.thresholdConfig.upperThresholdBlue &&
      index >= this.thresholdConfig.lowerThresholdBlue
    ) {
      locationColor = MARKER_COLOR.BLUE;
    }

    return locationColor;
  }

  public showClusterBig(clusterId: number) {
    if (!this.spotsWithSizeBig) return false;

    const leaves = this.clusterLeavesCache[clusterId];
    if (!leaves) return false;

    if (
      leaves.some((l: turf.helpers.Feature<unknown>) =>
        this.spotsWithSizeBig!.some(s => s.id === l.properties?.['id'])
      )
    ) {
      return true;
    }

    return false;
  }
}
