import { Pipe, PipeTransform } from '@angular/core';
import { LocationRecommendationDiscriminator, Spot } from '@app/shared';
import { ThresholdColorConfigProvider } from '@app/map/util';
import { RANKING_COLOR } from '@app/map/components/sidebar/components/spots-found';

@Pipe({
  name: 'rankingColor',
  standalone: true,
})
export class RankingColorPipe implements PipeTransform {
  private static readonly thresholdConfig = ThresholdColorConfigProvider.get();

  public transform(value: number, spot: Spot): RANKING_COLOR {
    let rankingColor: RANKING_COLOR = RANKING_COLOR.LIGHT_BLUE;
    if (LocationRecommendationDiscriminator.isLocation(spot)) {
      rankingColor = RANKING_COLOR.RED;
    } else {
      if (value <= RankingColorPipe.thresholdConfig.thresholdDarkblue) {
        rankingColor = RANKING_COLOR.DARK_BLUE;
      } else if (
        value <= RankingColorPipe.thresholdConfig.upperThresholdBlue &&
        value >= RankingColorPipe.thresholdConfig.lowerThresholdBlue
      ) {
        rankingColor = RANKING_COLOR.BLUE;
      }
    }

    return rankingColor;
  }
}
