import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Spot } from '@app/shared';

@Pipe({
  name: 'isLoading',
  standalone: true,
})
export class IsLoadingPipe implements PipeTransform {
  public transform(
    isLoading$: Observable<boolean>,
    spot: Spot,
    selectedSpot: Spot | undefined
  ): Observable<boolean> {
    return isLoading$.pipe(
      map(isLoading => spot.id === selectedSpot?.id && isLoading)
    );
  }
}
