import {
  LocationAddressDto,
  LocationDto,
  StationDto,
  Range,
} from '@app/shared';
import { isNil, round } from 'lodash';

export type ExportFormat = { header: string; value: string };
export class ColumnFormatters {
  public static formatScore(score: number): string {
    return round(score, 1).toString();
  }

  public static formatAddress(value: LocationAddressDto): string {
    let address: string | undefined = '';

    if (isNil(value) || (isNil(value.road) && isNil(value.city))) {
      address = '/';
    } else if (isNil(value.road)) {
      address = value.city;
    } else if (isNil(value.city)) {
      address = value.road;
    } else {
      address = `${value.road}, ${value.city}`;
    }

    return address || '';
  }

  public static formatChargingStationConfiguration(
    value: LocationDto
  ): ExportFormat[] {
    return value.stations?.map((station: StationDto, index) => {
      return {
        header: `station-${index + 1} charging-power (kW)`,
        value: station.chargingPower.toString(),
      };
    });
  }

  public static formatRangeExport(
    value: Range,
    header?: string
  ): ExportFormat[] {
    if (isNil(header)) {
      throw new Error('Range needs a header');
    }
    return [
      {
        header: `${header} min`,
        value: value.min.toString(),
      },
      {
        header: `${header} max`,
        value: value.max.toString(),
      },
    ];
  }

  public static roundNumber(value: number): string {
    return round(value, 7).toString();
  }
}
