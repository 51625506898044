import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { SpotMarkerComponent } from '@app/map/components/spot-marker/spot-marker.component';
import {
  GeoJSONSourceComponent,
  NgxMapLibreGLModule,
} from '@maplibre/ngx-maplibre-gl';
import { MarkerSizePipe } from '@app/map/components/spot-marker-group/marker-size.pipe';
import { IsLoadingPipe } from '@app/map/components/spot-marker-group/is-loading.pipe';
import { MarkerColorPipe } from '@app/map/components/spot-marker-group/marker-color.pipe';
import { MarkerClassPipe } from '@app/map/components/spot-marker-group/marker-class.pipe';
import { MarkerComponent } from '@app/map/components/marker/marker.component';
import { ClusterComponent } from '@app/map/components/cluster/cluster.component';
import {
  LngLatBounds,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  Map as MaplibreMap,
  Popup,
} from 'maplibre-gl';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, EMPTY, switchMap, throttleTime } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as turf from '@turf/turf';
import { FeatureCollection, Point } from '@turf/turf';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@app/core';

@Component({
  selector: 'app-poi-group',
  templateUrl: './poi-group.component.html',
  imports: [
    AsyncPipe,
    SpotMarkerComponent,
    NgxMapLibreGLModule,
    NgFor,
    MarkerSizePipe,
    IsLoadingPipe,
    MarkerColorPipe,
    MarkerClassPipe,
    NgClass,
    NgIf,
    MarkerComponent,
    ClusterComponent,
    NgOptimizedImage,
    TranslateModule,
  ],
  standalone: true,
  styleUrls: ['./poi-group.component.scss'],
})
export class PoiGroupComponent implements OnInit {
  @ViewChild(GeoJSONSourceComponent) source!: GeoJSONSourceComponent;
  @Input() public map: MaplibreMap | undefined;
  @Input() clusterBounds!: BehaviorSubject<LngLatBounds | undefined>;
  @Input({ required: true }) dataSource!: string;
  @Input() cluster?: boolean = true;
  @Input() color?: string = '#809c46';
  @Input() icon?: string = '';
  @Input({ required: true }) id!: string;
  public features = signal<GeoJSON.FeatureCollection>(
    turf.featureCollection([])
  );
  public popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  });
  public maxZoom = 10;
  readonly #destroyRef = inject(DestroyRef);
  readonly #httpClient = inject(HttpClient);
  readonly #translateService = inject(TranslateService);

  ngOnInit() {
    this.clusterBounds
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        throttleTime(1000, undefined, { leading: true, trailing: true }),
        switchMap(bounds => {
          if (this.map && this.map?.getZoom() > this.maxZoom) {
            const swMercator = turf.toMercator(
              turf.point([bounds!._sw.lng, bounds!._sw.lat])
            );
            const neMercator = turf.toMercator(
              turf.point([bounds!._ne.lng, bounds!._ne.lat])
            );
            const boundsString = `${swMercator.geometry.coordinates[0]},${swMercator.geometry.coordinates[1]},${neMercator.geometry.coordinates[0]},${neMercator.geometry.coordinates[1]}`;

            return this.#httpClient.get<FeatureCollection>(
              this.dataSource.replace('{bbox-epsg-3857}', boundsString)
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(data => {
        if (data) {
          const features = data.features;
          this.features.set(
            turf.featureCollection(
              features.map(feature => {
                return turf.toWgs84(
                  turf.point(
                    [
                      (feature.geometry as Point).coordinates[0],
                      (feature.geometry as Point).coordinates[1],
                    ],
                    {
                      ...feature.properties,
                      id: feature.id,
                      index: features.indexOf(feature),
                    }
                  )
                );
              })
            )
          );
        }
      });

    this.map!.on('zoom', () => {
      this.popup.remove();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async selectCluster($event: MapLayerMouseEvent) {
    $event.originalEvent.stopPropagation();
    $event.originalEvent.preventDefault();
    $event.originalEvent.stopImmediatePropagation();

    if (!$event.features) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const feature = $event.features![0] as any;
    if (!this.map) return;

    const clusterId = feature.properties.cluster_id;

    const zoom = await this.source.getClusterExpansionZoom(clusterId);

    this.map.flyTo({
      center: feature.geometry.coordinates,
      zoom: zoom,
    });
  }

  public hoverPoint($event: MapLayerMouseEvent) {
    if (!$event.features) return;

    const feature = $event.features![0];
    const translation: string | undefined = this.#getTranslation(feature);
    this.popup
      .setLngLat([
        (feature.geometry as Point).coordinates[0],
        (feature.geometry as Point).coordinates[1],
      ])
      .setHTML(this.#createPopupHTML(feature, translation))
      .addTo(this.map!);
  }

  #getTranslation(feature: MapGeoJSONFeature): string | undefined {
    const subclass = feature.properties?.['subclass'];
    return subclass
      ? this.#translateService.instant(
          `${CustomTranslateLoader.JSON_PREFIX_POI_CATEGORIES}.${subclass.toUpperCase()}`
        )
      : undefined;
  }

  #createPopupHTML(feature: MapGeoJSONFeature, subclassTranslation?: string) {
    return `
    <b>${
      feature.properties?.['name'] || feature.properties?.['operator'] || ''
    }</b></br>
    <span>${feature.properties?.['street'] || ''}</span>
    <span> ${subclassTranslation || ''}</span>
  `;
  }

  public hoverOut() {
    this.popup.remove();
  }
}
