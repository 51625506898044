import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpotsListElementPrefixComponent } from '@app/map/components/sidebar/components/spots-found/components/spots-list-element-prefix/spots-list-element-prefix.component';
import { SpotsListElementSuffixComponent } from '@app/map/components/sidebar/components/spots-found/components/spots-list-element-suffix/spots-list-element-suffix.component';
import { RANKING_COLOR } from '@app/map/components/sidebar/components/spots-found';

@Component({
  selector: 'app-spots-list-element',
  standalone: true,
  imports: [
    SpotsListElementPrefixComponent,
    MatTooltipModule,
    NgIf,
    SpotsListElementSuffixComponent,
  ],
  templateUrl: './spots-list-element.component.html',
  styleUrls: ['./spots-list-element.component.scss'],
})
export class SpotsListElementComponent implements AfterViewInit {
  @Input() public showPrefix?: boolean = true;
  @Input() public prefixColor?: RANKING_COLOR;
  @Input() public showSuffix?: boolean = true;
  @Input() public suffixText?: string;
  @Input() public shortSuffix?: boolean = false;

  @ViewChild('elementContent', { static: true }) elementContent!: ElementRef;
  readonly #changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  public disableTooltip = signal(true);
  public tooltip: string = '';

  public ngAfterViewInit(): void {
    if (this.#isTextOverflowed(this.elementContent.nativeElement)) {
      this.tooltip = this.elementContent.nativeElement.textContent;
      this.disableTooltip.set(false);
      this.#changeDetectorRef.detectChanges();
    }
  }

  #isTextOverflowed(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }
}
