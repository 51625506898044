import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorHandlerService, ResponsibilityService } from '@app/core';
import { NetworkOperatorInfoComponent } from '@app/map/components/spot-information/components/network-operator-info/network-operator-info.component';
import { NetworkOperatorDto, Spot } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-network-operator-tab',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NetworkOperatorInfoComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './network-operator-tab.component.html',
  styleUrls: ['./network-operator-tab.component.scss'],
})
export class GridOperatorTabComponent implements OnChanges {
  public isLoading = false;

  @Input()
  public spot!: Spot;

  public networkOperators?: NetworkOperatorDto[] = [];

  readonly #responsibilityService = inject(ResponsibilityService);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['spot']) {
      if (this.spot.address?.city || this.spot.address?.postcode) {
        this.isLoading = true;
        this.#responsibilityService
          .loadNetworkOperatorInfo(this.spot)
          .subscribe({
            next: result => {
              this.networkOperators = result;
              this.isLoading = false;
            },
            error: error => {
              if (error.status !== 404) {
                this.#errorHandlerService.handleCustomError(
                  'MAP.LOCATION_INFORMATION.NETWORK_OPERATOR_FETCH_ERROR'
                );
              }

              this.networkOperators = [];
              this.isLoading = false;
            },
          });
      }
    }
  }
}
