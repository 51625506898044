import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NetworkOperatorDto } from '@app/shared';

@Component({
  selector: 'app-network-operator-info',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './network-operator-info.component.html',
  styleUrls: ['./network-operator-info.component.scss'],
})
export class NetworkOperatorInfoComponent {
  @Input() public networkOperator?: NetworkOperatorDto;
}
