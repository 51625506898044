/* List of Layer ID's used by maplibre */
export enum LAYER_ID {
  SATELLITE = 'satellite',
  NETWORK_OPERATORS_FILL = 'network-operators-fill',
  NETWORK_OPERATORS_OUTLINE = 'network-operators-outline',
  CHARGING_STATIONS = 'charging_stations',
  /* GRID */
  GRID = 'grid',
  POWER_CABLE = 'power_cable',
  POWER_LINE = 'power_line',
  POWER_GENERATOR = 'power_generator',
  POWER_TRANSFORMER = 'power_transformer',
  POWER_POLE = 'power_pole',
  /* LANDUSE */
  LANDUSE_PARKING = 'parking',
  GEOSERVER_LANDUSE_COMMERCIAL = 'geoserver_landuse_commercial',
  GEOSERVER_LANDUSE_INDUSTRIAL = 'geoserver_landuse_industrial',
  GEOSERVER_LANDUSE_RESIDENTIAL = 'geoserver_landuse_residential',
  POPULATION_DENSITY = 'population_density',
  /* POI */
  SHOP = 'shop',
  SUSTENANCE = 'sustenance',
}
