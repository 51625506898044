export enum LAYER_NAME {
  /* MapTiler Layers */
  BASEMAP = 'basemap',
  SATELLITE = 'satellite',

  /* SpotOne Geoserver Layers */
  NETWORK_OPERATORS = 'network_operators',
  CHARGING_STATIONS = 'charging_stations',
  GRID = 'grid',
  POWER_CABLE = 'power_cable',
  POWER_LINE = 'power_line',
  POWER_GENERATOR = 'power_generator',
  POWER_TRANSFORMER = 'power_transformer',
  POWER_POLE = 'power_pole',
  PARKING = 'parking',
  LANDUSE_COMMERCIAL = 'landuse_commercial',
  LANDUSE_INDUSTRIAL = 'landuse_industrial',
  LANDUSE_RESIDENTIAL = 'landuse_residential',
  POPULATION_DENSITY = 'population_density',
  SHOPS = 'shops',
  SUSTENANCE = 'sustenance',

  /* Custom Layers */
  EMPTY = 'empty',
  AREA_RESTRICTION = 'area_restriction',
  REGIONS_RESTRICTION = 'regions_restriction',
  SEARCH_RADIUS = 'search_radius',
}
