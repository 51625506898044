<div
  [ngClass]="{
    'spot-information--fixed-width': !isFullWidth,
    'spot-information--full-width': isFullWidth
  }"
  class="spot-information mat-typography spot-information--full-width">
  <mat-progress-bar
    [ngClass]="{ 'loading-bar-hidden': !isLoading() }"
    mode="indeterminate"
    [color]="(spot | isLocation) ? 'primary' : 'accent'">
  </mat-progress-bar>

  <div class="spot-information__body">
    <mat-tab-group
      [ngClass]="{
        'spot-information__tab': (spot | isLocation)
      }"
      [color]="(spot | isLocation) ? 'primary' : 'accent'">
      <mat-tab
        labelClass="spot-information__header--capitalized"
        [label]="'MAP.LOCATION_INFORMATION.SPOT' | translate">
        <ng-template matTabContent>
          <div class="tab-container">
            <app-tab-header
              [road]="road"
              [hideSaveButton]="freeToPlay"
              [spot]="spot"
              [isLoading]="isLoading()"
              (saveClicked)="saveLocation()">
            </app-tab-header>
            <app-spot-tab
              [spot]="spot"
              [attributeToolTipPosition]="tabToolTipPosition"
              [showNewValueIndicator]="showNewValueIndicator">
            </app-spot-tab>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab labelClass="spot-information__header--capitalized">
        <ng-template mat-tab-label>
          <span
            [ngClass]="{ 'mdc-tab-new-value-indicator': showNewValueIndicator }"
            >{{ 'MAP.LOCATION_INFORMATION.USAGE' | translate }}
          </span>
        </ng-template>
        <ng-template matTabContent>
          <div class="tab-container">
            <app-usage-score-tab
              [spot]="spot"
              [attributeToolTipPosition]="tabToolTipPosition" />
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab
        labelClass="spot-information__header--capitalized"
        [label]="'MAP.LOCATION_INFORMATION.GRID_COST' | translate">
        <ng-template matTabContent>
          <div class="tab-container">
            <app-grid-score-tab [spot]="spot" />
          </div>
        </ng-template>
      </mat-tab>
      @if (!freeToPlay) {
        <mat-tab
          *ngIf="featureConfig.networkOperator"
          labelClass="spot-information__header--capitalized network-operator-tab"
          [label]="'COMMON.NETWORK_OPERATOR' | translate">
          <ng-template matTabContent>
            <div class="tab-container">
              <app-tab-header
                [road]="road"
                [spot]="spot"
                [isLoading]="isLoading()"
                [hideSaveButton]="freeToPlay"
                (saveClicked)="saveLocation()">
              </app-tab-header>
              <app-network-operator-tab [spot]="spot" />
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
    @if (!freeToPlay) {
      <div
        class="spot-information__menu-btn"
        *ngIf="(spot | isLocation) && featureConfig.crudOperations">
        <button
          [matMenuTriggerFor]="menu"
          mat-icon-button
          data-testid="spot-information-menu-btn">
          <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            (click)="onMenuClick(menu.type)"
            mat-menu-item
            *ngFor="let menu of menuOptions"
            [attr.data-testid]="menu.dataTestId">
            <mat-icon fontSet="material-symbols-outlined">
              {{ menu.icon }}
            </mat-icon>
            {{ menu.labelTranslationIdentifier | translate }}
          </button>
        </mat-menu>
      </div>
    }
  </div>
</div>
