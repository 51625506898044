import { Pipe, PipeTransform } from '@angular/core';
import { MARKER_COLOR } from '@app/shared';

@Pipe({
  name: 'markerClass',
  standalone: true,
})
export class MarkerClassPipe implements PipeTransform {
  public transform(markerColor: MARKER_COLOR): string {
    switch (markerColor) {
      case MARKER_COLOR.DARK_BLUE:
      case MARKER_COLOR.RED:
        return 'priority-3';
      case MARKER_COLOR.BLUE:
        return 'priority-2';
      default:
        return '';
    }
  }
}
