import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RestrictionService } from '@app/core';
import { LAYER_NAME } from '@app/map/enums';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

@Component({
  selector: 'app-radius-restriction-layer',
  standalone: true,
  imports: [CommonModule, NgxMapLibreGLModule],
  templateUrl: './radius-restriction-layer.component.html',
  styleUrls: [],
})
export class RadiusRestrictionLayerComponent {
  public readonly LAYER_NAME = LAYER_NAME;
  readonly #restrictionService = inject(RestrictionService);
  areaRestrictions = this.#restrictionService.radiusRestrictions;
}
