import { EChartsOption, SeriesOption } from 'echarts';
import { ChartConfigProvider } from '@app/map';

interface ChartCategory {
  name: string;
  value: number;
  color: string;
}

export class CategoryChartConfigProvider {
  public static generateConfig(categories: ChartCategory[]): EChartsOption {
    return {
      tooltip: {
        show: false,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      animationDuration: ChartConfigProvider.DEFAULT_ANIMATION_IN_MS,
      textStyle: ChartConfigProvider.TEXT_STYLE,
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      series: categories.map(category =>
        CategoryChartConfigProvider.generateSeries(category)
      ),
    };
  }

  private static generateSeries(category: ChartCategory): SeriesOption {
    return {
      silent: true,
      name: category.name,
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: 'white',
        formatter: (): string => {
          return `${category.name}: ${category.value}`;
        },
      },
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: category.color,
      },
      data: [1],
    };
  }
}
