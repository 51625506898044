<div class="table-container">
  <div class="table-container__header">
    <h1 id="tableDescription" class="table-container__title">
      {{ 'COMMON.PROJECT' | translate }} {{ projectWithLocations?.name }}
    </h1>
    <app-link-button
      [link]="APP_ROUTES.MAP_PROJECT + '/' + projectWithLocations?.id"
      labelTranslationIdentifier="SIDEBAR.MAP_VIEW" />
  </div>
  <div class="table-container__body" *ngIf="projectWithLocations">
    <app-table-toolbar
      [checkboxes]="displayedColumns$"
      [appliedConditions]="appliedConditions"
      (checkboxChanged)="onCheckBoxChange($event)"
      (applicableConditionsChanged)="onApplicableConditionsChange($event)"
      (exportBtnClicked)="onExportBtnClick($event)">
    </app-table-toolbar>
    <div class="table">
      <app-location-table
        [columnMetaData]="displayedColumns$"
        [locationRows]="locations"
        [exportBehavior]="exportBehavior"
        (deleteLocationClicked)="onDeleteLocationClick($event)"
        (stationChanged)="onStationChange($event)">
      </app-location-table>
    </div>
  </div>
</div>
