import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ApplicableCondition } from '@app/location-table-page/applicable-condtion';
import {
  CreateFilterDialogDataClose,
  CreateFiltersDialogComponent,
} from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/create-filters-dialog.component';
import { ShowColumnsSelectorComponent } from '@app/location-table-page/components/table-toolbar/components/show-columns-selector/show-columns-selector.component';
import { ConditionProvider } from '@app/location-table-page/condition-provider';
import { ColumnMetaData } from '@app/location-table-page/location-table-page.component';
import { DialogConfigFactory } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApplicableConditionExaminer,
  ApplicableConditionMapper,
} from '@app/location-table-page/util';
import { FilterFormValue } from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components';

@Component({
  selector: 'app-table-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TranslateModule,
    MatInputModule,
    MatDialogModule,
    ShowColumnsSelectorComponent,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
  ],
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent {
  @Input() public checkboxes!: ColumnMetaData[];
  @Input() public appliedConditions: ApplicableCondition[] = [];
  @Output() public checkboxChanged = new EventEmitter<ColumnMetaData[]>();
  @Output() public applicableConditionsChanged = new EventEmitter<
    ApplicableCondition[]
  >();
  @Output() public exportBtnClicked = new EventEmitter<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);
  readonly #dialog = inject(MatDialog);

  public onCheckboxChange($event: ColumnMetaData[]): void {
    this.checkboxChanged.emit($event);
  }

  public openCreateFiltersDialog(): void {
    const config = DialogConfigFactory.INSTANCE.buildDialogConfig('large');
    const dialogRef = this.#dialog.open(CreateFiltersDialogComponent, {
      ...config,
      data: {
        columMetaDate: this.checkboxes,
        conditions: ConditionProvider.INSTANCE.getConditionsMeta(),
        appliedConditions: this.appliedConditions,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((dialogData: CreateFilterDialogDataClose) => {
        const applicableConditions: ApplicableCondition[] | undefined =
          dialogData?.conditions
            .map((value: FilterFormValue) => {
              return ApplicableConditionMapper.mapFilterFormValue(value);
            })
            .filter(condition =>
              ApplicableConditionExaminer.isValid(condition)
            );

        if (applicableConditions) {
          this.applicableConditionsChanged.emit(applicableConditions);
        }
      });
  }

  public removeCondition(conditionIndex: number): void {
    this.appliedConditions.splice(conditionIndex, 1);
    this.applicableConditionsChanged.emit(this.appliedConditions);
  }

  public onExportBtnClick(): void {
    this.exportBtnClicked.emit(true);
  }
}
