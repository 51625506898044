import { Pipe, PipeTransform } from '@angular/core';
import { Interval } from '@app/shared';

@Pipe({
  name: 'roundInterval',
  standalone: true,
})
export class RoundIntervalPipe implements PipeTransform {
  public transform(interval: Interval, precision: number = 1): Interval {
    const roundValue = (value: number): number => {
      const factor = Math.pow(10, precision);

      return Math.round(value * factor) / factor;
    };

    return {
      minBoundary: roundValue(interval.minBoundary),
      maxBoundary: roundValue(interval.maxBoundary),
      min: roundValue(interval.min),
      avg: roundValue(interval.avg),
      max: roundValue(interval.max),
    };
  }
}
