import { LAYER_FILTER_TYPE, LAYER_NAME, LayerFilterType } from '@app/map';

export class MapLayerFilterProvider {
  public static getFilters(): LayerFilterType[] {
    return [
      {
        layer: LAYER_NAME.CHARGING_STATIONS,
        type: LAYER_FILTER_TYPE.SLIDER,
        labelTranslationIdentifier:
          'MAP.LAYERS.CHARGING_STATIONS.FILTERS.POWER',
        key: 'max_kw',
        min: 0,
        max: 500,
        step: 1,
      },
    ];
  }
}
