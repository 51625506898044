import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsLocationPipe, Spot } from '@app/shared';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tab-header',
  standalone: true,
  imports: [CommonModule, IsLocationPipe, MatButtonModule, TranslateModule],
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
})
export class TabHeaderComponent {
  @Input() public hideSaveButton = false;
  @Input() public spot!: Spot;
  @Input() public road?: string;
  @Input() public isLoading?: boolean | null;
  @Output() public saveClicked = new EventEmitter<never>();

  public onSaveClick(): void {
    this.saveClicked.emit();
  }
}
