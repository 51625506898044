import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyImpurePipe, Range } from '@app/shared';
import { ColumnMetaData } from '@app/location-table-page';
import { CurrencyFormatter } from '@app/map/components/spot-information/util';

@Pipe({
  name: 'row',
  standalone: true,
  pure: false, // required to be triggered by changeDetection on language change
})
export class RowPipe implements PipeTransform {
  constructor(private currencyImpure: CurrencyImpurePipe) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(value: any, columMeta: ColumnMetaData): string {
    if (columMeta.formatter) {
      return columMeta.formatter(value);
    }

    if (columMeta.isRange && columMeta.isCurrency) {
      const range = value as Range;
      const minValue = CurrencyFormatter.formatCurrency(range.min, true);
      const maxValue = CurrencyFormatter.formatCurrency(range.max, true);

      return `${minValue} - ${maxValue}`;
    } else if (columMeta.isRange) {
      const range = value as Range;
      if (columMeta.roundingPoints) {
        return `${range.min.toFixed(columMeta.roundingPoints)} - ${range.max.toFixed(columMeta.roundingPoints)}`;
      }
      return `${range.min} - ${range.min}`;
    }
    return value;
  }
}
