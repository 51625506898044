import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ExportMapper } from '@app/location-table-page/components/location-table/export-mapper';
import { Exporter } from '@app/location-table-page/components/location-table/exporter';
import {
  SpotInformationDialogComponent,
  SpotInformationDialogOutputData,
} from '@app/location-table-page/components/spot-information-dialog/spot-information-dialog.component';
import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import {
  ColumnMetaData,
  ExportBehavior,
} from '@app/location-table-page/location-table-page.component';
import { DialogConfigFactory, LocationDto } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { RowPipe } from '@app/location-table-page/util';
import { LocationService } from '@app/core';
import { StationsRowComponent } from './components/stations-row/stations-row.component';
import { LoactionTableInput } from './location-table-input';
import { MultiplyIntervalPipe } from '@app/map/components/spot-information/pipes';

@Component({
  selector: 'app-location-table',
  standalone: true,
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.scss'],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    TranslateModule,
    RowPipe,
    MatButtonModule,
    MatPaginatorModule,
    StationsRowComponent,
    MatChipsModule,
  ],
  providers: [MultiplyIntervalPipe],
})
export class LocationTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) public sort!: MatSort;

  @Output() public deleteLocationClicked =
    new EventEmitter<SpotInformationDialogOutputData>();

  @Output() public stationChanged = new EventEmitter<boolean>(false);

  @Input()
  public set columnMetaData(columnMetaData: ColumnMetaData[]) {
    this._columnMetaData = columnMetaData;
    if (columnMetaData) {
      this.matHeaderRowDef = columnMetaData
        .filter(colum => colum.isDisplayed)
        .map(colum => colum.columnIdentifier);
    }
  }

  private multiplyIntervalPipe = inject(MultiplyIntervalPipe);

  public locationRows = input([], {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform: (value: any) =>
      value.map((location: LocationDto) => this.enrichLocation(location)),
  });

  @Input()
  public set exportBehavior(exportBehavior: ExportBehavior) {
    if (exportBehavior.shouldExportBeExecuted) {
      this.executeExport(exportBehavior);
    }
  }

  public COLUMN_IDENTIFIER = COLUMN_IDENTIFIER;
  public _columnMetaData?: ColumnMetaData[];

  public dialog = inject(MatDialog);
  public router = inject(Router);
  public locationService = inject(LocationService);
  public matHeaderRowDef: COLUMN_IDENTIFIER[] = [];
  public dataSource?: MatTableDataSource<LoactionTableInput>;

  readonly #exporter = new Exporter();

  constructor() {
    effect(() => {
      if (this.dataSource?.data && this.locationRows()) {
        this.dataSource.data = this.locationRows()!;
      }
    });
  }
  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource([] as LoactionTableInput[]);
  }

  public ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  private enrichLocation(location: LocationDto): LoactionTableInput {
    return {
      ...location,
      [COLUMN_IDENTIFIER.SESSIONS_YEAR_ESTIMATION]: {
        ...this.multiplyIntervalPipe.transform(
          location.sessionsDayEstimation,
          365
        ),
      },
      [COLUMN_IDENTIFIER.CONSUMPTION_YEAR_ESTIMATION]: {
        ...this.multiplyIntervalPipe.transform(
          location.consumptionDayEstimation,
          365
        ),
      },
    };
  }
  public onRowClick(row: COLUMN_IDENTIFIER): void {
    const config = DialogConfigFactory.INSTANCE.buildDialogConfig('large');

    const dialogRef = this.dialog.open(SpotInformationDialogComponent, {
      ...config,
      data: {
        spot: row,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((data: SpotInformationDialogOutputData) => {
        if (data && data.shouldBeDeleted) {
          this.deleteLocationClicked.emit(data);
        }
        if (data && data.hasStationChanged) {
          this.stationChanged.emit(data.hasStationChanged);
        }
      });
  }

  private executeExport(exportBehavior: ExportBehavior) {
    if (this.dataSource?.data) {
      const exporterInput: Array<unknown> = ExportMapper.map(
        this.dataSource.data,
        this.matHeaderRowDef
      );
      const csv = this.#exporter.execute('csv', exporterInput);
      const blob = new Blob([csv], { type: 'text/csv' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.style.display = 'none';

      link.download = exportBehavior.filename
        ? exportBehavior.filename
        : 'export';
      link.click();
      link.remove();
    } else {
      throw new Error('No datasource available');
    }
  }
}
