import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import {
  KWhFormatPipe,
  Spot,
  TooltipPipe,
  TooltipShowDelayPipe,
} from '@app/shared';
import { TOOLTIP } from '@app/shared/util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AttributeTitleComponent } from '../attribute-title/attribute-title.component';
import { AttributeComponent } from '../attribute/attribute.component';
import { CategoryChartComponent } from '../category-chart/category-chart.component';
import { MessageComponent } from '../message/message.component';
import { RANGE_LABEL_FORMAT } from '../range-chart/range-chart-config-provider';
import { RangeChartComponent } from '../range-chart/range-chart.component';
import { ScoreChartComponent } from '../score-chart/score-chart.component';
import { ScoreExplanationComponent } from '../score-explanation/score-explanation.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MultiplyIntervalPipe, RoundIntervalPipe } from '../../pipes';
import { ConsumptionForecastChartComponent } from '../consumption-forecast-chart/consumption-forecast-chart.component';

@Component({
  selector: 'app-usage-score-tab',
  standalone: true,
  imports: [
    CommonModule,
    ScoreExplanationComponent,
    AttributeTitleComponent,
    AttributeComponent,
    RangeChartComponent,
    MultiplyIntervalPipe,
    RoundIntervalPipe,
    MessageComponent,
    CategoryChartComponent,
    ScoreChartComponent,
    TranslateModule,
    MatTooltipModule,
    TooltipPipe,
    TooltipShowDelayPipe,
    ConsumptionForecastChartComponent,
    KWhFormatPipe,
    MatExpansionModule,
    DecimalPipe,
  ],
  templateUrl: './usage-score-tab.component.html',
  styleUrls: ['./usage-score-tab.component.scss'],
})
export class UsageScoreTabComponent {
  panelOpenState = false;

  translateService = inject(TranslateService);

  @Input()
  public spot!: Spot;

  @Input()
  public attributeToolTipPosition: TooltipPosition = 'left';

  public readonly RANGE_LABEL_FORMAT = RANGE_LABEL_FORMAT;
  public readonly TT = TOOLTIP;
}
