import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-maplibre-control-btn',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './maplibre-control-btn.component.html',
  styleUrls: ['./maplibre-control-btn.component.scss'],
})
export class MaplibreControlBtnComponent {
  @Input() public icon!: string;
  @Output() public clicked = new EventEmitter<string>();

  public click(): void {
    this.clicked.emit('clicked');
  }
}
