/* These should match the geoserver layer name */

export enum SOURCE_LAYER {
  DISTRIBUTION_SERVICE_OPERATORS = 'distribution_service_operators',
  CHARGING_STATIONS = 'charging_stations',
  POINTS_OF_INTEREST = 'points_of_interest',
  NETWORK = 'network',
  POPULATION_DENSITY = 'population_density_h3',
  OSM_POWER_CABLE = 'osm_power_cable',
  OSM_POWER_LINE = 'osm_power_line',
  OSM_POWER_GENERATOR = 'osm_power_generator',
  OSM_POWER_TRANSFORMER = 'osm_power_transformer',
  OSM_POWER_POLE = 'osm_power_pole',
  OSM_PARKING = 'osm_parking',
  OSM_LANDUSE_COMMERCIAL = 'osm_landuse_commercial',
  OSM_LANDUSE_INDUSTRIAL = 'osm_landuse_industrial',
  OSM_LANDUSE_RESIDENTIAL = 'osm_landuse_residential',
  SHOP = 'osm_poi_shop',
  SUSTENANCE = 'osm_poi_sustenance',
}
