import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LocationService, ProjectService } from '@app/core';
import { ApplicableCondition } from '@app/location-table-page/applicable-condtion';
import { ColumnProvider } from '@app/location-table-page/column-provider';
import { LocationTableComponent } from '@app/location-table-page/components/location-table/location-table.component';
import { SpotInformationDialogOutputData } from '@app/location-table-page/components/spot-information-dialog/spot-information-dialog.component';
import { TableToolbarComponent } from '@app/location-table-page/components/table-toolbar/table-toolbar.component';
import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import { LocationRowService } from '@app/location-table-page/location-row.service';
import { LocationTableViewResolver } from '@app/location-table-page/location-table-view.resolver';
import {
  APP_ROUTES,
  LinkButtonComponent,
  LocationDto,
  ProjectWithLocationDto,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, switchMap } from 'rxjs';

export type ColumnMetaData = {
  columnIdentifier: COLUMN_IDENTIFIER;
  labelTranslationIdentifier: string;
  isDisplayed: boolean;
  isFilterable: boolean;
  isSortable?: boolean;
  isRange?: boolean;
  roundingPoints?: number;
  isCurrency?: boolean;
  minValue?: number;
  maxValue?: number;
  formatter?(value: unknown): string;
  formatForExport?(value: unknown, header?: string): unknown;
};

export type ExportBehavior = {
  shouldExportBeExecuted: boolean;
  filename?: string;
};

@Component({
  selector: 'app-location-table-page',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    LinkButtonComponent,
    LocationTableComponent,
    TranslateModule,
    TableToolbarComponent,
  ],
  templateUrl: './location-table-page.component.html',
  styleUrls: ['./location-table-page.component.scss'],
})
export class LocationTablePageComponent {
  public readonly APP_ROUTES = APP_ROUTES;

  readonly #displayColumnsByIdentifier =
    ColumnProvider.INSTANCE.displayColumnsByIdentifier;

  public projectWithLocations?: ProjectWithLocationDto;
  public displayedColumns$ = Array.from(
    this.#displayColumnsByIdentifier.values()
  );

  public locations: LocationDto[] = [];
  public appliedConditions: ApplicableCondition[] = [];

  public exportBehavior: ExportBehavior = { shouldExportBeExecuted: false };

  readonly #route = inject(ActivatedRoute);
  readonly #locationRowService = inject(LocationRowService);
  readonly #projectService = inject(ProjectService);
  readonly #locationService = inject(LocationService);

  constructor() {
    this.#route.data.pipe().subscribe(data => {
      this.projectWithLocations =
        data[LocationTableViewResolver.DATA_IDENTIFIER];
      this.locations = this.projectWithLocations?.locations
        ? this.projectWithLocations?.locations
        : [];
    });
    this.#locationRowService.originLocationRows = this.locations;
  }

  public onCheckBoxChange($event: ColumnMetaData[]): void {
    this.displayedColumns$ = $event;
  }

  public onApplicableConditionsChange($event?: ApplicableCondition[]): void {
    if ($event && $event.length > 0) {
      if (this.projectWithLocations?.locations) {
        this.locations = this.#locationRowService.filteredLocationRows;
      }
      this.appliedConditions = $event;
    } else {
      this.locations = this.#locationRowService.originLocationRows;
    }
  }

  public onDeleteLocationClick($event: SpotInformationDialogOutputData): void {
    this.#locationService
      .deleteLocation($event.spot.id)
      .pipe(
        switchMap(() => {
          if (this.projectWithLocations?.id) {
            return this.#projectService.getProjectWithLocations(
              this.projectWithLocations.id
            );
          }
          return EMPTY;
        })
      )
      .subscribe((projectWithLocations: ProjectWithLocationDto) => {
        this.locations = projectWithLocations.locations
          ? projectWithLocations.locations
          : [];
      });
  }

  public onStationChange($event: boolean): void {
    if (this.projectWithLocations?.id && $event) {
      this.#projectService
        .getProjectWithLocations(this.projectWithLocations.id)
        .subscribe(projectWithLocations => {
          this.locations = projectWithLocations.locations
            ? projectWithLocations.locations
            : [];
        });
    }
  }

  public onExportBtnClick($event: boolean): void {
    this.exportBehavior = {
      shouldExportBeExecuted: $event,
      filename: this.projectWithLocations?.name,
    };
  }
}
