@for (location of locations; track location.id) {
  <mgl-marker
    #locationMarker
    [lngLat]="[location.longitude, location.latitude]">
    <app-spot-marker
      (mouseenter)="onMouseEnter($event, location)"
      (mouseleave)="onMouseLeave()"
      [spot]="location"
      [markerColor]="MARKER_COLOR.GRAY"
      (clicked)="onLocationMarkerClicked($event, location)" />
  </mgl-marker>
}
