import { EChartsOption } from 'echarts';
import { ChartConfigProvider } from '@app/map';

export enum SCORE_CHART_COLOR {
  PRIMARY = '#39393A',
  ACCENT = '#E7E7E7',
  LABEL = '#2E384D',
}

export class ScoreChartConfigProvider {
  public static generateConfig(score: number, name: string): EChartsOption {
    return {
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      animationDuration: ChartConfigProvider.DEFAULT_ANIMATION_IN_MS,
      textStyle: ChartConfigProvider.TEXT_STYLE,
      series: [
        {
          type: 'gauge',
          startAngle: 190,
          endAngle: -10,
          min: 0,
          max: 10,
          splitNumber: 4,
          radius: '160%',

          center: ['50%', '80%'],

          itemStyle: {
            color: SCORE_CHART_COLOR.PRIMARY,
          },

          progress: {
            show: true,
            width: 10,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 20,
              color: [[1, SCORE_CHART_COLOR.ACCENT]],
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            formatter: (value): string => {
              return [value, `{name|${name}}`].join('\n');
            },
            rich: {
              name: {
                color: SCORE_CHART_COLOR.LABEL,
                fontSize: 16,
                lineHeight: 16,
              },
            },
            valueAnimation: true,
            width: '60%',
            lineHeight: 26,
            borderRadius: 8,
            offsetCenter: [0, '-20%'],
            fontSize: 26,
            fontWeight: 'bolder',
            color: 'inherit',
          },
          data: [
            {
              value: score,
            },
          ],
        },
      ],
    };
  }
}
