import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { GeoserverLayerService, LayerSliderFilter } from '@app/map';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-slider-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatSliderModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './slider-filter.component.html',
  styleUrls: ['./slider-filter.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SliderFilterComponent implements OnInit {
  @Input() public filter!: LayerSliderFilter;
  @Input() public filterForm!: FormGroup;

  readonly #destroyRef = inject(DestroyRef);
  readonly #geoserverLayerService = inject(GeoserverLayerService);

  public ngOnInit(): void {
    const group = new FormGroup({
      min: new FormControl(this.filter.min),
      max: new FormControl(this.filter.max),
    });
    this.filterForm.addControl(`${this.filter.type}_${this.filter.key}`, group);

    group.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.#destroyRef))
      .subscribe(values => {
        this.#geoserverLayerService.updateFilter({
          ...this.filter,
          min: values.min as number,
          max: values.max as number,
        });
      });
  }
}
