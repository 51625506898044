import { Injectable } from '@angular/core';
import { LAYER_NAME } from '@app/map/enums';
import {
  GeoserverLayerProvider,
  LayerFilterGenerator,
  MapLayerFilterProvider,
} from '@app/map/util';
import { FilterSpecification } from 'maplibre-gl';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { LayerFilterType, LayerWrapper } from '@app/map/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GeoserverLayerService {
  readonly #filters = new Map<LAYER_NAME, Map<string, LayerFilterType>>();

  readonly #overlayLayers: Map<string, LayerWrapper> = new Map<
    LAYER_NAME,
    LayerWrapper
  >();

  readonly #layerFilters$: BehaviorSubject<
    Map<LAYER_NAME, Map<string, LayerFilterType>>
  >;

  readonly #geoserverLayers$: BehaviorSubject<LayerWrapper[]>;

  constructor() {
    GeoserverLayerProvider.getLayers().forEach(item => {
      this.#overlayLayers.set(item.name, item);
    });

    MapLayerFilterProvider.getFilters().forEach(filter => {
      const layerMap =
        this.#filters.get(filter.layer) || new Map<string, LayerFilterType>();

      layerMap.set(filter.type + filter.key, filter);
      this.#filters.set(filter.layer, layerMap);
    });

    this.#layerFilters$ = new BehaviorSubject(this.#filters);

    this.#geoserverLayers$ = new BehaviorSubject(
      Array.from(this.#overlayLayers.values())
    );
  }

  public get filterLayers$(): Observable<Map<LAYER_NAME, FilterSpecification>> {
    return this.#layerFilters$.pipe(
      map(layerFilters => this.#mapToLayerNameByFilterSpec(layerFilters))
    );
  }

  #mapToLayerNameByFilterSpec(
    it: Map<LAYER_NAME, Map<string, LayerFilterType>>
  ): Map<LAYER_NAME, FilterSpecification> {
    const filterSpecByLayerName: Map<LAYER_NAME, FilterSpecification> =
      new Map();

    Array.from(it.entries()).forEach(
      ([layerName, layerFilterTypeBySourceLayer]: [
        LAYER_NAME,
        Map<string, LayerFilterType>,
      ]) => {
        const filterSpecification = LayerFilterGenerator.generate(
          layerFilterTypeBySourceLayer
        );

        if (filterSpecification) {
          filterSpecByLayerName.set(layerName, filterSpecification);
        }
      }
    );
    return filterSpecByLayerName;
  }

  public get geoserverLayers$(): Observable<LayerWrapper[]> {
    return this.#geoserverLayers$;
  }

  public setIsActiveByName(name: string, isActive: boolean): void {
    const layer = Array.from(this.#overlayLayers.values()).find(
      l => l.name === name
    );

    if (layer) {
      this.#overlayLayers.set(layer.name, { ...layer, isActive });
      this.#geoserverLayers$.next(Array.from(this.#overlayLayers.values()));
    }
  }

  public updateFilter(filter: LayerFilterType): void {
    const layerFilters = this.#filters.get(filter.layer);

    layerFilters?.set(filter.type + filter.key, filter);
    this.#layerFilters$.next(this.#filters);
  }

  public getFiltersByLayerName(layerName: LAYER_NAME): LayerFilterType[] {
    const layerFiltersMap = this.#filters.get(layerName);

    if (!layerFiltersMap) {
      return [];
    }

    return Array.from(layerFiltersMap.values());
  }
}
