import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateConfigurationDialogData } from '@app/map/components/spot-information/components/create-configuration-dialog/create-configuration-dialog-data';
import { CreateConfigurationDialogOutput } from '@app/map/components/spot-information/components/create-configuration-dialog/create-configuration-dialog-output';
import {
  DialogHeaderComponent,
  FlexListFormComponent,
  ListFormIdentifier,
  ListFormMetaNumber,
  ListFormMetaSelect,
  Spot,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { FilterFormComponent } from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components';

@Component({
  selector: 'app-create-configuration-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    TranslateModule,
    FlexListFormComponent,
    ReactiveFormsModule,
    FilterFormComponent,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './create-configuration-dialog.component.html',
  styleUrls: ['./create-configuration-dialog.component.scss'],
})
export class CreateConfigurationDialogComponent<
  T extends CreateConfigurationDialogData,
> {
  public isValidForm = false;

  /* TODO: Adjust when https://jira.eon.com/browse/ONETP-1893 has a solution */
  public createConfigurationDialogData: T = {
    chargingPower: new ListFormMetaSelect(
      'MAP.LOCATION_INFORMATION.CONFIGURATION_DIALOG.CHARGING_POWER_IN_KW',
      'chargingPower',
      ['50', '100', '150', '300', '350'],
      [Validators.required]
    ),
    connectors: new ListFormMetaNumber(
      'MAP.LOCATION_INFORMATION.CONFIGURATION_DIALOG.NUMBER_OF_CONNECTORS',
      'connectors',
      [Validators.max(10), Validators.min(1)],
      false
    ),
    id: new ListFormIdentifier('', [], false),
  } as T;

  public initialValues: CreateConfigurationDialogOutput[] = [];
  #lastValidFormValue: CreateConfigurationDialogOutput[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Spot,
    public readonly dialogRef: MatDialogRef<
      CreateConfigurationDialogComponent<T>
    >
  ) {
    this.#determineInitialValues();
  }

  #determineInitialValues(): void {
    const stationsById = new Map(
      this.data.stations.map(station => {
        return [station.id, station];
      })
    );

    this.initialValues = Array.from(stationsById.keys()).map(key => {
      const connectors = stationsById.get(key)?.connectors;
      const chargingPower = stationsById.get(key)?.chargingPower.toString();

      if (connectors && chargingPower) {
        return {
          chargingPower,
          connectors,
          id: key,
        };
      } else {
        throw new Error(`No matching station for id:  ${key}`);
      }
    });
  }

  public onIsValidChange($event: boolean): void {
    this.isValidForm = $event;
  }

  public closeIncludingData(): void {
    this.dialogRef.close(this.#lastValidFormValue);
  }

  public closeExcludingChanges(): void {
    this.dialogRef.close(this.initialValues);
  }

  public onValidFormValueChange($event: Array<unknown>): void {
    this.#lastValidFormValue = $event as CreateConfigurationDialogOutput[];
  }
}
