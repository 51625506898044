import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MaplibreControlBtnComponent } from '@app/map/components/maplibre-control-btn/maplibre-control-btn.component';
import { LAYER_NAME } from '@app/map';

@Component({
  selector: 'app-satellite-layer-toggle',
  standalone: true,
  imports: [CommonModule, MaplibreControlBtnComponent],
  templateUrl: './satellite-layer-toggle.component.html',
  styleUrls: [],
})
export class SatelliteLayerToggleComponent {
  public static readonly STANDARD_ICON = 'map';
  public static readonly SATELLITE_ICON = 'satellite_alt';

  @Output() public clicked = new EventEmitter<LAYER_NAME>();

  public icon = SatelliteLayerToggleComponent.SATELLITE_ICON;

  public onSatelliteControlClick(): void {
    if (this.icon === SatelliteLayerToggleComponent.STANDARD_ICON) {
      this.icon = SatelliteLayerToggleComponent.SATELLITE_ICON;
      this.clicked.emit(LAYER_NAME.BASEMAP);
    } else {
      this.icon = SatelliteLayerToggleComponent.STANDARD_ICON;
      this.clicked.emit(LAYER_NAME.SATELLITE);
    }
  }
}
