import { CONDITION_TYPE } from '@app/location-table-page/enum';

export interface ConditionMeta {
  conditionType: CONDITION_TYPE;
  labelTranslationIdentifier: string;
}

export class ConditionProvider {
  public static readonly INSTANCE = new ConditionProvider();

  readonly #conditionMetaByConditionType = new Map<
    CONDITION_TYPE,
    ConditionMeta
  >([
    [
      CONDITION_TYPE.GREATER_THAN,
      {
        conditionType: CONDITION_TYPE.GREATER_THAN,
        labelTranslationIdentifier: 'TABLE_VIEW.GREATER_THAN',
      },
    ],
    [
      CONDITION_TYPE.GREATER_THAN_OR_EQUAL,
      {
        conditionType: CONDITION_TYPE.GREATER_THAN_OR_EQUAL,
        labelTranslationIdentifier: 'TABLE_VIEW.GREATER_THAN_OR_EQUAL',
      },
    ],
    [
      CONDITION_TYPE.LESS_THAN,
      {
        conditionType: CONDITION_TYPE.LESS_THAN,
        labelTranslationIdentifier: 'TABLE_VIEW.LESS_THAN',
      },
    ],
    [
      CONDITION_TYPE.LESS_THAN_OR_EQUAL,
      {
        conditionType: CONDITION_TYPE.LESS_THAN_OR_EQUAL,
        labelTranslationIdentifier: 'TABLE_VIEW.LESS_THAN_OR_EQUAL',
      },
    ],
  ]);

  public getConditionsMeta(): ConditionMeta[] {
    return Array.from(this.#conditionMetaByConditionType.values());
  }

  public getConditionsByConditionType(
    conditionType: CONDITION_TYPE
  ): ConditionMeta | undefined {
    return this.#conditionMetaByConditionType.get(conditionType);
  }
}
