import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocationEvChargersIn10KMDto } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { CategoryChartConfigProvider } from './category-chart-config-provider';
import { provideEcharts, NgxEchartsDirective } from 'ngx-echarts';

@Component({
  selector: 'app-category-chart',
  standalone: true,
  imports: [CommonModule, NgxEchartsDirective],
  templateUrl: './category-chart.component.html',
  styleUrls: ['./category-chart.component.scss'],
  providers: [provideEcharts()],
})
export class CategoryChartComponent implements OnChanges, OnInit {
  @Input()
  public evChargers!: LocationEvChargersIn10KMDto;
  readonly #destroyRef = inject(DestroyRef);

  public chartOptions: EChartsOption | null = null;
  public readonly translateService = inject(TranslateService);

  public ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.chartOptions = this.generateChartOptions();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['evChargers']) {
      this.chartOptions = this.generateChartOptions();
    }
  }

  public generateChartOptions(): EChartsOption | null {
    return CategoryChartConfigProvider.generateConfig([
      {
        name: this.translateService.instant(
          'MAP.LOCATION_INFORMATION.SCORES.USAGE.EV_STATION_COMPETITORS_CATEGORIES.CATEGORY_1'
        ),
        value: this.evChargers.powerMinTo22,
        color: '#A6A6A6',
      },
      {
        name: this.translateService.instant(
          'MAP.LOCATION_INFORMATION.SCORES.USAGE.EV_STATION_COMPETITORS_CATEGORIES.CATEGORY_2'
        ),
        value: this.evChargers.power23To49,
        color: '#7F7F7F',
      },
      {
        name: this.translateService.instant(
          'MAP.LOCATION_INFORMATION.SCORES.USAGE.EV_STATION_COMPETITORS_CATEGORIES.CATEGORY_3'
        ),
        value: this.evChargers.power50To149,
        color: '#595959',
      },
      {
        name: this.translateService.instant(
          'MAP.LOCATION_INFORMATION.SCORES.USAGE.EV_STATION_COMPETITORS_CATEGORIES.CATEGORY_4'
        ),
        value: this.evChargers.power150ToMax,
        color: '#39393A',
      },
    ]);
  }
}
