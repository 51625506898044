import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarkerComponent } from '../marker/marker.component';
import { Observable, of } from 'rxjs';
import { IsLocationPipe, MARKER_COLOR, Spot } from '@app/shared';
import { MARKER_SIZE } from '@app/map/components/spot-marker/marker-size.enum';

@Component({
  selector: 'app-spot-marker',
  templateUrl: './spot-marker.component.html',
  styleUrls: ['./spot-marker.component.scss'],
  standalone: true,
  imports: [MarkerComponent, NgIf, IsLocationPipe],
})
export class SpotMarkerComponent {
  public readonly MARKER_COLOR = MARKER_COLOR;

  @Input()
  public isLoading$: Observable<boolean> = of(false);

  @Input({ required: true })
  public spot!: Spot;

  @Input() public size: MARKER_SIZE = MARKER_SIZE.NORMAL;
  @Input() public markerColor: MARKER_COLOR = MARKER_COLOR.BLUE;
  @Output()
  public clicked = new EventEmitter<MouseEvent>();

  public onSpotMarkerClicked($event: MouseEvent): void {
    this.clicked.emit($event);
  }
}
