import { LayerWrapper } from '@app/map';
import {
  LAYER_COLOR,
  LAYER_GROUP,
  LAYER_ID,
  LAYER_NAME,
  SOURCE_LAYER,
  SOURCE_SPECIFICATION_TYPE,
} from '@app/map/enums';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { environment } from '@environments/environment';

export class GeoserverLayerProvider {
  public static readonly DEFAULT_MIN_LAYER_ZOOM = 12;
  public static readonly DEFAULT_MAX_LAYER_ZOOM = 20;

  public static getLayers(): LayerWrapper[] {
    return [
      /**
       {
       name: LAYER_NAME.NETWORK_OPERATORS,
       labelTranslationIdentifier: 'MAP.LAYERS.NETWORK_OPERATORS.NAME',
       isActive: false,
       canBeToggled: true,
       icon: 'recent_patient',
       order: 1,
       sourceSpecification: {
       type: SOURCE_SPECIFICATION_TYPE.VECTOR,
       scheme: 'tms',
       tiles: [
       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:distribution_service_operators@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
       ],
       promoteId: 'id',
       },
       layerSpecifications: [
       {
       id: LAYER_ID.NETWORK_OPERATORS_FILL,
       type: 'fill',
       source: LAYER_NAME.NETWORK_OPERATORS,
       'source-layer': SOURCE_LAYER.DISTRIBUTION_SERVICE_OPERATORS,
       maxzoom: DEFUALT_MIN_LAYER_ZOOM,
       paint: {
       'fill-opacity': [
       'case',
       ['boolean', ['feature-state', 'hover'], false],
       0.1,
       0.05,
       ],
       'fill-color': LAYER_COLOR.NETWORK_OPERATORS,
       'fill-outline-color': 'black',
       },
       },
       {
       id: LAYER_ID.NETWORK_OPERATORS_OUTLINE,
       type: 'line',
       source: LAYER_NAME.NETWORK_OPERATORS,
       'source-layer': SOURCE_LAYER.DISTRIBUTION_SERVICE_OPERATORS,
       maxzoom: DEFUALT_MIN_LAYER_ZOOM,
       paint: {
       'line-width': [
       'case',
       ['boolean', ['feature-state', 'hover'], false],
       2,
       1,
       ],
       'line-color': LAYER_COLOR.NETWORK_OPERATORS,
       },
       },
       ],
       }, **/
      {
        name: LAYER_NAME.POPULATION_DENSITY,
        labelTranslationIdentifier: 'MAP.LAYERS.POPULATION_DENSITY',
        isActive: false,
        icon: 'diversity_3',
        canBeToggled: true,
        order: 1,
        zoomOnToggle: 8,
        sourceSpecification: {
          type: SOURCE_SPECIFICATION_TYPE.VECTOR,
          scheme: 'xyz',
          tiles: [
            `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:population_density_h3&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=application%2Fvnd.mapbox-vector-tile`,
          ],
        },
        layerSpecifications: [
          {
            id: LAYER_ID.POPULATION_DENSITY,
            type: 'heatmap',
            source: LAYER_NAME.POPULATION_DENSITY,
            'source-layer': SOURCE_LAYER.POPULATION_DENSITY,
            minzoom: 7,
            maxzoom: 9.7,
            paint: {
              'heatmap-weight': [
                'interpolate',
                ['linear'],
                ['get', 'overall_population_per_sqkm'],
                50,
                0,
                4000,
                1,
              ],
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(232, 237, 231, 0.8)',
                0.25,
                'rgb(129, 190, 206)',
                0.5,
                'rgb(55, 139, 164)',
                0.75,
                'rgb(3, 98, 128)',
                1,
                'rgb(1, 46, 74)',
              ],
              'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                7,
                10,
                15,
                20,
              ],
              'heatmap-opacity': 0.6,
            },
          },
        ],
      },
      {
        name: LAYER_NAME.CHARGING_STATIONS,
        labelTranslationIdentifier: 'MAP.LAYERS.CHARGING_STATIONS.NAME',
        isActive: false,
        canBeToggled: true,
        icon: 'charging_station',
        clusterIcon: 'assets/charging_station.svg',
        cluster: true,
        color: '#809c46',
        order: 2,
        zoomOnToggle: 12,
        sourceSpecification: {
          type: SOURCE_SPECIFICATION_TYPE.GEOJSON,
          data: `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:charging_stations&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=geojson`,
        },
        layerSpecifications: [],
      },
      {
        name: LAYER_NAME.SHOPS,
        group: LAYER_GROUP.POINTS_OF_INTEREST,
        labelTranslationIdentifier: 'MAP.LAYERS.POINTS_OF_INTEREST.SHOPS',
        isActive: false,
        canBeToggled: true,
        icon: 'shopping_cart',
        clusterIcon: 'assets/shopping_cart.svg',
        cluster: true,
        clusterType: 'round',
        color: '#CC6C6B',
        zoomOnToggle: 12,
        order: 3,
        sourceSpecification: {
          type: SOURCE_SPECIFICATION_TYPE.GEOJSON,
          data: `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_poi_shop&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=geojson`,
        },
        layerSpecifications: [],
      },
      {
        name: LAYER_NAME.SUSTENANCE,
        group: LAYER_GROUP.POINTS_OF_INTEREST,
        labelTranslationIdentifier: 'MAP.LAYERS.POINTS_OF_INTEREST.SUSTENANCE',
        isActive: false,
        canBeToggled: true,
        icon: 'restaurant',
        clusterIcon: 'assets/restaurant.svg',
        cluster: true,
        clusterType: 'round',
        color: '#AF8A66',
        zoomOnToggle: 12,
        order: 4,
        sourceSpecification: {
          type: SOURCE_SPECIFICATION_TYPE.GEOJSON,
          data: `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_poi_sustenance&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=geojson`,
        },
        layerSpecifications: [
          {
            id: `polygon-${LAYER_ID.SUSTENANCE}`,
            type: 'fill',
            source: LAYER_NAME.SUSTENANCE,
            'source-layer': LAYER_NAME.SUSTENANCE,
            minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
            paint: {
              'fill-color': 'white',
            },
            filter: ['==', '$type', 'Polygon'],
          },
          {
            id: LAYER_ID.SUSTENANCE,
            type: 'symbol',
            source: LAYER_NAME.SUSTENANCE,
            minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
            'source-layer': SOURCE_LAYER.SUSTENANCE,
            layout: {
              'icon-overlap': 'always',
              'icon-image': 'sustenance',
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.32 * 0.2,
                15,
                0.66 * 0.2,
                22,
                1.32 * 0.5,
              ],
            },
          },
        ],
      },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   name: LAYER_NAME.GRID,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.GROUP',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 4,
      //   sourceSpecification: {
      //     minzoom: 6,
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:network@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.GRID,
      //       type: 'line',
      //       source: LAYER_NAME.GRID,
      //       'source-layer': SOURCE_LAYER.NETWORK,
      //       minzoom: DEFUALT_MIN_LAYER_ZOOM,
      //       paint: {
      //         'line-color': LAYER_COLOR.GRID,
      //         'line-width': 2,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   name: LAYER_NAME.POWER_CABLE,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.POWER_CABLE',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 5,
      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:osm_power_cable@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.POWER_CABLE,
      //       minzoom: DEFUALT_MIN_LAYER_ZOOM,
      //       type: 'line',
      //       source: LAYER_NAME.POWER_CABLE,
      //       'source-layer': SOURCE_LAYER.OSM_POWER_CABLE,
      //       paint: {
      //         'line-color': LAYER_COLOR.POWER,
      //         'line-width': 1,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   order: 6,
      //   zoomOnToggle: 12,
      //   name: LAYER_NAME.POWER_LINE,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.POWER_LINE',
      //   isActive: false,
      //   canBeToggled: true,
      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:osm_power_line@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.POWER_LINE,
      //       type: 'line',
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       source: LAYER_NAME.POWER_LINE,
      //       'source-layer': SOURCE_LAYER.OSM_POWER_LINE,
      //       paint: {
      //         'line-color': LAYER_COLOR.POWER,
      //         'line-width': 1,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   name: LAYER_NAME.POWER_GENERATOR,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.POWER_GENERATOR',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 7,
      //   zoomOnToggle: 12,

      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:osm_power_generator@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.POWER_GENERATOR,
      //       type: 'fill',
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       source: LAYER_NAME.POWER_GENERATOR,
      //       'source-layer': SOURCE_LAYER.OSM_POWER_GENERATOR,
      //       paint: {
      //         'fill-opacity': 0.7,
      //         'fill-color': LAYER_COLOR.POWER,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   name: LAYER_NAME.POWER_TRANSFORMER,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.POWER_TRANSFORMER',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 8,
      //   zoomOnToggle: 12,

      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:osm_power_transformer@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.POWER_TRANSFORMER,
      //       type: 'circle',
      //       source: LAYER_NAME.POWER_TRANSFORMER,
      //       'source-layer': SOURCE_LAYER.OSM_POWER_TRANSFORMER,
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       paint: {
      //         'circle-radius': 3,
      //         'circle-color': LAYER_COLOR.POWER,
      //         'circle-opacity': 0.7,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.ENERGY_GRID,
      //   name: LAYER_NAME.POWER_POLE,
      //   labelTranslationIdentifier: 'MAP.LAYERS.ENERGY_GRID.POWER_POLE',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 9,
      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'tms',
      //     tiles: [
      //       `${environment.geoserverUrl}/gwc/service/tms/${environment.geoserverApiVersion}/spotone:osm_power_pole@EPSG:900913@pbf/{z}/{x}/{y}.pbf`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.POWER_POLE,
      //       type: 'circle',
      //       source: LAYER_NAME.POWER_POLE,
      //       minzoom: DEFUALT_MIN_LAYER_ZOOM,
      //       'source-layer': SOURCE_LAYER.OSM_POWER_POLE,
      //       paint: {
      //         'circle-radius': 2,
      //         'circle-color': LAYER_COLOR.POWER,
      //         'circle-opacity': 0.7,
      //       },
      //     },
      //   ],
      // },
      {
        name: LAYER_NAME.PARKING,
        labelTranslationIdentifier: 'MAP.LAYERS.PARKING_SPOTS',
        isActive: false,
        canBeToggled: true,
        icon: 'local_parking',
        order: 5,
        zoomOnToggle: 12,
        sourceSpecification: {
          type: SOURCE_SPECIFICATION_TYPE.VECTOR,
          scheme: 'xyz',
          tiles: [
            `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_parking&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=application%2Fvnd.mapbox-vector-tile`,
          ],
        },
        layerSpecifications: [
          {
            id: LAYER_ID.LANDUSE_PARKING,
            type: 'fill',
            source: LAYER_NAME.PARKING,
            minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
            maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
            'source-layer': SOURCE_LAYER.OSM_PARKING,
            paint: {
              'fill-opacity': 0.3,
              'fill-color': LAYER_COLOR.PARKING,
            },
            filter: ['!=', '$type', 'LineString'],
          },
        ],
      },
      // Temporarily disabled because not workey
      // {
      //   group: LAYER_GROUP.LANDUSE,
      //   name: LAYER_NAME.LANDUSE_COMMERCIAL,
      //   labelTranslationIdentifier: 'MAP.LAYERS.LANDUSE.COMMERCIAL',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 6,
      //   zoomOnToggle: 12,

      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'xyz',
      //     tiles: [
      //       `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_landuse_commercial&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=application%2Fvnd.mapbox-vector-tile`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.GEOSERVER_LANDUSE_COMMERCIAL,
      //       type: 'fill',
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       source: LAYER_NAME.LANDUSE_COMMERCIAL,
      //       'source-layer': SOURCE_LAYER.OSM_LANDUSE_COMMERCIAL,
      //       paint: {
      //         'fill-opacity': 0.3,
      //         'fill-color': LAYER_COLOR.LANDUSE_COMMERCIAL,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.LANDUSE,
      //   name: LAYER_NAME.LANDUSE_INDUSTRIAL,
      //   labelTranslationIdentifier: 'MAP.LAYERS.LANDUSE.INDUSTRIAL',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 7,
      //   zoomOnToggle: 12,

      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'xyz',
      //     tiles: [
      //       `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_landuse_industrial&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=application%2Fvnd.mapbox-vector-tile`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.GEOSERVER_LANDUSE_INDUSTRIAL,
      //       type: 'fill',
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       source: LAYER_NAME.LANDUSE_INDUSTRIAL,
      //       'source-layer': SOURCE_LAYER.OSM_LANDUSE_INDUSTRIAL,
      //       paint: {
      //         'fill-opacity': 0.3,
      //         'fill-color': LAYER_COLOR.LANDUSE_INDUSTRIAL,
      //       },
      //     },
      //   ],
      // },
      // {
      //   group: LAYER_GROUP.LANDUSE,
      //   name: LAYER_NAME.LANDUSE_RESIDENTIAL,
      //   labelTranslationIdentifier: 'MAP.LAYERS.LANDUSE.RESIDENTIAL',
      //   isActive: false,
      //   canBeToggled: true,
      //   order: 8,
      //   zoomOnToggle: 12,

      //   sourceSpecification: {
      //     type: SOURCE_SPECIFICATION_TYPE.VECTOR,
      //     scheme: 'xyz',
      //     tiles: [
      //       `${environment.geoserverUrl}/spotone/wms?service=WMS&version=1.1.1&request=GetMap&layers=spotone:osm_landuse_residential&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&styles=&format=application%2Fvnd.mapbox-vector-tile`,
      //     ],
      //   },
      //   layerSpecifications: [
      //     {
      //       id: LAYER_ID.GEOSERVER_LANDUSE_RESIDENTIAL,
      //       type: 'fill',
      //       minzoom: GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM,
      //       maxzoom: GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM,
      //       source: LAYER_NAME.LANDUSE_RESIDENTIAL,
      //       'source-layer': SOURCE_LAYER.OSM_LANDUSE_RESIDENTIAL,
      //       paint: {
      //         'fill-opacity': 0.3,
      //         'fill-color': LAYER_COLOR.LANDUSE_RESIDENTIAL,
      //       },
      //     },
      //   ],
      // },
    ];
  }
}
