<div class="toolbar__row">
  <div class="toolbar__element--start">
    <button
      (click)="openCreateFiltersDialog()"
      class="toolbar__icon-button"
      color="primary"
      mat-flat-button>
      <mat-icon fontSet="material-symbols-outlined">tune</mat-icon>
      {{ 'COMMON.FILTER' | translate }}
    </button>
    <app-show-columns-selector
      [checkboxes]="checkboxes"
      (selectionChanged)="onCheckboxChange($event)">
    </app-show-columns-selector>
  </div>
  <div class="toolbar__element--end">
    <button
      (click)="onExportBtnClick()"
      data-testid="export-btn"
      class="toolbar__icon-button"
      color="primary"
      mat-stroked-button>
      <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
      {{ 'COMMON.EXPORT' | translate }}
    </button>
  </div>
</div>
<div *ngIf="appliedConditions.length > 0" class="toolbar__row">
  <div class="toolbar__element--start">
    <p class="toolbar__text">{{ 'TABLE_VIEW.ACTIVE_FILTERS' | translate }}</p>
    <mat-chip-set>
      <mat-chip
        class="toolbar__chip"
        *ngFor="let appliedFilter of appliedConditions; let i = index">
        {{ appliedFilter.criterionTranslationIdentifier | translate }}
        <span class="toolbar__chip--lowercase">{{
          appliedFilter.conditionTypeTranslationIdentifier | translate
        }}</span>
        {{ appliedFilter.value }}
        <button
          matChipRemove
          (click)="removeCondition(i)"
          [attr.aria-label]="'remove ' + appliedFilter">
          <mat-icon fontSet="material-symbols-outlined">cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-set>
  </div>
</div>
