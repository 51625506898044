<div class="spots-list-element" data-testid="spots-list-element">
  <div *ngIf="showPrefix" class="spots-list-element__prefix">
    <app-spots-list-element-prefix [prefixColor]="prefixColor" />
  </div>
  <div
    #elementContent
    [matTooltip]="tooltip"
    [matTooltipDisabled]="disableTooltip()"
    class="spots-list-element__content">
    <ng-content />
  </div>
  <div *ngIf="showSuffix" class="spots-list-element__suffix">
    <app-spots-list-element-suffix
      icon="speed"
      [text]="suffixText"
      [shortSuffix]="shortSuffix" />
  </div>
</div>
