import { OverlayModule } from '@angular/cdk/overlay';
import { Component, input, signal } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { StationDto } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-stations-row',
  standalone: true,
  imports: [MatIconModule, OverlayModule, MatChipsModule, TranslateModule],
  templateUrl: './stations-row.component.html',
  styleUrl: './stations-row.component.scss',
})
export class StationsRowComponent {
  public stations = input.required<StationDto[]>();

  public isOverlayDisplayed = signal<boolean>(false);

  public onMouseEnter(): void {
    this.isOverlayDisplayed.update(() => true);
  }

  public onMouseLeave(): void {
    this.isOverlayDisplayed.update(() => false);
  }
}
