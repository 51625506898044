// eslint-disable-next-line @softarc/sheriff/deep-import
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  Input,
  NgZone,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ErrorHandlerService,
  LoadingService,
  LocationService,
  MaptilerService,
  NominatimService,
  ProjectService,
  RestrictionService,
  SearchService,
} from '@app/core';
import {
  RadiusRestrictionLayerComponent,
  EmptyLayerComponent,
  MaplibreControlBtnComponent,
  MarkerComponent,
  PoiGroupComponent,
  ProjectLocationMarkerGroupComponent,
  SatelliteLayerToggleComponent,
  SearchHereBtnComponent,
  SearchRadiusLayerComponent,
  SidebarComponent,
  SpotInformationComponent,
  SpotInformationFeatureConfig,
  SpotMarkerComponent,
  SpotMarkerGroupComponent,
  LayerZoomInfoMessageComponent,
} from '@app/map/components';
import { LayerSelectionState } from '@app/map/components/sidebar/components';
import { SpotOnHoverEvent } from '@app/map/components/sidebar/components/spots-found';
import { LayerWrapper, RecommendationDtoWrapper } from '@app/map/interfaces';
import { SearchResolverOutput } from '@app/map/resolver';
import {
  GeoserverLayerProvider,
  LocationEstimationDtoMapper,
  MapConfig,
  MapConfigProvider,
  SpotClickEvent,
} from '@app/map/util';
import {
  API_ERROR_TYPE,
  APP_ROUTES,
  ApiError,
  ApiErrorDiscriminator,
  COUNTRY_CODE,
  FORM_NAME,
  GeocodingResponseDto,
  LocationDto,
  LocationRecommendationRequestDto,
  LocationRequestDto,
  MARKER_COLOR,
  ProjectDto,
  SearchFormValue,
  Spot,
} from '@app/shared';
import { RadiusRestrictionQueryPipe } from '@app/shared/';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import { isNil } from 'lodash-es';
import {
  FilterSpecification,
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  LngLatLike,
  Map as MaplibreMap,
  MapMouseEvent,
  StyleSpecification,
} from 'maplibre-gl';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { LAYER_NAME, SOURCE_SPECIFICATION_TYPE } from './enums';
import { GeoserverLayerService } from './geoserver-layer.service';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { ProjectSummary } from '@app/map/components/sidebar/components/layer-panel/components/project-layer-group/project-summary';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { PROJECT_EXPANSION } from '@app/core/services/project.service';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { RegionsRestrictionLayerComponent } from './components/regions-restriction-layer/regions-restriction-layer.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    NgxMapLibreGLModule,
    SpotInformationComponent,
    MatButtonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    AsyncPipe,
    TranslateModule,
    SidebarComponent,
    MarkerComponent,
    SpotMarkerComponent,
    SpotMarkerGroupComponent,
    MaplibreControlBtnComponent,
    SatelliteLayerToggleComponent,
    EmptyLayerComponent,
    RadiusRestrictionLayerComponent,
    RegionsRestrictionLayerComponent,
    SearchRadiusLayerComponent,
    RadiusRestrictionQueryPipe,
    SearchHereBtnComponent,
    PoiGroupComponent,
    ProjectLocationMarkerGroupComponent,
    LayerZoomInfoMessageComponent,
  ],
})
export class MapComponent implements OnInit {
  protected readonly MAP_CONFIG: MapConfig = MapConfigProvider.config;
  protected readonly MARKER_COLOR = MARKER_COLOR;
  protected readonly SOURCE_SPECIFICATION_TYPE = SOURCE_SPECIFICATION_TYPE;
  protected readonly LAYER_NAME = LAYER_NAME;
  protected readonly INITIAL_STYLE_SPECIFICATION =
    MaptilerService.INITIAL_STYLE_SPECIFICATION;

  public map?: MaplibreMap;
  public isSatelliteView = false;
  public geoserverLayers?: LayerWrapper[];
  public geoserverLayerFilters: Map<string, FilterSpecification> = new Map();
  public recommendationsById: WritableSignal<
    Map<string, RecommendationDtoWrapper>
  > = signal(new Map());
  public recommendations: Signal<RecommendationDtoWrapper[]> = computed(() =>
    Array.from(this.recommendationsById().values())
  );
  public spotsFoundOptions: Signal<Spot[]> = computed(() => [
    ...this.recommendationsById().values(),
    ...this.locations(),
  ]);
  public selectedSpot?: Spot = undefined;
  public selectedSpot$: Subject<Spot> = new Subject<Spot>();
  public temporaryRecommendationMarker?: LngLatLike = undefined;
  public temporaryRecommendation?: RecommendationDtoWrapper;
  public spotsWithSizeBig: Spot[] = [];
  public spotsWithSizeBig$: Subject<Spot[]> = new Subject();
  readonly #zone = inject(NgZone);
  readonly project: WritableSignal<ProjectDto | undefined> = signal(undefined);
  public style?: StyleSpecification;
  public center = signal<LngLatLike>(
    this.MAP_CONFIG.DEFAULT_CENTER as LngLatLike
  );

  @Input()
  public freeToPlay: boolean = false;
  public zoom = signal<number>(this.MAP_CONFIG.DEFAULT_ZOOM_EMPTY_PROJECT);
  public fitBounds = signal<LngLatBoundsLike | undefined>(undefined);
  public showMap = signal<boolean>(false);
  public locationRecommendationRequest = signal<
    LocationRecommendationRequestDto | undefined
  >(undefined);
  public clusterBounds = new BehaviorSubject<LngLatBounds | undefined>(
    undefined
  );
  public readonly spotInformationFeatureConfig: SpotInformationFeatureConfig = {
    networkOperator: true,
    crudOperations: true,
  };
  public geocodingResult?: GeocodingResponseDto;
  public showSearchPanel = false;
  public expandSearchPanel = false;
  lastSubmittedSearch?: SearchFormValue | null;
  previousCoordinates?: LngLat;
  isSearchHereBtnHidden = signal<boolean>(true);
  zoomLevel = signal<number | undefined>(undefined);
  isClusterLayerActivated = signal<boolean>(false);

  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #layerService = inject(GeoserverLayerService);
  readonly #translateService = inject(TranslateService);
  readonly #maptilerService = inject(MaptilerService);
  protected satelliteTilesUrl = this.#maptilerService.satelliteTilesUrl;
  readonly #searchService = inject(SearchService);
  public lastSubmittedSearch$: Observable<SearchFormValue | null> =
    this.#searchService.lastSubmittedSearch$.pipe();
  readonly #projectService = inject(ProjectService);
  readonly #loadingService = inject(LoadingService);
  readonly #errorHandlerService = inject(ErrorHandlerService);
  readonly #locationService = inject(LocationService);
  readonly #geocodingService = inject(NominatimService);
  readonly #destroyRef = inject(DestroyRef);
  public isLoading$: Observable<boolean> = toObservable(
    this.#loadingService.isLoading
  ).pipe(distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef));
  readonly #restrictionService = inject(RestrictionService);
  public readonly radiusIsRestricted = this.#restrictionService.isRestricted;
  private locationsById: WritableSignal<Map<string, LocationDto>> = signal(
    new Map()
  );
  public locations: Signal<LocationDto[]> = computed(() => {
    return Array.from(this.locationsById().values());
  });
  public isSpotInformationVisible: boolean = false;

  #unsubscribeMapEvaluation = new Subject<void>();

  public projectsById = new Map<string, ProjectDto>();
  public activeProjectsLayersById: WritableSignal<Map<string, ProjectDto>> =
    signal(new Map<string, ProjectDto>());

  private projectSummaries: WritableSignal<ProjectSummary[] | undefined> =
    signal(undefined);

  public filteredProjectSummaries = computed(() =>
    this.projectSummaries()?.filter(
      project => project.id !== this.project()?.id
    )
  );

  public projects$: Observable<ProjectSummary[]> = this.#projectService
    .getProjects([PROJECT_EXPANSION.LOCATIONS])
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      tap(
        projects =>
          (this.projectsById = new Map(
            projects.map(project => [project.id, project])
          ))
      ),
      map(projects =>
        projects.map(project => {
          return { id: project.id, name: project.name };
        })
      )
    );

  public ngOnInit(): void {
    if (this.freeToPlay) {
      this.#maptilerService.loadFreeMapStyle();
    } else {
      this.#maptilerService.loadStyle();
    }

    if (!this.freeToPlay) {
      this.#projectService
        .getProjects([PROJECT_EXPANSION.LOCATIONS])
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          tap(
            projects =>
              (this.projectsById = new Map(
                projects.map(project => [project.id, project])
              ))
          ),
          map(projects =>
            projects.map(project => {
              return { id: project.id, name: project.name } as ProjectSummary;
            })
          )
        )
        .subscribe(summaries => this.projectSummaries.set(summaries));
    }

    let resolverOutput: SearchResolverOutput;
    this.#route.data
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap(() => this.recommendationsById.set(new Map())),
        tap(data => this.adjustedPanelAppearance(data['resolverOutput'])),
        switchMap(data => {
          resolverOutput = data['resolverOutput'];
          if (resolverOutput?.projectId) {
            return combineLatest([
              this.#projectService.loadProjectById(resolverOutput.projectId),
              this.#locationService.loadLocations(resolverOutput.projectId),
            ]);
          } else if (resolverOutput?.geocodingResult) {
            this.locationRecommendationRequest.set(
              resolverOutput?.geocodingResult
            );
            this.selectedSpot = undefined;
            this.showMap.set(true);

            const rangeCircle = turf.circle(
              [
                resolverOutput?.geocodingResult.longitude,
                resolverOutput?.geocodingResult.latitude,
              ],
              resolverOutput?.geocodingResult.radiusMeter,
              {
                units: 'meters',
              }
            );

            this.fitBounds.set(turf.bbox(rangeCircle) as LngLatBoundsLike);

            return combineLatest([
              of(undefined),
              of([]),
              this.#locationService
                .loadRecommendations(resolverOutput.geocodingResult)
                .pipe(
                  map(recommendations => {
                    return recommendations.map((recommendation, index) => {
                      return {
                        ...recommendation,
                        id: index.toString(10),
                      };
                    });
                  }),
                  catchError(error => {
                    if (ApiErrorDiscriminator.isApiError(error)) {
                      const customError = error.error.errors[0] as ApiError;

                      if (
                        customError.type ===
                        API_ERROR_TYPE.OUTSIDE_AREA_RESTRICTION
                      ) {
                        this.#errorHandlerService.handleCustomError(
                          'MAP.RECOMMENDATION_NOT_ALLOWED'
                        );
                      }
                    }
                    return of(undefined);
                  })
                ),
            ]);
          }
          return combineLatest([of(undefined), of([]), of([])]);
        })
      )
      .subscribe({
        next: ([project, locations, recommendations]) => {
          if (project && locations.length > 0) {
            const lastLocation = locations[locations.length - 1];
            this.center.update(() => [
              lastLocation.longitude,
              lastLocation.latitude,
            ]);
            this.zoom.update(() => this.MAP_CONFIG.DEFAULT_ZOOM);
          }

          if (project && locations.length == 0) {
            this.center.update(
              () => this.MAP_CONFIG.DEFAULT_CENTER as LngLatLike
            );
            this.zoom.update(() => this.MAP_CONFIG.DEFAULT_ZOOM_EMPTY_PROJECT);
          }

          this.showMap.set(true);
          this.project.set(project);
          this.selectedSpot = undefined;
          this.locationsById.set(
            new Map(locations.map(location => [location.id, location]))
          );

          if (recommendations) {
            this.recommendationsById.set(
              new Map(
                recommendations.map(recommendation => [
                  recommendation.id,
                  recommendation,
                ])
              )
            );
          }
        },
        error: error => {
          this.#errorHandlerService.handleError(error);
        },
      });

    this.#searchService.lastSubmittedSearch$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(searchValues => (this.lastSubmittedSearch = searchValues));

    combineLatest([
      this.#layerService.geoserverLayers$.pipe(
        filter(layers => !isNil(layers))
      ),
      this.#layerService.filterLayers$,
    ]).subscribe(([geoserverLayers, layerFilters]) => {
      this.geoserverLayerFilters = layerFilters;
      this.geoserverLayers = geoserverLayers;
      this.geoserverLayers.sort((a, b) => a.order - b.order);

      if (this.geoserverLayers.some(layer => layer.isActive && layer.cluster)) {
        this.isClusterLayerActivated.set(true);
      } else {
        this.isClusterLayerActivated.set(false);
      }
    });

    this.#maptilerService.style$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(style => {
        this.style = style;
      });
  }

  public initMap(mapLibreMap: MaplibreMap): void {
    this.map = mapLibreMap;
    MapConfigProvider.initZoomBehaviour(mapLibreMap);
    MapConfigProvider.initLayerBehaviour(mapLibreMap, this.#translateService);
    this.previousCoordinates = this.map.getCenter();
    this.calculateClusterBounds();
    this.map.on('moveend', this.calculateClusterBounds.bind(this));
    this.map.on('zoom', () => {
      const zoom = Math.floor(this.map?.getZoom() || -1);
      if (!zoom || zoom == -1) {
        return;
      }
      if (zoom !== this.zoomLevel()) {
        this.#zone.run(() => {
          this.zoomLevel.set(zoom);
        });
      }
    });
  }

  public onLocationClicked(event: SpotClickEvent): void {
    this.selectedSpot = event.spot;
    this.isSpotInformationVisible = event.shouldOpenSpotInformation;
    this.temporaryRecommendationMarker = undefined;
    this.temporaryRecommendation = undefined;
    if (event.shouldOpenSpotInformation) {
      event.$event.stopPropagation(); // need propagation for popup
    }
  }

  public onMapClick(event: MapMouseEvent): void {
    const features = this.map?.queryRenderedFeatures(event.point);
    if (features?.findIndex(f => f.layer.id.includes('clusters-')) !== -1) {
      return;
    }
    this.#unsubscribeMapEvaluation.next();

    if (this.selectedSpot) {
      this.selectedSpot = undefined;
      this.temporaryRecommendationMarker = undefined;
      this.temporaryRecommendation = undefined;
    } else {
      if (!this.isEstimationEnabled()) {
        return;
      }

      const temporaryCoordinates = [
        event['lngLat'].lng,
        event['lngLat'].lat,
      ] as LngLatLike;

      const isOutsideOfAreaRestriction =
        this.#restrictionService.checkIfOutsideRadiusRestriction(
          temporaryCoordinates
        );

      const isInsideAllowedRegion =
        this.#restrictionService.checkIfInsideAllowedRegions(
          temporaryCoordinates
        );

      if (
        (this.#restrictionService.radiusRestrictions() &&
          isOutsideOfAreaRestriction) ||
        (this.#restrictionService.allowedRegions() && !isInsideAllowedRegion)
      ) {
        this.temporaryRecommendationMarker = undefined;
        this.#errorHandlerService.handleCustomError(
          'MAP.ESTIMATION_NOT_ALLOWED'
        );
        return;
      }

      this.temporaryRecommendationMarker = temporaryCoordinates;

      this.#locationService
        .loadEstimation(
          LocationEstimationDtoMapper.map(
            event['lngLat'].lng,
            event['lngLat'].lat,
            'DE',
            this.lastSubmittedSearch?.[FORM_NAME.AMOUNT_OF_CHARGING_STATIONS],
            this.lastSubmittedSearch?.[FORM_NAME.MAX_CAPACITY_IN_KW]
          )
        )
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          map(estimation => {
            return {
              ...estimation,
              id: LocationService.DUMMY_ID_FOR_ESTIMATION,
            };
          })
        )
        .subscribe({
          next: (estimation: RecommendationDtoWrapper) => {
            if (estimation) {
              this.temporaryRecommendationMarker = [
                estimation.longitude,
                estimation.latitude,
              ];
              this.selectedSpot = estimation;
              this.isSpotInformationVisible = true;
            }
          },
          error: error => {
            if (error.message === 'Not supported country') {
              this.#errorHandlerService.handleCustomError(
                'MAP.ESTIMATION_NOT_ALLOWED'
              );
              return;
            }

            if (ApiErrorDiscriminator.isApiError(error)) {
              const customError = error.error.errors[0] as ApiError;

              if (
                customError.type === API_ERROR_TYPE.OUTSIDE_AREA_RESTRICTION
              ) {
                this.#errorHandlerService.handleCustomError(
                  'MAP.ESTIMATION_NOT_ALLOWED'
                );
              }
            } else {
              this.#errorHandlerService.handleError(error);
            }
          },
        });
    }
  }

  public isEstimationEnabled(): boolean {
    return !!this.map && this.map.getZoom() > this.MAP_CONFIG.ESTIMATION_ZOOM;
  }

  public saveRecommendation($event: {
    locationRequest: LocationRequestDto;
    recommendationId: string;
  }): void {
    if ($event.recommendationId === LocationService.DUMMY_ID_FOR_ESTIMATION) {
      this.temporaryRecommendationMarker = undefined;
    }
    this.#locationService
      .createLocation($event.locationRequest)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: spot => {
          this.selectedSpot = spot;

          this.recommendationsById.update(value => {
            value.delete($event.recommendationId);
            return new Map(value);
          });
          this.locationsById.update(locations => {
            locations.set(spot.id, spot);
            return new Map(locations);
          });
        },
        error: error => {
          this.#errorHandlerService.handleError(error);
        },
      });
  }

  public deleteLocation($event: { locationId: string }): void {
    const centerBeforeDelete = this.center;

    this.#locationService
      .deleteLocation($event.locationId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.selectedSpot = undefined;
          this.locationsById.update(locations => {
            locations.delete($event.locationId);
            return new Map(locations);
          });
          this.center = centerBeforeDelete;
        },
        error: error => {
          this.#errorHandlerService.handleError(error);
        },
      });
  }

  public spotsFoundSelectionChange($event: Spot): void {
    this.selectedSpot = $event;
    this.selectedSpot$.next($event);
    this.isSpotInformationVisible = true;
  }

  public spotOnHover($event: SpotOnHoverEvent): void {
    if ($event.hover && $event.spot) {
      this.spotsWithSizeBig = [$event.spot];
      this.spotsWithSizeBig$.next(this.spotsWithSizeBig);
    } else {
      this.spotsWithSizeBig = [];
      this.spotsWithSizeBig$.next([]);
    }
  }

  public onSatelliteControlClick($event: LAYER_NAME): void {
    this.isSatelliteView = $event === LAYER_NAME.SATELLITE;
  }

  public onSearchFormSubmitClick(): void {
    this.selectedSpot = undefined;
  }

  public onLayerStageChange($event: LayerSelectionState): void {
    this.#layerService.setIsActiveByName($event.name, $event.isActive);

    if (this.map && $event.isActive) {
      const layerMinZoom = $event.layer.layerSpecifications[0]?.minzoom
        ? $event.layer.layerSpecifications[0].minzoom
        : GeoserverLayerProvider.DEFAULT_MIN_LAYER_ZOOM;
      const layerMaxZoom = $event.layer.layerSpecifications[0]?.maxzoom
        ? $event.layer.layerSpecifications[0].maxzoom
        : GeoserverLayerProvider.DEFAULT_MAX_LAYER_ZOOM;
      const currentZoom = this.map.getZoom();

      if (currentZoom >= layerMaxZoom || currentZoom <= layerMinZoom) {
        this.map.zoomTo($event.layer.zoomOnToggle);
      }
    }
  }

  public onProjectLayerStageChange(projectIds: string[]): void {
    this.activeProjectsLayersById.update(() =>
      this.buildActiveProjectsLayersById(projectIds)
    );
  }

  private buildActiveProjectsLayersById(
    projectIds: string[]
  ): Map<string, ProjectDto> {
    return new Map(
      projectIds.map(id => {
        const project = this.projectsById.get(id);
        if (project) {
          return [id, project];
        }
        throw new Error(`Project ${id} not found`);
      })
    );
  }

  public isNotSupportedCountry(countryCode: COUNTRY_CODE): boolean {
    return !Object.values(COUNTRY_CODE).includes(countryCode);
  }

  public onSearchHereBtnClick() {
    const { lat, lng } = this.map!.getCenter();
    if (this.lastSubmittedSearch) {
      this.#geocodingService
        .searchByCoordinates(lat, lng)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(res => {
          if (
            this.isNotSupportedCountry(
              res.address.countryCode.toUpperCase() as COUNTRY_CODE
            )
          ) {
            this.#errorHandlerService.handleCustomError(
              'MAP.RECOMMENDATION_NOT_ALLOWED'
            );
            return;
          }

          const route = !this.freeToPlay
            ? `/${APP_ROUTES.MAP}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`
            : `/${APP_ROUTES.FREE_TO_PLAY}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`;

          this.#router.navigate([route], {
            queryParams: {
              ...this.lastSubmittedSearch,
              [FORM_NAME.CITY_OR_AREA]: res.id,
              [FORM_NAME.COUNTRY_CODE]: res.address.countryCode.toUpperCase(),
            },
          });
        });
      this.isSearchHereBtnHidden.set(true);
      this.previousCoordinates = this.map!.getCenter();
    }
  }

  public onMapDragEnd() {
    const mapCenter = this.map!.getCenter();
    const temporaryCoordinates = [mapCenter.lng, mapCenter.lat] as LngLatLike;
    const currentZoomLevel = this.map!.getZoom();

    if (this.shouldCalculateSearchHereBtnAppearance(temporaryCoordinates)) {
      this.isSearchHereBtnHidden.update(() => {
        return !(
          this.previousCoordinates &&
          this.MAP_CONFIG.isSearchHereBtnDisplayed(
            mapCenter,
            this.previousCoordinates,
            Math.round(currentZoomLevel)
          )
        );
      });
    } else {
      this.isSearchHereBtnHidden.set(true);
    }
  }

  shouldCalculateSearchHereBtnAppearance(
    temporaryCoordinates: LngLatLike
  ): boolean {
    if (
      this.previousCoordinates &&
      this.project() === undefined &&
      this.showSearchPanel
    ) {
      if (this.#restrictionService.radiusRestrictions()) {
        return false;
      }

      const isInsideAllowedRegion =
        this.#restrictionService.checkIfInsideAllowedRegions(
          temporaryCoordinates
        );
      return this.previousCoordinates && isInsideAllowedRegion;
    }
    return false;
  }

  private adjustedPanelAppearance(resolverOutput?: SearchResolverOutput) {
    if (this.freeToPlay) {
      this.expandSearchPanel = true;
      this.showSearchPanel = true;
      return;
    }

    if (resolverOutput?.geocodingResult) {
      this.expandSearchPanel = false;
      this.showSearchPanel = true;
    } else {
      this.showSearchPanel = false;
      this.expandSearchPanel = false;
    }
  }

  private calculateClusterBounds() {
    const bounds = this.map!.getBounds();

    const width = (bounds.getEast() - bounds.getWest()) * 0.5;
    const height = (bounds.getNorth() - bounds.getSouth()) * 0.5;

    const clusterBounds = new LngLatBounds(
      new LngLat(bounds.getWest() - width, bounds.getSouth() - height),
      new LngLat(bounds.getEast() + width, bounds.getNorth() + height)
    );

    this.clusterBounds.next(clusterBounds);
  }
}
