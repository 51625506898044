<div (click)="onClicked($event)" class="element" [className]="size">
  <svg viewBox="0 0 27 38" xmlns="http://www.w3.org/2000/svg">
    <path
      class="st0"
      d="M 13.121 0.015 C 5.921 0.015 0.021 5.915 0.021 13.115 C 0.021 20.215 6.321 30.315 12.021 37.215 C 12.321 37.515 12.621 37.815 13.121 37.815 C 13.621 37.815 13.921 37.515 14.221 37.215 C 19.821 30.415 26.221 20.215 26.221 13.115 C 26.221 6.015 20.521 0.215 13.521 0.015 L 13.121 0.015 Z"
      [attr.fill]="color"></path>
    <path
      class="st1"
      d="M 13.121 20.915 C 8.621 20.915 5.021 17.315 5.021 12.815 C 5.021 8.315 8.621 4.715 13.121 4.715 C 17.621 4.715 21.221 8.315 21.221 12.815 C 21.221 17.315 17.621 20.915 13.121 20.915 Z"
      style="
        clip-rule: evenodd;
        fill: rgb(255, 255, 255);
        fill-rule: evenodd;
      " />
  </svg>

  <mat-spinner
    *ngIf="loading$ | async"
    [diameter]="diameter"
    [color]="indicatorColor"
    data-testid="marker-loading-indicator"
    class="spinner"></mat-spinner>
</div>
