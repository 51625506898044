import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Spot } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';

@Component({
  selector: 'app-consumption-forecast-chart',
  standalone: true,
  imports: [NgxEchartsDirective],
  templateUrl: './consumption-forecast-chart.component.html',
  styleUrl: './consumption-forecast-chart.component.scss',
  providers: [provideEcharts()],
})
export class ConsumptionForecastChartComponent implements OnChanges, OnInit {
  @Input()
  public spot!: Spot;

  readonly #translateService = inject(TranslateService);
  readonly #destroyRef = inject(DestroyRef);

  option = signal<EChartsOption | null>({});

  ngOnInit(): void {
    this.#translateService.onLangChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.option.set(this.generateChartOptions());
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['spot']) {
      this.option.set(this.generateChartOptions());
    }
  }

  public generateChartOptions() {
    return {
      tooltip: {
        trigger: 'axis',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: function (params: any) {
          let result = params[0].value[0] + '<br/>';
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          params.forEach(function (item: any) {
            result += item.marker + Math.round(item.value[1]) + ' kWh<br/>';
          });
          return result;
        },
      },
      legend: {},
      grid: {
        top: 60,
        bottom: 30,
        left: '10%',
        right: 15,
      },
      xAxis: {
        type: 'value',
        min: 2024,
        max: 2035,
        axisLabel: {
          formatter: (value: number) => {
            return value.toString();
          },
        },
      },
      yAxis: {
        type: 'value',
        name: this.#translateService.instant(
          'MAP.LOCATION_INFORMATION.SCORES.USAGE.USAGE_FORECASTING.CONSUMPTION_WITH_UNIT'
        ),
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 35,
      },
      series: [
        {
          name: this.#translateService.instant(
            'MAP.LOCATION_INFORMATION.SCORES.USAGE.USAGE_FORECASTING.MAX_POTENTIAL_CONSUMPTION'
          ),
          type: 'line',
          data: this.spot.forecastOptimalConsumptionDay?.map(forecast => [
            forecast.year,
            forecast.value,
          ]),
          itemStyle: {
            color: '#00bfff',
          },
        },
        {
          name: this.#translateService.instant(
            'MAP.LOCATION_INFORMATION.SCORES.USAGE.USAGE_FORECASTING.CURRENT_CONFIGURATION_CONSUMPTION'
          ),
          type: 'line',
          data: this.spot.forecastConsumptionDay?.map(forecast => [
            forecast.year,
            forecast.value,
          ]),
          itemStyle: {
            color: 'black',
          },
        },
      ],
    } as EChartsOption;
  }
}
