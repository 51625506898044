import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import {
  CurrencyImpurePipe,
  Spot,
  TooltipPipe,
  TooltipShowDelayPipe,
} from '@app/shared';
import { TOOLTIP } from '@app/shared/util';
import { AttributeTitleComponent } from '../attribute-title/attribute-title.component';
import { AttributeComponent } from '../attribute/attribute.component';
import { MessageComponent } from '../message/message.component';
import { RANGE_LABEL_FORMAT } from '../range-chart/range-chart-config-provider';
import { RangeChartComponent } from '../range-chart/range-chart.component';
import { ScoreChartComponent } from '../score-chart/score-chart.component';
import { ScoreExplanationComponent } from '../score-explanation/score-explanation.component';
import { CurrencyFormatter } from '@app/map/components/spot-information/util';
import { MoneyRangeFormatPipe, RoundIntervalPipe } from '../../pipes';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserService } from '@app/core';

@Component({
  selector: 'app-grid-score-tab',
  standalone: true,
  imports: [
    CommonModule,
    ScoreExplanationComponent,
    ScoreChartComponent,
    AttributeTitleComponent,
    AttributeComponent,
    RangeChartComponent,
    MessageComponent,
    CurrencyPipe,
    RoundIntervalPipe,
    CurrencyImpurePipe,
    DecimalPipe,
    TooltipPipe,
    TooltipShowDelayPipe,
    MatDividerModule,
    MatTableModule,
    MatIconModule,
    MoneyRangeFormatPipe,
    MatExpansionModule,
    MoneyRangeFormatPipe,
  ],
  templateUrl: './grid-score-tab.component.html',
  styleUrls: ['./grid-score-tab.component.scss'],
})
export class GridScoreTabComponent {
  @Input({ required: true })
  public spot!: Spot;

  public readonly TT = TOOLTIP;
  public LABEL_FORMAT = RANGE_LABEL_FORMAT;
  public readonly formatCurrency = CurrencyFormatter.formatCurrency;
  readonly #userService = inject(UserService);

  public readonly isFreeUser = this.#userService.isFreeUser;
}
