import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LAYER_NAME } from '@app/map/enums';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

@Component({
  selector: 'app-empty-layer',
  standalone: true,
  imports: [CommonModule, NgxMapLibreGLModule],
  templateUrl: './empty-layer.component.html',
  styleUrls: [],
})
export class EmptyLayerComponent {
  public readonly LAYER_NAME = LAYER_NAME;
}
