import {
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LayerGroupHeaderComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer-group-header/layer-group-header.component';
import { FiltersComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer/components';
import { MatIcon } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProjectSummary } from '@app/map/components/sidebar/components/layer-panel/components/project-layer-group/project-summary';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap, timer } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-project-layer-group',
  standalone: true,
  imports: [
    LayerGroupHeaderComponent,
    FiltersComponent,
    MatIcon,
    MatSlideToggle,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: './project-layer-group.component.html',
  styleUrl: './project-layer-group.component.scss',
})
export class ProjectLayerGroupComponent implements OnInit {
  static readonly DELAY_FOR_FORM_UPDATE = 2000;
  readonly AMOUNT_OF_LAYER_UNTIL_SCROLL = 5;
  @Input({ required: true }) groupName!: string;
  projectSummaries = input<ProjectSummary[] | null>([]);
  @Output() activeProjectLayer = new EventEmitter<string[]>();
  readonly #destroyRef = inject(DestroyRef);

  public projectLayerForm: FormGroup;
  public projectSummariesToDisplay: ProjectSummary[] = [];

  constructor() {
    this.projectLayerForm = new FormGroup({});
    effect(() => {
      this.projectSummariesToDisplay = this.projectSummaries() ?? [];
      if (this.projectSummariesToDisplay.length > 0) {
        this.projectSummariesToDisplay.forEach(projectSummary => {
          this.projectLayerForm.addControl(
            projectSummary.id,
            new FormControl(undefined, Validators.required)
          );
        });
      }
    });
  }
  ngOnInit(): void {
    this.observeForm();
  }

  private observeForm() {
    timer(ProjectLayerGroupComponent.DELAY_FOR_FORM_UPDATE)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        mergeMap(() => this.projectLayerForm.valueChanges)
      )
      .subscribe(form => {
        this.activeProjectLayer.emit(this.getListOfActiveLayers(form));
      });
  }

  private getListOfActiveLayers(form: {
    [key: string]: boolean | null | undefined;
  }) {
    return Object.entries(form)
      .filter(([, value]) => value === true)
      .map(([key]) => key);
  }
}
