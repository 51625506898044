<div
  echarts
  [matTooltip]="
    (tooltipTranslationIdentifier ? tooltipTranslationIdentifier : '')
      | translate
  "
  [matTooltipShowDelay]="tooltipShowDelay"
  [options]="chartOptions"
  class="score-chart"
  [autoResize]="false"></div>
