<span
  class="stations-count"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  cdkOverlayOrigin
  data-testid="station-amount-span"
  #trigger="cdkOverlayOrigin">
  {{ stations().length }}
</span>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOverlayDisplayed()">
  <div class="overlay-container" data-testid="cdk-overlay">
    <mat-chip-set>
      @for (station of stations(); track station.id) {
        <mat-chip-option>
          {{ station.chargingPower }} {{ 'COMMON.KW' | translate }}
        </mat-chip-option>
      }
    </mat-chip-set>
  </div>
</ng-template>
