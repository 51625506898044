import { LAYER_ID, LAYER_NAME } from '@app/map/enums';
import { EventData } from '@maplibre/ngx-maplibre-gl';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import {
  LngLat,
  MapLayerMouseEvent,
  Map as MapLibreMap,
  MapMouseEvent,
  Popup,
} from 'maplibre-gl';

export interface MapConfig {
  DEFAULT_CENTER: number[];
  DEFAULT_ZOOM: number;
  MAX_ZOOM: number;
  MIN_ZOOM: number;
  ESTIMATION_ZOOM: number; // Specifies at which zoom level it is possible to click on the map to get an estimation
  DEFAULT_ZOOM_EMPTY_PROJECT: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformRequest(url: string): any;

  isSearchHereBtnDisplayed: (
    currentCoordinates: LngLat,
    previousCoordinates: LngLat,
    currentZoomLevel: number
  ) => boolean;
}

export class MapConfigProvider {
  private static readonly GEOSERVER_URL_IDENTIFIER = 'geoserver';
  private static readonly _config: MapConfig = {
    DEFAULT_CENTER: [10.18686541930605, 51.28272484557387],
    DEFAULT_ZOOM: 15,
    MAX_ZOOM: 20,
    MIN_ZOOM: 5,
    DEFAULT_ZOOM_EMPTY_PROJECT: 5,
    ESTIMATION_ZOOM: 12,
    transformRequest: (
      url: string
    ): { url: string; headers?: { [key: string]: string } } => {
      if (url.includes(MapConfigProvider.GEOSERVER_URL_IDENTIFIER)) {
        const token = sessionStorage.getItem('access_token');

        if (token) {
          return {
            url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        }
      }

      return {
        url,
      };
    },
    isSearchHereBtnDisplayed: (
      currentCoordinates: LngLat,
      previousCoordinates: LngLat,
      currentZoomLevel: number
    ): boolean => {
      const startPoint = turf.point([
        previousCoordinates.lng,
        previousCoordinates.lat,
      ]);
      const endPoint = turf.point([
        currentCoordinates.lng,
        currentCoordinates.lat,
      ]);
      const distanceInKM = turf.distance(startPoint, endPoint, {
        units: 'kilometers',
      });
      return distanceInKM > distanceInKMByZoom.get(currentZoomLevel)!;
    },
  };

  public static get config(): MapConfig {
    return this._config;
  }

  public static initLayerBehaviour(
    map: MapLibreMap,
    translateService: TranslateService
  ): void {
    this.initNetworkOperatorLayerBehaviour(map);
    this.initChargingStationsLayerBehaviour(map, translateService);
  }

  public static initZoomBehaviour(map: MapLibreMap): void {
    map.on('zoom', () => {
      const zoomLevel = map.getZoom();

      if (zoomLevel < this._config.ESTIMATION_ZOOM) {
        map.getCanvasContainer().style.cursor = '';
      } else {
        map.getCanvasContainer().style.cursor = 'pointer';
      }
    });
  }

  public static dsoHoverId: string | number | undefined;

  private static initNetworkOperatorLayerBehaviour(map: MapLibreMap): void {
    let popupVisible = false;
    const popup = new Popup({
      closeOnClick: false,
      closeButton: false,
    });

    map.on('click', LAYER_ID.NETWORK_OPERATORS_FILL, (e: MapMouseEvent) => {
      map.flyTo({
        center: e.lngLat,
        zoom: this._config.ESTIMATION_ZOOM + 0.1,
        speed: 2,
      });
    });

    map.on(
      'mousemove',
      LAYER_ID.NETWORK_OPERATORS_FILL,
      (e: MapLayerMouseEvent) => {
        if (!e.features) {
          return;
        }

        map.getCanvas().style.cursor = 'pointer';

        if (MapConfigProvider.shouldResetDSOPopup(e)) {
          map.setFeatureState(
            {
              source: LAYER_NAME.NETWORK_OPERATORS,

              sourceLayer: 'distribution_service_operators',
              id: MapConfigProvider.dsoHoverId,
            },
            { hover: false }
          );

          popupVisible = false;
        }

        MapConfigProvider.dsoHoverId = e.features[0].id;

        map.setFeatureState(
          {
            source: LAYER_NAME.NETWORK_OPERATORS,
            sourceLayer: 'distribution_service_operators',

            id: MapConfigProvider.dsoHoverId,
          },
          { hover: true }
        );

        if (!popupVisible && e.features[0].properties) {
          const bbox = turf.bbox(e.features[0]);
          const top = turf.point([(bbox[0] + bbox[2]) / 2, bbox[3]]);

          popup
            .setLngLat({
              lng: top.geometry.coordinates[0],
              lat: top.geometry.coordinates[1],
            })
            .setHTML(`<p>${e.features[0].properties['name']}</p>`)
            .addTo(map);

          popupVisible = true;
        }
      }
    );

    map.on('mouseleave', LAYER_ID.NETWORK_OPERATORS_FILL, () => {
      map.getCanvas().style.cursor = '';
      if (MapConfigProvider.dsoHoverId !== undefined) {
        map.setFeatureState(
          {
            source: LAYER_NAME.NETWORK_OPERATORS,
            sourceLayer: 'distribution_service_operators',
            id: MapConfigProvider.dsoHoverId,
          },
          { hover: false }
        );

        MapConfigProvider.dsoHoverId = undefined;
      }

      if (popupVisible) {
        popup.remove();
        popupVisible = false;
      }
    });
  }

  private static initChargingStationsLayerBehaviour(
    map: MapLibreMap,
    translateService: TranslateService
  ): void {
    let popupVisible = false;
    const popup = new Popup({
      closeOnClick: false,
      closeButton: false,
    });

    map.on('mousemove', LAYER_ID.CHARGING_STATIONS, (e: EventData) => {
      if (MapConfigProvider.dsoHoverId) {
        return;
      }

      if (!popupVisible) {
        const operator = translateService.instant(
          'MAP.LAYERS.CHARGING_STATIONS.POPUP.OPERATOR'
        );
        const power = translateService.instant(
          'MAP.LAYERS.CHARGING_STATIONS.POPUP.POWER'
        );
        const powerValue = translateService.instant(
          `MAP.LAYERS.CHARGING_STATIONS.POPUP.POWER_VALUE`,
          { power: e['features'][0].properties.max_kw }
        );

        popup
          .setLngLat({
            lng: e['features'][0].geometry.coordinates[0],
            lat: e['features'][0].geometry.coordinates[1],
          })
          .setHTML(
            `<p><strong>${operator}: </strong>${e['features'][0].properties.operator}</p><p><strong>${power}: </strong>${powerValue}</p>`
          )
          .addTo(map);

        popupVisible = true;
      }
    });

    map.on('mouseleave', LAYER_ID.CHARGING_STATIONS, () => {
      if (popupVisible) {
        popup.remove();
        popupVisible = false;
      }
    });
  }

  private static shouldResetDSOPopup(e: EventData): boolean {
    const hasExistingHoverId = MapConfigProvider.dsoHoverId !== undefined;
    const isDifferentHoverId =
      MapConfigProvider.dsoHoverId !== e['features'][0].id;

    return hasExistingHoverId && isDifferentHoverId;
  }
}

const distanceInKMByZoom: Map<number, number> = new Map([
  [5, 30],
  [6, 30],
  [7, 30],
  [8, 5],
  [9, 5],
  [10, 5],
  [11, 1],
  [12, 1],
  [13, 1],
  [14, 1],
  [15, 1],
  [16, 1],
  [17, 1],
  [18, 1],
  [19, 1],
  [20, 1],
  [21, 1],
  [22, 1],
]);
