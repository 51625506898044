import { COLUMN_IDENTIFIER } from '@app/location-table-page/enum';
import { FilterFormValue } from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components';
import { ApplicableCondition } from '@app/location-table-page';

export class FilterFormValueMapper {
  public static map(applicableCondition: ApplicableCondition): FilterFormValue {
    return {
      condition: {
        conditionType: applicableCondition.conditionType,
        labelTranslationIdentifier:
          applicableCondition.conditionTypeTranslationIdentifier,
      },
      criterion: {
        columnIdentifier: applicableCondition.criterion as COLUMN_IDENTIFIER,
        labelTranslationIdentifier:
          applicableCondition.criterionTranslationIdentifier,
        isDisplayed: true,
        isFilterable: true,
      },
      value: applicableCondition.value,
    };
  }
}
