<div>
  <mat-form-field color="accent">
    <mat-label>{{ filter.labelTranslationIdentifier | translate }}</mat-label>
    <input
      matInput
      [formControlName]="filter.type + '_' + filter.key"
      tabindex="-1"
      type="text" />
  </mat-form-field>
</div>
