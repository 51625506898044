import { CONDITION_TYPE } from '@app/location-table-page/enum';
import { FilterFormValue } from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components';
import { ApplicableCondition } from '@app/location-table-page';

export class ApplicableConditionMapper {
  private static readonly filterFunctionByConditionType = new Map<
    CONDITION_TYPE,
    (locationValue: number, formValue: number) => boolean
  >([
    [
      CONDITION_TYPE.GREATER_THAN,
      (locationValue: number, formValue: number): boolean => {
        return locationValue > formValue;
      },
    ],
    [
      CONDITION_TYPE.GREATER_THAN_OR_EQUAL,
      (locationValue: number, formValue: number): boolean => {
        return locationValue >= formValue;
      },
    ],
    [
      CONDITION_TYPE.LESS_THAN,
      (locationValue: number, formValue: number): boolean => {
        return locationValue < formValue;
      },
    ],
    [
      CONDITION_TYPE.LESS_THAN_OR_EQUAL,
      (locationValue: number, formValue: number): boolean => {
        return locationValue <= formValue;
      },
    ],
  ]);

  public static mapFilterFormValue(
    source: FilterFormValue
  ): ApplicableCondition {
    const filterFunction =
      ApplicableConditionMapper.filterFunctionByConditionType.get(
        source.condition.conditionType
      );

    if (filterFunction) {
      return new ApplicableCondition(
        source.condition.conditionType,
        source.condition.labelTranslationIdentifier,
        source.value,
        source.criterion.columnIdentifier,
        source.criterion.labelTranslationIdentifier,
        filterFunction
      );
    }
    throw new Error(
      `Unsupported conditionType ${source.condition.conditionType}`
    );
  }
}
