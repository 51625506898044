<div class="container" [formGroupName]="filter.type + '_' + filter.key">
  <span>{{ filter.labelTranslationIdentifier | translate }}</span>
  <mat-slider
    color="accent"
    class="slider"
    [min]="filter.min"
    [max]="filter.max"
    [step]="filter.step"
    discrete>
    <input matSliderStartThumb formControlName="min" />
    <input matSliderEndThumb formControlName="max" />
  </mat-slider>
</div>
