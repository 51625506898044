<form [formGroup]="spotSelection">
  <mat-selection-list
    *ngIf="spots.length > 0; else emptySpotsFoundOptions"
    [formControlName]="SPOT_SELECTION_CONTROL_NAME"
    [multiple]="false"
    (selectionChange)="selectionChange($event)"
    hideSingleSelectionIndicator="true"
    class="spots-found-list">
    <ng-container
      *ngFor="
        let spot of spots;
        let index = index;
        let last = last;
        let first = first
      ">
      <mat-divider *ngIf="first"></mat-divider>
      <mat-list-option
        [value]="spot"
        [attr.data-testid]="'mat-option-' + index"
        (mouseenter)="onMouseEnter(spot)"
        (mouseleave)="onMouseLeave()">
        <app-spots-list-element
          [showPrefix]="showPrefixes"
          [prefixColor]="index | rankingColor: spot"
          [showSuffix]="showSuffixes"
          [suffixText]="spot | suffixText: (rankBy$ | async)"
          [shortSuffix]="!!(rankBy$ | async)">
          <ng-container *ngIf="spot.address?.road; else emptyAddress"
            >{{ spot.address?.road }}
          </ng-container>
        </app-spots-list-element>

        <ng-template #emptyAddress>
          {{ 'COMMON.NO_ADDRESS_INFO' | translate }}
        </ng-template>
      </mat-list-option>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </mat-selection-list>
  <ng-template #emptySpotsFoundOptions>
    <div class="list__element" *ngIf="!isLoading(); else loading">
      {{ 'SIDEBAR.NO_SPOTS_FOUND' | translate }}
    </div>
  </ng-template>
  <ng-template #loading>
    <app-spots-skeleton-list *ngIf="isLoading()" />
  </ng-template>
</form>
