import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  RANGE_LABEL_FORMAT,
  RangeChartConfigProvider,
} from './range-chart-config-provider';
import { TOOLTIP } from '@app/shared/util';
import { Interval, TooltipPipe, TooltipShowDelayPipe } from '@app/shared';
import {
  matTooltipAnimations,
  MatTooltipModule,
  TooltipPosition,
} from '@angular/material/tooltip';

@Component({
  selector: 'app-range-chart',
  standalone: true,
  imports: [
    CommonModule,
    NgxEchartsDirective,
    TranslateModule,
    NgIf,
    TooltipPipe,
    MatTooltipModule,
    TooltipShowDelayPipe,
  ],
  templateUrl: './range-chart.component.html',
  styleUrls: ['./range-chart.component.scss'],
  providers: [provideEcharts()],
})
export class RangeChartComponent implements OnChanges {
  @Input()
  public labelTranslationIdentifier?: string;

  @Input()
  public interval?: Interval;

  @Input()
  public format: RANGE_LABEL_FORMAT = RANGE_LABEL_FORMAT.CURRENCY;

  @Input() public tooltip?: string;
  @Input() public tooltipPosition: TooltipPosition = 'above';
  @Input() public tooltipShowDelay = 0;

  public chartOptions: EChartsOption | null = null;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['interval']) {
      this.chartOptions = this.generateChartOptions();
    }
  }

  public generateChartOptions(): EChartsOption | null {
    if (!this.interval) {
      return null;
    }

    return RangeChartConfigProvider.generateConfig(this.interval, this.format);
  }

  protected readonly TOOLTIP = TOOLTIP;
  protected readonly matTooltipAnimations = matTooltipAnimations;
}
