<ng-container *ngIf="layer">
  <div [formGroup]="layerForm">
    <div class="layer-element" [ngClass]="{ 'child-element': isGroupChild }">
      <div class="icon-text">
        <mat-icon
          class="footer-icon"
          *ngIf="!layer.clusterIcon"
          fontSet="material-symbols-outlined"
          >{{ layer.icon }}
        </mat-icon>
        <div
          class="cluster-icon"
          *ngIf="layer.clusterIcon"
          [style.background-color]="layer.color">
          <img [src]="layer.clusterIcon" alt="" />
        </div>
        {{ layer.labelTranslationIdentifier | translate }}
      </div>
      <mat-slide-toggle
        class="small"
        [attr.data-testid]="layer.name + '_toggle'"
        [formControlName]="layer.name"></mat-slide-toggle>
    </div>

    <app-filters
      *ngIf="filters && filters.length > 0"
      [filters]="filters"
      [parentForm]="layerForm"></app-filters>
  </div>
</ng-container>
