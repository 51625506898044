<div class="network-operator" *ngIf="networkOperator">
  <p class="network-operator__text">
    {{ 'COMMON.NETWORK_OPERATOR' | translate }}
  </p>

  <ul class="network-operator__list">
    <li class="network-operator__list-element" *ngIf="networkOperator.name">
      {{ networkOperator.name }}
    </li>
    <li
      class="network-operator__list-element"
      *ngIf="networkOperator.postalCode || networkOperator.city">
      {{ networkOperator.postalCode }} {{ networkOperator.city }}
    </li>
    <li class="network-operator__list-element" *ngIf="networkOperator.phone">
      <mat-icon fontSet="material-symbols-outlined">call</mat-icon>
      {{ networkOperator.phone }}
    </li>
    <li class="network-operator__list-element" *ngIf="networkOperator.email">
      <mat-icon fontSet="material-symbols-outlined">mail</mat-icon>
      <span class="network-operator__list-element__text">{{
        networkOperator.email
      }}</span>
    </li>
  </ul>
</div>
