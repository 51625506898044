<app-layer-group-header [groupName]="groupName" icon="save" />
<div [formGroup]="projectLayerForm" class="project-layer">
  @if (projectSummariesToDisplay.length > 0) {
    <ul class="project-layer-list">
      @for (projectSummary of projectSummaries(); track projectSummary.id) {
        <li
          [ngClass]="{
            'project-layer-element-scrollbar':
              projectSummariesToDisplay.length > AMOUNT_OF_LAYER_UNTIL_SCROLL,
            'project-layer-element':
              projectSummariesToDisplay.length <= AMOUNT_OF_LAYER_UNTIL_SCROLL
          }">
          <div class="element-content">
            <span>{{ projectSummary.name }}</span>
            <mat-slide-toggle
              [formControlName]="projectSummary.id"
              class="small" />
          </div>
        </li>
      }
    </ul>
  }
</div>
