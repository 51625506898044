<ng-container *ngIf="locationRows() && locationRows()!.length > 0; else noRows">
  <ng-container *ngIf="dataSource">
    <table
      [dataSource]="dataSource"
      aria-describedby="tableDescription"
      mat-table
      class="location-table"
      matSort>
      <ng-container *ngFor="let columnMeta of _columnMetaData">
        <ng-container
          *ngIf="columnMeta.isDisplayed"
          [matColumnDef]="columnMeta.columnIdentifier">
          @if (columnMeta.isSortable) {
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-capitalized"
              [attr.data-testid]="'th-' + columnMeta.columnIdentifier">
              {{ columnMeta.labelTranslationIdentifier | translate }}
            </th>
          } @else {
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-capitalized"
              [attr.data-testid]="'th-' + columnMeta.columnIdentifier">
              {{ columnMeta.labelTranslationIdentifier | translate }}
            </th>
          }

          <td mat-cell *matCellDef="let row">
            @switch (columnMeta.columnIdentifier) {
              @case (COLUMN_IDENTIFIER.CHARGING_STATION_CONFIGURATION) {
                <app-stations-row
                  [stations]="
                    row[COLUMN_IDENTIFIER.CHARGING_STATION_CONFIGURATION]
                  " />
              }
              @default {
                {{
                  row[columnMeta.columnIdentifier] | row: columnMeta | translate
                }}
              }
            }
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="matHeaderRowDef"></tr>
      <tr
        mat-row
        (click)="onRowClick(row)"
        *matRowDef="let row; columns: matHeaderRowDef"></tr>
    </table>
    <!-- paginator is required for export, if not present lib throws error -->
    <mat-paginator style="display: none"></mat-paginator>
  </ng-container>
</ng-container>
<ng-template #noRows>
  <p class="table-text--no-Rows">{{ 'TABLE_VIEW.NO_SPOTS' | translate }}</p>
</ng-template>
