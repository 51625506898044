import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-layer-group-header',
  standalone: true,
  imports: [MatIcon, TranslateModule],
  templateUrl: './layer-group-header.component.html',
  styleUrl: './layer-group-header.component.scss',
})
export class LayerGroupHeaderComponent {
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) groupName!: string;
}
