<div class="filter">
  <div class="filter__form">
    <form [formGroup]="form">
      <ng-container formArrayName="conditionsForm">
        <ng-container
          *ngFor="
            let filtersForm of conditionsForm.controls;
            let i = index;
            let first = first;
            let last = last
          ">
          <div class="filter__container">
            <div
              (click)="addNewForm(last)"
              class="filter__element"
              [formGroup]="filtersForm">
              <mat-form-field color="accent">
                <mat-label>{{ 'TABLE_VIEW.CRITERION' | translate }}</mat-label>
                <mat-select
                  [formControlName]="CRITERION_FORM_NAME"
                  tabindex="-1">
                  <mat-option
                    *ngFor="let criterion of _criteria"
                    [value]="criterion">
                    {{ criterion.labelTranslationIdentifier | translate }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    filtersForm?.get(CRITERION_FORM_NAME)?.errors?.['required']
                  ">
                  {{ 'COMMON.REQUIRED_FIELD' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field color="accent">
                <mat-label>{{ 'TABLE_VIEW.CONDITION' | translate }}</mat-label>
                <mat-select
                  [formControlName]="CONDITION_FORM_NAME"
                  tabindex="-1">
                  <mat-option
                    *ngFor="let condition of conditions"
                    [value]="condition">
                    {{ condition.labelTranslationIdentifier | translate }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    filtersForm?.get(CONDITION_FORM_NAME)?.errors?.['required']
                  ">
                  {{ 'COMMON.REQUIRED_FIELD' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field color="accent">
                <mat-label>{{ 'TABLE_VIEW.VALUE' | translate }}</mat-label>
                <input
                  [title]="
                    'SEARCH_CARD.AMOUNT_OF_CHARGING_STATIONS_TO_BUILD'
                      | translate
                  "
                  matInput
                  [formControlName]="VALUE_FORM_NAME"
                  type="number"
                  tabindex="-1" />
                <mat-error *ngIf="filtersForm?.get(VALUE_FORM_NAME)?.invalid">
                  <span
                    *ngIf="
                      filtersForm?.get(VALUE_FORM_NAME)?.errors?.['required']
                    ">
                    {{ 'COMMON.REQUIRED_FIELD' | translate }}
                  </span>
                  <span
                    *ngIf="filtersForm?.get(VALUE_FORM_NAME)?.errors?.['min']">
                    {{
                      'SEARCH_CARD.MIN_LENGTH_ERROR'
                        | translate
                          : {
                              minLength:
                                filtersForm?.get(VALUE_FORM_NAME)?.errors?.[
                                  'min'
                                ].min
                            }
                    }}
                  </span>
                  <span
                    *ngIf="filtersForm?.get(VALUE_FORM_NAME)?.errors?.['max']">
                    {{
                      'SEARCH_CARD.MAX_LENGTH_ERROR'
                        | translate
                          : {
                              maxLength:
                                filtersForm?.get(VALUE_FORM_NAME)?.errors?.[
                                  'max'
                                ].max
                            }
                    }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="filter__action">
              <button
                *ngIf="!first"
                (click)="deleteFilter(i)"
                tabindex="-1"
                mat-icon-button>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
    <div
      [ngClass]="{
        'filter__message--hidden': !showFilterResult,
        'filter__message--visible': showFilterResult
      }">
      <p>
        {{
          'TABLE_VIEW.FILTER_RESULTS'
            | translate
              : {
                  filterResultsLength:
                    (locationRowService.lengthOfFilterResult$ | async),
                  locationsLength: amountOfOriginRows
                }
        }}
      </p>
    </div>
  </div>
</div>
