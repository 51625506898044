import { EChartsOption, SeriesOption } from 'echarts';
import { BarSeriesLabelOption } from 'echarts/types/src/chart/bar/BarSeries';
import { CurrencyFormatter } from '@app/map/components/spot-information/util';
import { Interval, kWhFormatter, meterFormatter } from '@app/shared';
import { ChartConfigProvider } from '@app/map';

export enum RANGE_COLOR {
  PRIMARY = '#39393A',
  ACCENT = '#e0e0e0',
  LABEL_PRIMARY = '#000',
  LABEL_ACCENT = '#727475',
}

export enum RANGE_LABEL_FORMAT {
  UNFORMATTED = 'unformatted',
  KWH = 'kwh',
  METER = 'meter',
  CURRENCY = 'currency',
}

export class RangeChartConfigProvider {
  private static standardFormatter(value: number): string {
    return value.toString();
  }

  private static customFormatter(
    format: RANGE_LABEL_FORMAT
  ): (options: number) => string {
    if (format === RANGE_LABEL_FORMAT.CURRENCY) {
      return CurrencyFormatter.formatCurrencyShort;
    } else if (format === RANGE_LABEL_FORMAT.METER) {
      return meterFormatter;
    } else if (format === RANGE_LABEL_FORMAT.KWH) {
      return kWhFormatter;
    }

    return this.standardFormatter;
  }

  public static generateConfig(
    interval: Interval,
    format: RANGE_LABEL_FORMAT
  ): EChartsOption {
    const formatter = RangeChartConfigProvider.customFormatter(format);

    return {
      animation: false,
      legend: { show: false },
      grid: {
        left: 60,
        bottom: 0,
        right: 60,
        top: 30,
        height: 10,
      },
      textStyle: ChartConfigProvider.TEXT_STYLE,
      xAxis: {
        type: 'value',
        max: interval.maxBoundary - interval.minBoundary,
        show: false,
      },
      yAxis: {
        type: 'category',
        data: [''],
        show: false,
      },
      series: [
        RangeChartConfigProvider.generateSeries(
          interval.min - interval.minBoundary,
          RANGE_COLOR.ACCENT,
          {
            show: true,
            position: 'left',
            offset: [-5, 0],
            color: RANGE_COLOR.LABEL_ACCENT,
            formatter: () => formatter(interval.minBoundary),
          }
        ),
        RangeChartConfigProvider.generateSeries(
          interval.avg - interval.min,
          RANGE_COLOR.PRIMARY,
          {
            show: true,
            position: 'insideBottomLeft',
            offset: [-5, 25],
            color: '#727475',
            formatter: () => formatter(interval.min),
          }
        ),
        RangeChartConfigProvider.generateSeries(
          0,
          RANGE_COLOR.PRIMARY,
          {
            show: true,
            align: 'center',
            offset: [0, -25],
            color: RANGE_COLOR.LABEL_PRIMARY,
            formatter:
              format === RANGE_LABEL_FORMAT.CURRENCY
                ? (): string => CurrencyFormatter.formatCurrency(interval.avg)
                : (): string => formatter(interval.avg),
          },
          true
        ),
        RangeChartConfigProvider.generateSeries(
          interval.max - interval.avg,
          RANGE_COLOR.PRIMARY,
          {
            show: true,
            position: 'insideBottomRight',
            align: 'center',
            offset: [5, 25],
            color: RANGE_COLOR.LABEL_ACCENT,
            formatter: () => formatter(interval.max),
          },
          false
        ),
        RangeChartConfigProvider.generateSeries(
          interval.maxBoundary - interval.max,
          RANGE_COLOR.ACCENT,
          {
            show: true,
            position: 'right',
            offset: [5, 0],
            color: RANGE_COLOR.LABEL_ACCENT,
            formatter: () => formatter(interval.maxBoundary),
          }
        ),
      ],
    };
  }

  private static generateSeries(
    value: number,
    color: string,
    label: BarSeriesLabelOption | undefined,
    showLabelLine = false
  ): SeriesOption {
    return {
      silent: true,
      type: 'bar',
      stack: 'total',
      barGap: 0,
      label,
      labelLine: {
        show: showLabelLine,
      },
      itemStyle: {
        color,
        borderWidth: 1,
        borderColor: color,
      },
      emphasis: {
        focus: 'series',
      },
      data: [value],
    };
  }
}
