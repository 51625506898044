import { Component, EventEmitter, Output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { MaplibreControlBtnComponent } from '@app/map/components/maplibre-control-btn/maplibre-control-btn.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-search-here-btn',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    NgxMapLibreGLModule,
    MaplibreControlBtnComponent,
    MatIconModule,
  ],
  templateUrl: './search-here-btn.component.html',
  styleUrls: ['./search-here-btn.component.scss'],
})
export class SearchHereBtnComponent {
  readonly position = 'top-left';
  @Output() public btnClicked = new EventEmitter<boolean>();
  public isHidden = input.required<boolean>();

  public onBtnClick(): void {
    this.btnClicked.emit(true);
  }
}
