import { ExpressionSpecification, FilterSpecification } from 'maplibre-gl';
import { LAYER_FILTER_TYPE, LayerFilterType } from '@app/map';

export class LayerFilterGenerator {
  public static generate(
    filters: Map<string, LayerFilterType>
  ): FilterSpecification | undefined {
    if (filters.size === 0) {
      return undefined;
    }

    const filterSpec: ExpressionSpecification = ['all'];

    filters.forEach((filter: LayerFilterType) => {
      if (filter.type === LAYER_FILTER_TYPE.SLIDER) {
        const { min, max, key: fieldKey } = filter;

        if (min !== undefined) {
          const minFilter: ExpressionSpecification = [
            '>=',
            ['get', fieldKey],
            min,
          ];

          filterSpec.push(minFilter);
        }

        if (max !== undefined) {
          const maxFilter: ExpressionSpecification = [
            '<=',
            ['get', fieldKey],
            max,
          ];

          filterSpec.push(maxFilter);
        }
      } else if (filter.type === LAYER_FILTER_TYPE.TEXT) {
        const { value, key: fieldKey } = filter;

        if (value !== undefined && value.trim() !== '') {
          const containsFilter: ExpressionSpecification = [
            'in',
            value.trim().toLowerCase(),
            ['downcase', ['get', fieldKey]],
          ];

          filterSpec.push(containsFilter);
        }
      }
    });

    return filterSpec;
  }
}
