import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ApplicableCondition } from '@app/location-table-page/applicable-condtion';
import { ConditionMeta } from '@app/location-table-page/condition-provider';
import { LocationRowService } from '@app/location-table-page/location-row.service';
import { ColumnMetaData } from '@app/location-table-page/location-table-page.component';
import { DialogBase, DialogData, DialogHeaderComponent } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApplicableConditionMapper,
  FilterFormValueMapper,
} from '@app/location-table-page/util';
import {
  FilterFormComponent,
  FilterFormValue,
} from '@app/location-table-page/components/table-toolbar/components/create-filters-dialog/components';

export interface CreateFilterDialogData extends DialogData {
  columMetaDate: ColumnMetaData[];
  conditions: ConditionMeta[];
  appliedConditions: ApplicableCondition[];
}

export type CreateFilterDialogDataClose = { conditions: FilterFormValue[] };

@Component({
  selector: 'app-create-filters-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    TranslateModule,
    DialogHeaderComponent,
    FilterFormComponent,
  ],
  templateUrl: './create-filters-dialog.component.html',
  styleUrls: ['./create-filters-dialog.component.scss'],
})
export class CreateFiltersDialogComponent extends DialogBase {
  public currentConditions: ConditionMeta[] = [];

  #filterFormValue: FilterFormValue[] = [];
  #initialAppliedConditions: ApplicableCondition[] = [];
  readonly #locationRowService = inject(LocationRowService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public override data: CreateFilterDialogData,
    public override readonly dialogRef: MatDialogRef<CreateFiltersDialogComponent>
  ) {
    super(dialogRef, data);
    this.#initialAppliedConditions = data.appliedConditions;
  }

  public onConditionsChange($event: FilterFormValue[]): void {
    this.currentConditions = $event.map(value => value.condition);
    this.#filterFormValue = $event;
    const applicableConditions = $event.map(value =>
      ApplicableConditionMapper.mapFilterFormValue(value)
    );

    const rows = this.#locationRowService.originLocationRows;

    this.#locationRowService.applyFilters(rows, applicableConditions);
  }

  public closeIncludingChanges(): void {
    const data: CreateFilterDialogDataClose = {
      conditions: this.#filterFormValue,
    };

    this.dialogRef.close(data);
  }

  public closeExcludingChanges(): void {
    const data: CreateFilterDialogDataClose = {
      conditions: this.#initialAppliedConditions.map(condition =>
        FilterFormValueMapper.map(condition)
      ),
    };

    this.dialogRef.close(data);
  }
}
