import { Pipe, PipeTransform } from '@angular/core';
import { Range } from '@app/shared';
import { CurrencyFormatter } from '../util';

@Pipe({
  name: 'moneyRangeFormat',
  standalone: true,
})
export class MoneyRangeFormatPipe implements PipeTransform {
  public transform(range: Range, language = 'en'): string {
    return (
      CurrencyFormatter.formatCurrencyThousand(range.min, language) +
      ' - ' +
      CurrencyFormatter.formatCurrencyThousand(range.max, language)
    );
  }
}
