<div class="details">
  <p>
    {{ 'MAP.LOCATION_INFORMATION.CONTACT_DESCRIPTION' | translate }}
  </p>
  <div class="details__elements">
    <ng-container *ngIf="isLoading; else dataPresent">
      <div class="details__elements__spinner-container">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </ng-container>
    <ng-template #dataPresent>
      <ng-container
        *ngIf="
          networkOperators && networkOperators.length > 0;
          else noNetworkOperatorDataPresent
        ">
        <div
          *ngFor="let networkOperator of networkOperators"
          class="details__element">
          <app-network-operator-info
            [networkOperator]="networkOperator"></app-network-operator-info>
        </div>
      </ng-container>
      <ng-template #noNetworkOperatorDataPresent>
        <p>{{ 'MAP.LOCATION_INFORMATION.NO_NETWORK_OPERATOR' | translate }}</p>
      </ng-template>
    </ng-template>
  </div>
</div>
