import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-attribute-title',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatTooltipModule],
  templateUrl: './attribute-title.component.html',
  styleUrls: ['./attribute-title.component.scss'],
})
export class AttributeTitleComponent {
  @Input()
  public icon!: string;

  @Input()
  public titleTranslationIdentifier!: string;

  @Input()
  public subTitle?: string;

  @Input()
  public tooltipTranslationIdentifier?: string;

  @Input()
  public tooltipShowDelay = 0;

  @Input()
  public tooltipPosition: TooltipPosition = 'above';
}
