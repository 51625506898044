export enum COLUMN_IDENTIFIER {
  ID = 'id',
  ADDRESS = 'address',
  TOTAL_COST_ESTIMATION = 'totalCostEstimation',
  COST_ESTIMATION_SCORE_LOCAL = 'costEstimationScoreLocal',
  COST_ESTIMATION_SCORE = 'costEstimationScore',
  USAGE_ESTIMATION_SCORE = 'consumptionDayEstimationScore',
  USAGE_ESTIMATION_SCORE_LOCAL = 'consumptionDayEstimationScoreLocal',
  EV_DENSITY = 'evDensity',
  POPULATION_DENSITY = 'populationDensity',
  CHARGING_STATION_CONFIGURATION = 'stations',
  SESSION_DAY_ESTIMATION = 'sessionsDayEstimation',
  SESSIONS_YEAR_ESTIMATION = 'sessionsYearEstimation',
  CONSUMPTION_DAY_ESTIMATION = 'consumptionDayEstimation',
  CONSUMPTION_YEAR_ESTIMATION = 'consumptionYearEstimation',
  LONGITUDE = 'longitude',
  LATITUDE = 'latitude',
}
