<mat-expansion-panel expanded hideToggle>
  <app-attribute-title
    icon="payments"
    titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.EST_TOTAL_COST"
    [subTitle]="spot.totalCostEstimation | moneyRangeFormat"
    [tooltipTranslationIdentifier]="TT.TOTAL_COST_INFO | tooltip"
    [tooltipShowDelay]="TT.TOTAL_COST_INFO | tooltipShowDelay" />

  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.CABLING_COST"
    [numerator]="spot.gridConnectionCostEstimation | moneyRangeFormat"
    [tooltipTranslationIdentifier]="TT.CABLING_COST | tooltip"
    [tooltipShowDelay]="TT.CABLING_COST | tooltipShowDelay" />

  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.SUBSTATION_COST"
    [numerator]="spot.substationCostEstimation | moneyRangeFormat"
    [tooltipTranslationIdentifier]="TT.SUBSTATION_COST | tooltip"
    [tooltipShowDelay]="TT.SUBSTATION_COST | tooltipShowDelay" />

  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.GRID_INVESTMENT_COST"
    [numerator]="spot.gridInvestmentCostEstimation | moneyRangeFormat"
    [tooltipTranslationIdentifier]="TT.GRID_INVESTMENT_COST | tooltip"
    [tooltipShowDelay]="TT.GRID_INVESTMENT_COST | tooltipShowDelay" />
</mat-expansion-panel>

<mat-expansion-panel expanded hideToggle class="cabling">
  <app-attribute-title
    icon="cable"
    titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.DISTANCE_ESTIMATION_GRID" />

  <app-range-chart
    [interval]="spot.distanceToGridEstimation | roundInterval: 0"
    [format]="LABEL_FORMAT.METER" />

  <app-range-chart
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.CABLING_COST"
    [interval]="spot.gridConnectionCostEstimation | roundInterval: 0"
    [format]="LABEL_FORMAT.CURRENCY" />
</mat-expansion-panel>

<div class="disclaimer">
  @if (isFreeUser()) {
    <app-message
      icon="warning"
      [spacing]="true"
      [responsive]="false"
      labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.DISCLAIMER_PUBLIC" />
  } @else {
    <app-message
      icon="warning"
      [spacing]="true"
      [responsive]="false"
      labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.DISCLAIMER" />
  }
</div>
