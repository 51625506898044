import { LAYER_GROUP } from '@app/map/enums';

interface LayerGroupInfo {
  groupName: string;
  groupIcon: string;
}

export const LAYER_GROUP_INFO: Record<LAYER_GROUP, LayerGroupInfo> = {
  [LAYER_GROUP.ENERGY_GRID]: {
    groupName: 'MAP.LAYERS.ENERGY_GRID.GROUP',
    groupIcon: 'grid_on',
  },
  [LAYER_GROUP.LANDUSE]: {
    groupName: 'MAP.LAYERS.LANDUSE.GROUP',
    groupIcon: 'crop_landscape',
  },
  [LAYER_GROUP.POINTS_OF_INTEREST]: {
    groupName: 'MAP.LAYERS.POINTS_OF_INTEREST.GROUP',
    groupIcon: 'pin_drop',
  },
};
