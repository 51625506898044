import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ColumnMetaData } from '@app/location-table-page/location-table-page.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-show-columns-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './show-columns-selector.component.html',
  styleUrls: ['./show-columns-selector.component.scss'],
})
export class ShowColumnsSelectorComponent {
  protected readonly FORM_NAME = 'checkboxSelected';
  public _checkboxes?: ColumnMetaData[] = [];
  @Input()
  public set checkboxes(columnMetaData: ColumnMetaData[]) {
    this._checkboxes = columnMetaData;
    this.initForm(columnMetaData?.filter(colum => colum.isDisplayed));
  }

  @Output() public selectionChanged = new EventEmitter<ColumnMetaData[]>();
  protected form!: FormGroup;
  readonly #fb = inject(FormBuilder);

  private initForm(columnMetaData: ColumnMetaData[]): void {
    this.form = this.#fb.group({
      [this.FORM_NAME]: [columnMetaData, Validators.required],
    });
  }

  protected emitSelectionChange($event: MatSelectChange): void {
    const columIdentifierSet = new Set(
      $event.value.map(
        (columnMetaData: ColumnMetaData) => columnMetaData.columnIdentifier
      )
    );

    const adjustedCheckboxes = this._checkboxes?.map(columMeta => ({
      ...columMeta,
      isDisplayed: columIdentifierSet.has(columMeta.columnIdentifier),
    }));

    this.selectionChanged.emit(adjustedCheckboxes);
  }
}
