import { Pipe, PipeTransform } from '@angular/core';
import { Spot } from '@app/shared';
import { round } from 'lodash';
import { RankBy } from '@app/map/components/sidebar/components/spots-found/components';

@Pipe({
  name: 'suffixText',
  standalone: true,
})
export class SuffixTextPipe implements PipeTransform {
  private static readonly PRECISION = 1;

  public transform(value: Spot, rankBy: RankBy | undefined | null): string {
    let scoreValue: number;

    switch (rankBy) {
      case RankBy.COST_ESTIMATION_SCORE_LOCAL:
        scoreValue = value.costEstimationScoreLocal;
        break;
      case RankBy.CONSUMPTION_DAY_ESTIMATION_SCORE_LOCAL:
        scoreValue = value.consumptionDayEstimationScoreLocal;
        break;
      default:
        return `${round(
          value.costEstimationScoreLocal,
          SuffixTextPipe.PRECISION
        )}/${round(
          value.consumptionDayEstimationScoreLocal,
          SuffixTextPipe.PRECISION
        )}`;
    }

    return `${round(scoreValue, SuffixTextPipe.PRECISION)}`;
  }
}
