import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-layer-zoom-info-message',
  standalone: true,
  imports: [NgxMapLibreGLModule, MatIconModule, TranslateModule],
  templateUrl: './layer-zoom-info-message.component.html',
  styleUrl: './layer-zoom-info-message.component.scss',
})
export class LayerZoomInfoMessageComponent {}
