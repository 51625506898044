<app-dialog-header (closeButtonClicked)="close()">
  {{ 'TABLE_VIEW.SPOT_DETAILS' | translate }}
</app-dialog-header>
<div class="spot-information-dialog-container">
  <mat-dialog-content>
    <app-spot-information
      [spot]="spot"
      [isFullWidth]="true"
      (deleteLocationClick)="onDeleteLocationClick()"
      (stationsChanged)="onStationChange($event)">
    </app-spot-information>
  </mat-dialog-content>
</div>
