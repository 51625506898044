<div class="map-container">
  <app-sidebar
    (layerStageChanged)="onLayerStageChange($event)"
    (projectLayerStageChanged)="onProjectLayerStageChange($event)"
    (searchFormSubmitClick)="onSearchFormSubmitClick()"
    (spotOnHover)="spotOnHover($event)"
    (spotsFoundSelectionChange)="spotsFoundSelectionChange($event)"
    [expandSearchPanel]="expandSearchPanel"
    [lastSubmittedSearch$]="lastSubmittedSearch$"
    [layers]="geoserverLayers"
    [project]="project()"
    [projectSummaries]="filteredProjectSummaries() ?? []"
    [selectedSpot]="selectedSpot"
    [showSearchPanel]="showSearchPanel"
    [spotsFoundOptions]="spotsFoundOptions()"
    [freeToPlay]="freeToPlay"
    [showSpotsFound]="
      (lastSubmittedSearch$ | async) !== null || project() !== undefined
    " />

  <section>
    <div
      class="map-overlay"
      [ngClass]="{
        'map-overlay-hidden':
          isSearchHereBtnHidden() || (lastSubmittedSearch$ | async) === null
      }">
      <mat-icon class="icon" fontSet="material-symbols-outlined">
        add
      </mat-icon>
    </div>

    <mgl-map
      (mapClick)="onMapClick($event)"
      *ngIf="showMap() && style"
      [style]="isSatelliteView ? INITIAL_STYLE_SPECIFICATION : style"
      [zoom]="[zoom()]"
      [center]="center()"
      [dragRotate]="false"
      [fitBounds]="fitBounds()"
      [maxZoom]="MAP_CONFIG.MAX_ZOOM"
      [minZoom]="MAP_CONFIG.MIN_ZOOM"
      [pitchWithRotate]="false"
      [touchZoomRotate]="false"
      [transformRequest]="MAP_CONFIG.transformRequest"
      (mapLoad)="initMap($event)"
      (mapDragEnd)="onMapDragEnd()"
      class="map"
      movingMethod="jumpTo"
      data-testid="map">
      <mgl-image
        id="sustenance"
        url="../../../assets/map-images/sustenance.png" />
      <mgl-image id="shops" url="../../../assets/map-images/shops.png" />
      <mgl-image
        id="charging_stations"
        url="../../../assets/map-images/charging_stations.png" />
      <mgl-image
        id="charging_stations_cluster"
        url="../../../assets/map-images/charging_stations_cluster.png" />
      <mgl-image
        id="shops_cluster"
        url="../../../assets/map-images/shops_cluster.png" />
      <mgl-image
        id="sustenance_cluster"
        url="../../../assets/map-images/sustenance_cluster.png" />

      <!--
      Bug: https://jira.eon.com/browse/ONETP-693
      This layer will be rendered as the first layer (the layer itself does not show anything)
      It is used to draw the satellite layer underneath to fix the bug that layers are not visible when isSatelliteView
      -->
      <app-empty-layer />

      <ng-container *ngIf="isSatelliteView">
        <mgl-raster-source
          [id]="LAYER_NAME.SATELLITE"
          [url]="satelliteTilesUrl" />
        <mgl-layer
          [before]="LAYER_NAME.EMPTY"
          [id]="LAYER_NAME.SATELLITE"
          [source]="LAYER_NAME.SATELLITE"
          type="raster">
        </mgl-layer>
      </ng-container>

      @for (
        project of activeProjectsLayersById() | keyvalue;
        track project.key
      ) {
        <app-project-location-marker-group
          [map]="map"
          [project]="project.value"
          (clicked)="onLocationClicked($event)" />
      }

      <app-location-marker-group
        (clicked)="onLocationClicked($event)"
        *ngIf="map && recommendations() && recommendations().length > 0"
        [cluster]="true"
        [isLoading$]="isLoading$"
        [map]="map"
        [selectedSpot$]="selectedSpot$"
        [selectedSpot]="selectedSpot"
        [spotsWithSizeBig$]="spotsWithSizeBig$"
        [spots]="recommendations()"
        id="recommendation-markers" />

      <app-location-marker-group
        (clicked)="onLocationClicked($event)"
        *ngIf="locations()"
        [isLoading$]="isLoading$"
        [map]="map"
        [selectedSpot$]="selectedSpot$"
        [selectedSpot]="selectedSpot"
        [spotsWithSizeBig]="spotsWithSizeBig"
        [spots]="locations()"
        id="location-markers" />

      <mgl-marker
        *ngIf="temporaryRecommendationMarker"
        [lngLat]="temporaryRecommendationMarker"
        [offset]="[0, -20]">
        <app-marker
          size="big"
          data-testid="temporaryRecommendationMarker"
          [color]="MARKER_COLOR.BLUE"
          [loading$]="isLoading$"
          size="big" />
      </mgl-marker>

      <mgl-control [showCompass]="false" mglNavigation></mgl-control>

      <ng-container *ngFor="let layer of geoserverLayers">
        <div *ngIf="layer.isActive">
          <app-poi-group
            *ngIf="
              layer.sourceSpecification.type ===
                SOURCE_SPECIFICATION_TYPE.GEOJSON &&
              clusterBounds.value !== undefined
            "
            [clusterBounds]="clusterBounds"
            [cluster]="layer.cluster"
            [color]="layer.color"
            [icon]="layer.clusterIcon"
            [id]="layer.name"
            [map]="map"
            dataSource="{{ layer.sourceSpecification.data }}" />

          <mgl-raster-source
            *ngIf="
              layer.sourceSpecification.type ===
              SOURCE_SPECIFICATION_TYPE.RASTER
            "
            [id]="layer.name"
            [scheme]="layer.sourceSpecification.scheme"
            [tiles]="layer.sourceSpecification.tiles" />
          <mgl-vector-source
            *ngIf="
              layer.sourceSpecification.type ===
              SOURCE_SPECIFICATION_TYPE.VECTOR
            "
            [id]="layer.name"
            [promoteId]="layer.sourceSpecification.promoteId"
            [scheme]="layer.sourceSpecification.scheme"
            [tiles]="
              layer.sourceSpecification.tiles | radiusRestrictionQuery
            " />

          <mgl-layer
            *ngFor="let layerSpecification of layer.layerSpecifications"
            [filter]="
              layerSpecification.filter
                ? layerSpecification.filter
                : geoserverLayerFilters.get(layerSpecification.id)
            "
            [id]="layerSpecification.id"
            [layout]="layerSpecification.layout"
            [maxzoom]="layerSpecification.maxzoom"
            [minzoom]="layerSpecification.minzoom"
            [paint]="layerSpecification.paint"
            [sourceLayer]="layerSpecification['source-layer']"
            [source]="layerSpecification.source"
            [type]="layerSpecification.type" />
        </div>
      </ng-container>

      <app-radius-restriction-layer />
      <app-regions-restriction-layer />
      <app-search-radius-layer
        [locationRecommendationRequest]="locationRecommendationRequest()" />

      @if (!this.freeToPlay) {
        <mgl-control>
          <app-satellite-layer-toggle
            (clicked)="onSatelliteControlClick($event)" />
        </mgl-control>
      }

      <mgl-control
        [positionOptions]="{
          enableHighAccuracy: true
        }"
        mglGeolocate />

      <app-search-here-btn
        (btnClicked)="onSearchHereBtnClick()"
        [isHidden]="
          isSearchHereBtnHidden() || (lastSubmittedSearch$ | async) === null
        ">
        {{ 'MAP.SEARCH_THIS_AREA' | translate }}
      </app-search-here-btn>

      @if (zoomLevel()! < 10 && isClusterLayerActivated()) {
        <app-layer-zoom-info-message />
      }
    </mgl-map>

    <div class="location-popup">
      <app-spot-information
        [freeToPlay]="freeToPlay"
        data-testid="spot-information"
        *ngIf="selectedSpot && isSpotInformationVisible"
        [spot]="selectedSpot"
        [featureConfig]="spotInformationFeatureConfig"
        (deleteLocationClick)="deleteLocation($event)"
        (saveLocationClick)="saveRecommendation($event)"
        [featureConfig]="spotInformationFeatureConfig"
        [spot]="selectedSpot" />
    </div>
  </section>
</div>
