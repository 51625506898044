import { AsyncPipe, CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  APP_ROUTES,
  LinkButtonComponent,
  ProjectDto,
  SearchCardComponent,
  SearchFormValue,
  Spot,
  TOOLTIP,
  TooltipPipe,
  TooltipShowDelayPipe,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  LayerPanelComponent,
  LayerSelectionState,
  SpotsFoundComponent,
} from '@app/map/components/sidebar/components';
import { SpotOnHoverEvent } from '@app/map/components/sidebar/components/spots-found';
import { FiltersComponent } from '@app/map/components/sidebar/components/layer-panel/components/layer/components';
import { LayerWrapper } from '@app/map';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { ProjectSummary } from '@app/map/components/sidebar/components/layer-panel/components/project-layer-group/project-summary';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  standalone: true,
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    CommonModule,
    MatSlideToggleModule,
    TranslateModule,
    MatExpansionModule,
    LayerPanelComponent,
    MatIconModule,
    SpotsFoundComponent,
    LinkButtonComponent,
    FiltersComponent,
    SearchCardComponent,
    MatTooltipModule,
    TooltipPipe,
    TooltipShowDelayPipe,
  ],
})
export class SidebarComponent {
  @Input() public layers: LayerWrapper[] | undefined;

  @Input() public spotsFoundOptions!: Spot[];

  @Input() public selectedSpot?: Spot;
  @Input() public project?: ProjectDto;

  @Input() public lastSubmittedSearch$?: Observable<SearchFormValue | null>;
  @Input() public showSearchPanel = false;
  @Input() public showLayerPanel = true;
  @Input() public showSpotsFound = true;
  @Input() public freeToPlay = false;

  @Input() public expandSearchPanel = false;
  public projectSummaries = input<ProjectSummary[] | null>(null);

  @Output() public layerStageChanged = new EventEmitter<LayerSelectionState>();
  @Output() public projectLayerStageChanged = new EventEmitter<string[]>();

  @Output() public spotsFoundSelectionChange = new EventEmitter<Spot>();

  @Output() public spotOnHover = new EventEmitter<SpotOnHoverEvent>();

  @Output() public searchFormSubmitClick = new EventEmitter<SearchFormValue>();

  public formGroup: FormGroup;

  public readonly APP_ROUTES = APP_ROUTES;
  public readonly LIMIT_AMOUNT_OF_SPOTS = 100;
  public readonly TT = TOOLTIP;
  readonly #fb = inject(FormBuilder);

  constructor() {
    this.formGroup = this.#fb.group({});
  }

  public emitSpotsFoundSelectionChange($event: Spot): void {
    this.spotsFoundSelectionChange.emit($event);
  }

  public emitSpotOnHover($event: SpotOnHoverEvent): void {
    this.spotOnHover.emit($event);
  }

  public onSubmitButtonClick(): void {
    this.searchFormSubmitClick.emit();
  }

  public onLayerStateChanged($event: LayerSelectionState): void {
    this.layerStageChanged.emit($event);
  }

  public onProjectLayerChanged($event: string[]) {
    this.projectLayerStageChanged.emit($event);
  }
}
