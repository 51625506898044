import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent, LocationDto } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { SpotInformationComponent } from '@app/map';

export type SpotInformationDialogData = {
  spot: LocationDto;
};

export type SpotInformationDialogOutputData = {
  shouldBeDeleted: boolean;
  hasStationChanged: boolean;
  spot: LocationDto;
};

@Component({
  selector: 'app-spot-information-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    TranslateModule,
    MatDialogModule,
    SpotInformationComponent,
  ],
  templateUrl: './spot-information-dialog.component.html',
  styleUrls: ['./spot-information-dialog.component.scss'],
})
export class SpotInformationDialogComponent {
  public readonly data: SpotInformationDialogData = inject(MAT_DIALOG_DATA);
  public readonly dialogRef = inject(MatDialogRef);

  public readonly spot: LocationDto = this.data.spot;

  #isStationChanged = false;

  public onDeleteLocationClick(): void {
    this.dialogRef.close({
      shouldBeDeleted: true,
      hasStationChanged: this.#isStationChanged,
      spot: this.spot,
    });
  }

  public close(): void {
    this.dialogRef.close({
      shouldBeDeleted: false,
      spot: this.spot,
      hasStationChanged: this.#isStationChanged,
    });
  }

  public onStationChange($event: boolean): void {
    this.#isStationChanged = $event;
  }
}
