<mat-expansion-panel
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false">
  <mat-expansion-panel-header
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'">
    <div class="panel-summary">
      <app-attribute-title
        data-testid="attribute-title-consumption"
        titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_DAY_ESTIMATION"
        icon="bolt"
        [subTitle]="spot.consumptionDayEstimation.avg | kWhFormat" />

      @if (!panelOpenState) {
        <app-attribute
          data-testid="attribute-consumption-per-year"
          labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_YEAR_ESTIMATION"
          [numerator]="
            (spot.consumptionDayEstimation | multiplyInterval: 365).avg
              | kWhFormat
          " />
        <app-attribute
          data-testid="attribute-sessions-per-day"
          labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_DAY_ESTIMATION"
          [numerator]="
            spot.sessionsDayEstimation.avg
              | number: '1.0-1' : translateService.currentLang
          " />
        <app-attribute
          data-testid="attribute-sessions-per-year"
          labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_YEAR_ESTIMATION"
          [numerator]="
            (spot.sessionsDayEstimation | multiplyInterval: 365).avg
              | number: '1.0-0' : translateService.currentLang
          " />
      }
    </div>
  </mat-expansion-panel-header>

  <app-range-chart
    [interval]="spot.consumptionDayEstimation | roundInterval"
    [format]="RANGE_LABEL_FORMAT.KWH" />

  <app-range-chart
    [interval]="
      spot.consumptionDayEstimation | multiplyInterval: 365 | roundInterval: 0
    "
    [tooltip]="TT.CONSUMPTION_PER_YEAR_ESTIMATION | tooltip | translate"
    [tooltipPosition]="attributeToolTipPosition"
    [tooltipShowDelay]="TT.CONSUMPTION_PER_YEAR_ESTIMATION | tooltipShowDelay"
    [format]="RANGE_LABEL_FORMAT.KWH"
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_YEAR_ESTIMATION">
  </app-range-chart>

  <app-range-chart
    [interval]="spot.sessionsDayEstimation | roundInterval"
    [format]="RANGE_LABEL_FORMAT.UNFORMATTED"
    [tooltip]="TT.USAGE_PER_DAY_ESTIMATION | tooltip | translate"
    [tooltipPosition]="attributeToolTipPosition"
    [tooltipShowDelay]="TT.USAGE_PER_DAY_ESTIMATION | tooltipShowDelay"
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_DAY_ESTIMATION">
  </app-range-chart>

  <app-range-chart
    [interval]="
      spot.sessionsDayEstimation | multiplyInterval: 365 | roundInterval: 0
    "
    [format]="RANGE_LABEL_FORMAT.UNFORMATTED"
    [tooltip]="TT.USAGE_PER_YEAR_ESTIMATION | tooltip | translate"
    [tooltipPosition]="attributeToolTipPosition"
    [tooltipShowDelay]="TT.USAGE_PER_YEAR_ESTIMATION | tooltipShowDelay"
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_YEAR_ESTIMATION">
  </app-range-chart>
</mat-expansion-panel>

<div class="disclaimer">
  <app-message
    icon="warning"
    [spacing]="true"
    [responsive]="false"
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.DISCLAIMER" />
</div>

@if (spot.forecastConsumptionDay) {
  <mat-expansion-panel class="panel" expanded hideToggle>
    <app-attribute-title
      data-testid="attribute-title-forecasting"
      titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.USAGE_FORECASTING.TITLE"
      icon="chart_data" />
    <app-consumption-forecast-chart [spot]="spot" />
  </mat-expansion-panel>
}

<mat-expansion-panel class="panel" expanded hideToggle>
  <app-attribute-title
    data-testid="attribute-title-insights"
    icon="lightbulb"
    titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.LOCATION_INSIGHTS">
  </app-attribute-title>

  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.POPULATION_DENSITY"
    [numerator]="spot.populationDensity.toString()">
  </app-attribute>
  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.EV_DENSITY"
    [numerator]="spot.evDensity.toString()">
  </app-attribute>

  <app-attribute
    labelTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CHARGING_STATIONS_10KM_RADIUS"
    [numerator]="spot.evChargersIn10KM.powerTotal.toString()">
  </app-attribute>

  <app-category-chart [evChargers]="spot.evChargersIn10KM" />
</mat-expansion-panel>
