<div class="scores">
  <app-score-chart
    [score]="spot.consumptionDayEstimationScoreLocal"
    [tooltipTranslationIdentifier]="TT.USAGE_SCORE_LOCAL | tooltip"
    [tooltipShowDelay]="TT.GRID_SCORE_COUNTRY | tooltipShowDelay"
    scoreNameTranslationIdentifier="MAP.LOCATION_INFORMATION.USAGE_SCORE_LOCAL_NEW_LINE">
  </app-score-chart>

  <app-score-chart
    [score]="spot.costEstimationScoreLocal"
    [tooltipTranslationIdentifier]="TT.GRID_SCORE_LOCAL | tooltip"
    [tooltipShowDelay]="TT.GRID_SCORE_LOCAL | tooltipShowDelay"
    scoreNameTranslationIdentifier="MAP.LOCATION_INFORMATION.GRID_SCORE_LOCAL_NEW_LINE">
  </app-score-chart>
</div>

<app-attribute-title
  titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_DAY_ESTIMATION"
  icon="bolt"
  [subTitle]="spot.consumptionDayEstimation.avg | kWhFormat"
  [tooltipTranslationIdentifier]="TT.CONSUMPTION_PER_DAY_ESTIMATION | tooltip"
  [tooltipShowDelay]="TT.CONSUMPTION_PER_DAY_ESTIMATION | tooltipShowDelay" />

<app-attribute-title
  icon="payments"
  titleTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.EST_TOTAL_COST"
  [subTitle]="spot.totalCostEstimation | moneyRangeFormat"
  [tooltipTranslationIdentifier]="TT.TOTAL_COST_INFO | tooltip"
  [tooltipShowDelay]="TT.TOTAL_COST_INFO | tooltipShowDelay" />

<mat-divider />

<app-attribute-title
  titleTranslationIdentifier="MAP.LOCATION_INFORMATION.LOCATION_SCORING"
  icon="speed">
</app-attribute-title>

<app-attribute
  data-testid="spot-tab-usage-score"
  [tooltipTranslationIdentifier]="TT.USAGE_SCORE | tooltip"
  [tooltipShowDelay]="TT.USAGE_SCORE | tooltipShowDelay"
  [tooltipPosition]="attributeToolTipPosition"
  [showNewValueIndicator]="showNewValueIndicator"
  [numerator]="spot.consumptionDayEstimationScoreLocal | number: '1.0-1'"
  [denominator]="spot.consumptionDayEstimationScore | number: '1.0-1'"
  numeratorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.EXPLANATIONS.LOCAL"
  denominatorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.USAGE.EXPLANATIONS.COUNTRY"
  labelTranslationIdentifier="MAP.LOCATION_INFORMATION.USAGE_LOCAL_COUNTRY_SCORE"
  newValueIndicatorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.CONFIGURATION_SETTINGS_CHANGED">
</app-attribute>

<app-attribute
  data-testid="spot-tab-grid-score"
  [tooltipTranslationIdentifier]="TT.GRID_SCORE | tooltip"
  [tooltipShowDelay]="TT.GRID_SCORE | tooltipShowDelay"
  [tooltipPosition]="attributeToolTipPosition"
  [numerator]="spot.costEstimationScoreLocal | number: '1.0-1'"
  [denominator]="spot.costEstimationScore | number: '1.0-1'"
  [showNewValueIndicator]="showNewValueIndicator"
  labelTranslationIdentifier="MAP.LOCATION_INFORMATION.GRID_LOCAL_COUNTRY_SCORE"
  newValueIndicatorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.CONFIGURATION_SETTINGS_CHANGED"
  numeratorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.EXPLANATIONS.LOCAL"
  denominatorToolTipTranslationIdentifier="MAP.LOCATION_INFORMATION.SCORES.GRID.EXPLANATIONS.COUNTRY">
</app-attribute>

<mat-divider />

<app-attribute-title
  titleTranslationIdentifier="MAP.LOCATION_INFORMATION.LOCATION_SET_UP"
  icon="ev_station">
</app-attribute-title>

<app-attribute
  [numerator]="totalChargingCapacityInKw?.toString()"
  [tooltipTranslationIdentifier]="TT.TOTAL_CHARGING_CAPACITY_IN_KW | tooltip"
  [tooltipShowDelay]="TT.TOTAL_CHARGING_CAPACITY_IN_KW | tooltipShowDelay"
  [tooltipPosition]="attributeToolTipPosition"
  labelTranslationIdentifier="MAP.LOCATION_INFORMATION.TOTAL_CHARGING_CAPACITY_IN_KW">
</app-attribute>

<app-attribute
  id="chargingStationsToBuild"
  [numerator]="spot.stations.length.toString()"
  [tooltipTranslationIdentifier]="TT.CHARGING_STATIONS_TO_BUILD | tooltip"
  [tooltipShowDelay]="TT.CHARGING_STATIONS_TO_BUILD | tooltipShowDelay"
  [tooltipPosition]="attributeToolTipPosition"
  labelTranslationIdentifier="MAP.LOCATION_INFORMATION.CHARGING_STATIONS_TO_BUILD">
</app-attribute>

<app-attribute-station-chips
  [matTooltip]="TT.CHARGING_STATIONS_CONFIGURATION | tooltip | translate"
  [matTooltipShowDelay]="TT.CHARGING_STATIONS_CONFIGURATION | tooltipShowDelay"
  [matTooltipPosition]="attributeToolTipPosition"
  [chips]="spot.stations"
  labelTranslationIdentifier="MAP.LOCATION_INFORMATION.CHARGING_STATIONS_CONFIGURATION">
</app-attribute-station-chips>
